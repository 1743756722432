import React from 'react'

function MessageBoxArea() {

  // Function that closes the message box when clicked outside
  function closeOnClickOutside(event) {
    if(event.target.id === "message-box-area") {
      event.target.classList.remove("show");
    }
  }

  return(
    <div id="message-box-area" className="hide" onClick={(event) => closeOnClickOutside(event)}>
      <div id="message-box" className="flex flex-fd-c flex-ai-c flex-jc-sb">
        <div id="message-box__header">Something went wrong</div>
        <div id="message-box__text" className="flex flex-jc-c flex-ai-c"></div>
        <button id="message-box__button" className="btn--cta" onClick={() => document.getElementById("message-box-area").classList.remove("show")}></button>
        <button className="btn--close" onClick={() => document.getElementById("message-box-area").classList.remove("show")}>
          <i className="fas fa-times"></i>
        </button>
      </div>
    </div>
  )
}

export default MessageBoxArea;
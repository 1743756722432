import React, { useState } from 'react'

import * as API from '../api/books-api'
import FormInput from '../components/FormInput'

function BookDelete(props) {

  const [id, setId] = useState(-1);

  // Function that deletes the book identified by the given ID
  function deleteById(event) {
    event.preventDefault();
    API.deleteById(id, result => {
      window.location.reload();
    }, props.refreshToken);
  }

  return(
    <div className="form-block__wrapper">
      <form className="form-block">
        <FormInput label="ID" placeholder="1" onChange={setId}/>
        <button className="form-block__button" onClick={event => {deleteById(event)}}>Delete</button>
      </form>
    </div>
  )
}

export default BookDelete;
import React, { useEffect } from 'react'

function AnnouncementArea() {

  useEffect(() => {
    let header = document.getElementById("header");
    let announcementArea = document.getElementById("announcement-area");
    announcementArea.style.top = `${header.offsetHeight}px`
  }, [])

  return(
    <div id="announcement-area"></div>
  )
}

export default AnnouncementArea;
import React, { useState } from 'react'

import * as USERS_API from '../api/users-api'

function Recovery(props) {

  const [emailToSend, setEmailToSend] = useState("");

  // Function that calls the API to initiate the account recovery process.
  function recoveryFunction(event) {
    event.preventDefault();
    let theEmail = {
      email: emailToSend
    }
    USERS_API.initializeRecovery(theEmail, callback => {
      window.location.reload();
    }, props.refreshToken)
  }

  const onInputSelect = (target) => target.parentElement.style.backgroundColor = "white";
  const onInputDeselect = (target) => target.parentElement.style.backgroundColor = "#FFDACC";

  return(
    <div className="recovery__container">
      <div className="recovery__wrapper">
        <form className="flex flex-ai-c flex-jc-sb flex-fd-c">
          <h1>Account Recovery Assistance</h1>
          <p>We will send you details on how to reset your password to your email address. Please complete the restoration process within 24 hours of receiving the email.</p>
          <div className="input__wrapper flex flex-ai-c">
            <i className="fas fa-envelope"></i>
            <input type="text" className="login__input" onSelect={e => onInputSelect(e.target)} onBlur={e => onInputDeselect(e.target)} onChange={e => setEmailToSend(e.target.value)} placeholder="Email"/>
          </div>
          <button className="cta__button" onClick={(event) => recoveryFunction(event)}>Submit</button>
        </form>
      </div>
    </div>
  )
}

export default Recovery;
import jwt_decode from 'jwt-decode'

import RestClient from './rest-client'
import { HOST } from './hosts'

const endpoint = {
  get_ranked: '/api/series/getRanked',
  get_all: '/api/series/getAll',
  save: '/api/series/add',
  get_by_name: '/api/series/get',
  get_by_id: '/api/series/getById/?id=',
  delete: '/api/series/delete/?id=',
  update: '/api/series/update/?id='
};

// Function that adds a new series
function save(series, callback, refresh) {
  let request = new Request(HOST.backend_api + endpoint.save, {
    method: 'POST',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(series)
  });
  if(Date.now() >= jwt_decode(localStorage.getItem('accessToken')).exp * 1000) { 
    RestClient.refreshAccessToken(refresh, result => {
      request.headers.append('Authorization', `Bearer ${result}`)
      RestClient.performRequest(request, callback);
    });
  } else {
    request.headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
    RestClient.performRequest(request, callback);
  }
}

// Function that retrieves all series
function getAll(callback) {
  let request = new Request(HOST.backend_api + endpoint.get_all, {
    method: 'GET',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  });
  RestClient.performRequest(request, callback);
}

// Function that retrieves all series, ranked by sales
function getRankedSeries(callback) {
  let request = new Request(HOST.backend_api + endpoint.get_ranked, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
  RestClient.performRequest(request, callback);
}

// Function that retrieves a series identified by its' name
function getByName(series, callback, refresh) {
  let request = new Request(HOST.backend_api + endpoint.get_by_name, {
    method: 'GET',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(series)
  });
  if(Date.now() >= jwt_decode(localStorage.getItem('accessToken')).exp * 1000) { 
    RestClient.refreshAccessToken(refresh, result => {
      request.headers.append('Authorization', `Bearer ${result}`)
      RestClient.performRequest(request, callback);
    });
  } else {
    request.headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
    RestClient.performRequest(request, callback);
  }
}

// Function that retrieves a series identified by its' ID
function getById(id, callback, refresh) {
  let request = new Request(HOST.backend_api + endpoint.get_by_id + id, {
    method: 'GET',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  });
  if(Date.now() >= jwt_decode(localStorage.getItem('accessToken')).exp * 1000) { 
    RestClient.refreshAccessToken(refresh, result => {
      request.headers.append('Authorization', `Bearer ${result}`)
      RestClient.performRequest(request, callback);
    });
  } else {
    request.headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
    RestClient.performRequest(request, callback);
  }
}

// Function that deletes a series identified by its' ID
function deleteById(id, callback, refresh) {
  let request = new Request(HOST.backend_api + endpoint.delete + id, {
    method: 'DELETE',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  });
  if(Date.now() >= jwt_decode(localStorage.getItem('accessToken')).exp * 1000) { 
    RestClient.refreshAccessToken(refresh, result => {
      request.headers.append('Authorization', `Bearer ${result}`)
      RestClient.performRequest(request, callback);
    });
  } else {
    request.headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
    RestClient.performRequest(request, callback);
  }
}

// Function that updates a series identified by its' ID
function update(id, series, callback, refresh) {
  let request = new Request(HOST.backend_api + endpoint.update + id, {
    method: 'PUT',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(series)
  });
  if(Date.now() >= jwt_decode(localStorage.getItem('accessToken')).exp * 1000) { 
    RestClient.refreshAccessToken(refresh, result => {
      request.headers.append('Authorization', `Bearer ${result}`)
      RestClient.performRequest(request, callback);
    });
  } else {
    request.headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
    RestClient.performRequest(request, callback);
  }
}

export { getRankedSeries, getAll, save, getByName, getById, deleteById, update }
import React, { useState } from 'react'
import {withRouter} from 'react-router-dom'

import BooksRenderer from '../utils/BookRendererUtils'

function Search(props) {

  const [showFilter, setShowFilter] = useState(false);

  return(
    <div className="search-main">
      <div className="search__container max-width">
        <div className="filter__buttons">
        <button className="filter__button filter__button__phone filter__button__show" 
                onClick={() => setShowFilter(true)} 
                style={{display: `${!showFilter ? 'block' : 'none'}`}}>
                  Filter your Search
        </button>
        <button className="filter__button filter__button__phone filter__button__hide" 
                onClick={() => setShowFilter(false)}
                style={{display: `${showFilter ? 'block' : 'none'}`}}>
                  Hide Filter
        </button>
        </div>
        <BooksRenderer 
          data={props.data} 
          term={props.term} 
          urlAppend = {props.urlAppend}
          showFilter = {showFilter}
          setShowFilter = {setShowFilter}
          refreshToken = { props.refreshToken }
        />
      </div>
    </div>
  )

}

export default withRouter(Search);
import React from 'react'

function FormSelect(props) {

  const data = [];
  props.data.forEach(element => {
    var elemName = element.name;
    data.push(<option key={"element " + element.id} value={element.id}>{elemName}</option>)
  })

  return(
    <div className="form-block__element" id={props.id}>
      <label className="form-block__label">{props.label}</label>
      <select onChange={e => props.dataSelect(e.target.value)} className="form-block__select" defaultValue={'DEFAULT'}>
        <option value="DEFAULT" disabled>Choose One</option>
        {data}
      </select>
    </div>
  )
}

export default FormSelect;
import * as ANNOUNCEMENT_UTILS from '../utils/AnnouncementUtils'

// Adds a book to the shopping cart and announces it
function addToShoppingCart(book, cart) {
  var newShoppingCart = [];
  var found = false;
  cart.forEach(item => {
    if(item.isbn13 === book.isbn13) {
      found = true;
      item.amount = item.amount + 1;
    }
    newShoppingCart.push(item);
  })
  if(!found) {
    newShoppingCart.push({
      id: book.id,
      isbn13: book.isbn13,
      name: book.name,
      author: book.author,
      price: book.price,
      quantity: book.quantity,
      discount: book.discount,
      series: book.series,
      amount: 1
    });
  }
  ANNOUNCEMENT_UTILS.addAnnouncement('has been added to the cart.', book.name)

  return newShoppingCart;
}

// Changes the amount of a book to the shopping cart
function changeAmountInCart(bookToChange, cart, newAmount) {
  var newShoppingCart = [];
  cart.forEach(book => {
    if(book.isbn13 === bookToChange.isbn13) {
      var bookGettingChanged = book;
      bookGettingChanged.amount = newAmount;
      if(newShoppingCart == null) {
        newShoppingCart = [bookGettingChanged];
      } else {
        newShoppingCart.push(bookGettingChanged);
      }
    } else {
      if(newShoppingCart == null) {
        newShoppingCart = [book];
      } else {
        newShoppingCart.push(book);
      }
    }
  })
  return newShoppingCart;
}

// Returns the amount of a book in the cart
function getAmount(bookToCheck, cart) {
  var valueToReturn = -1;
  cart.forEach(book => {
    if(book.isbn13 === bookToCheck.isbn13) {
      valueToReturn = book.amount;
    }
  })
  return valueToReturn;  
}

// Removes a book to the shopping cart and announces it
function removeBookFromCart(bookToRemove, cart) {
  var newShoppingCart = [];
  cart.forEach(book => {
    if(book.isbn13 !== bookToRemove.isbn13) {
      if(newShoppingCart == null) {
        newShoppingCart = [book];
      } else {
        newShoppingCart.push(book);
      }
    }
  })
  ANNOUNCEMENT_UTILS.addAnnouncement(`${bookToRemove.name} has been removed from the cart.`)
  
  return newShoppingCart;
}

export { addToShoppingCart, changeAmountInCart, removeBookFromCart, getAmount };
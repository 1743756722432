import React, { useEffect, useState } from 'react'

import Carousel from '../components/Carousel'
import * as BOOK_FETCHER from '../utils/BookFetchUtils'
import * as BOOK_API from '../api/books-api'

const BANNER_COUNT = 3;

function HomePage(props) {

  const [currentBanner, setCurrentBanner] = useState(0);
  const [previousBanner, setPreviousBanner] = useState(-1);
  const [ordersCount, setOrdersCount] = useState(0);

  // On load adds a dot for each banner
  useEffect(() => {
    let banners = document.getElementsByClassName("banner");
    let dotFather = document.getElementsByClassName("banner__dots")[0];

    for(let i = 0; i < banners.length; i++) {
      let dot = document.createElement("div");
      dot.classList.add("banner__dot");
      dot.onclick = () => changeActiveBanner(i);
      dotFather.appendChild(dot);
    }

  }, [])

  // Function that changes the active banner when a dot is clicked
  function changeActiveBanner(bannerId) {
    let banners = document.getElementsByClassName("banner");

    setPreviousBanner(currentBanner);
    setCurrentBanner(bannerId);

    for(let i = 0; i < banners.length; i++) {
      banners[i].classList.remove("hide");
      banners[i].classList.remove("show");
      banners[i].classList.add("hide");
    }
  }

  // Function that cycles through the banners on a timer
  useEffect(() => {
    const interval = setInterval(() => {

      let flash = document.getElementById("banner__flash");
      flash.classList.add("fade-in");
      setTimeout(() => {
        setPreviousBanner(currentBanner);
        if(currentBanner + 1 === BANNER_COUNT) {
          setCurrentBanner(0);
        } else {
          setCurrentBanner(currentBanner + 1)
        }
        flash.classList.remove("fade-in");
        flash.classList.add("fade-out");
      }, 50)
    }, 15000)
    return () => clearInterval(interval); 
  }, [currentBanner])

  // Function that changes the filling of the dots
  useEffect(() => {
    let banners = document.getElementsByClassName("banner");
    let dots = document.getElementsByClassName("banner__dot");
    
    for(let i = 0; i < dots.length; i++) {
      if(dots[i].classList.contains("banner__dot--filled")) {
        dots[i].classList.remove("banner__dot--filled");
      }
    }

    if(previousBanner !== -1) {
      banners[previousBanner].classList.add("hide");
      banners[previousBanner].classList.remove("show");
    }

    banners[currentBanner].classList.add("show");
    dots[currentBanner].classList.add("banner__dot--filled");

  }, [currentBanner])

  // Function that fetches books by genre
  function fetchBooksByGenre(genre, callback) {
    BOOK_FETCHER.fetchBooksByGenre(genre, result => {
      callback(result)
    }, props.refreshToken);
  }

  // Function that fetches books based on the user's order history 
  function fetchBooksByOrderHistory(noUse, callback) {
    if(localStorage.getItem('accessToken') !== undefined && localStorage.getItem('accessToken') !== null) {
      BOOK_API.getRecommendationsBasedOnPurchaseHistory(result =>  {
        setOrdersCount(result.length)
        callback(result);
      }, props.refreshToken);
    }
  }

  return(
    <main>
      <div className="banner__container">
        <div className="banners">
          <div className="banners__holder max-width relative ">
            <div className="banner flex flex-jc-c">
              <img src="https://beyondbooks.sergiudeaj.ro/assets/images/others/banner1.png" alt="World Book Day banner" className="banner__image"/>
            </div>
            <div className="banner flex flex-jc-c hide">
              <img src="https://beyondbooks.sergiudeaj.ro/assets/images/others/banner2.png" alt="World Book Day banner" className="banner__image"/>
            </div>
            <div className="banner flex flex-jc-c hide">
              <img src="https://beyondbooks.sergiudeaj.ro/assets/images/others/banner3.png" alt="World Book Day banner" className="banner__image"/>
            </div>
            <div id="banner__flash"></div>
            <div className="banner__dots flex flex-jc-c flex-ai-c">
            </div>
          </div>
        </div>
      </div>

      <div className="qualities">
        <div className="qualities__holder max-width ">
          <div className="qualities__wrapper flex shadow-bottom">
            <div className="quality flex flex-jc-c flex-ai-c">
              <div className="quality__image hide-for-not-desktop"><i className="fas fa-truck"></i></div>
              <div className="quality__texts flex">
                <div className="quality__image flex flex-ai-c flex-jc-c hide-for-desktop"><i className="fas fa-truck"></i></div>
                <h2 className="quality__header">Quick Delivery</h2>
                <p className="quality__text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</p>
              </div>
            </div>
            <div className="quality flex flex-jc-c flex-ai-c">
              <div className="quality__image hide-for-not-desktop"><i className="fas fa-money-check-alt"></i></div>
              <div className="quality__texts flex">
                <div className="quality__image flex flex-ai-c flex-jc-c hide-for-desktop"><i className="fas fa-money-check-alt"></i></div>
                <h2 className="quality__header">Pay Easily</h2>
                <p className="quality__text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</p>
              </div>
            </div>
            <div className="quality flex flex-jc-c flex-ai-c">
              <div className="quality__image hide-for-not-desktop"><i className="fas fa-piggy-bank"></i></div>
              <div className="quality__texts flex">
                <div className="quality__image flex flex-ai-c flex-jc-c hide-for-desktop"><i className="fas fa-piggy-bank"></i></div>
                <h2 className="quality__header">Best Deals</h2>
                <p className="quality__text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</p>
              </div>
            </div>
            <div className="quality flex flex-jc-c flex-ai-c">
              <div className="quality__image hide-for-not-desktop"><i className="fas fa-shield-alt"></i></div>
              <div className="quality__texts flex">
                <div className="quality__image flex flex-ai-c flex-jc-c hide-for-desktop"><i className="fas fa-shield-alt"></i></div>
                <h2 className="quality__header">Secure Payments</h2>
                <p className="quality__text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main max-width flex">
        <div className="content">

          <div className={`block ${ordersCount > 0 ? 'show' : 'hide'}`}>
            <h2>Recommended for you</h2>
            <Carousel fetcher={fetchBooksByOrderHistory} value="abc" refreshToken = { props.refreshToken }/>
          </div>

          <div className="block">
            <h2>Bestselling Fantasy Books</h2>
            <Carousel fetcher={fetchBooksByGenre} value="fantasy" refreshToken = { props.refreshToken }/>
          </div>

          <div className="block">
            <h2>Bestselling History Books</h2>
            <Carousel fetcher={fetchBooksByGenre} value="history" refreshToken = { props.refreshToken }/>
          </div>

          <div className="block">
            <h2>Bestselling Science Books</h2>
            <Carousel fetcher={fetchBooksByGenre} value="science" refreshToken = { props.refreshToken }/>
          </div>

        </div>
      </div>
    </main>
  )
}

export default HomePage;
import React, { useState } from 'react'

import * as API from '../api/publisher-api'
import FormInput from '../components/FormInput'

function PublisherAdd(props) {

  const [name, setName]  = useState("");

  // Function that saves a new publisher
  function add(event) {
    event.preventDefault()
    let element = { name: name, }
    API.save(element, result => {
      window.location.reload();
    }, props.refreshToken);
  }

  return(
    <div className="form-block__wrapper">
      <form className="form-block">
        <FormInput label="NAME" placeholder="Humanitas" onChange={setName}/>
        <button className="form-block__button" onClick={event => {add(event)}}>Add</button>
      </form>
    </div>
  )
}

export default PublisherAdd;
import React from 'react'
import { useState, useEffect } from 'react'

import BookView from '../CRUDs/BookView';
import BookAdd from '../CRUDs/BookAdd';
import BookUpdate from '../CRUDs/BookUpdate';
import BookDelete from '../CRUDs/BookDelete';
import BookQuantity from '../CRUDs/BookQuantity';
import BookDiscount from '../CRUDs/BookDiscount'
import AuthorView from '../CRUDs/AuthorView';
import AuthorAdd from '../CRUDs/AuthorAdd';
import AuthorUpdate from '../CRUDs/AuthorUpdate';
import AuthorDelete from '../CRUDs/AuthorDelete';
import FormatView from '../CRUDs/FormatView';
import FormatAdd from '../CRUDs/FormatAdd';
import FormatUpdate from '../CRUDs/FormatUpdate';
import FormatDelete from '../CRUDs/FormatDelete';
import GenreView from '../CRUDs/GenreView';
import GenreAdd from '../CRUDs/GenreAdd';
import GenreUpdate from '../CRUDs/GenreUpdate';
import GenreDelete from '../CRUDs/GenreDelete';
import LanguageView from '../CRUDs/LanguageView';
import LanguageAdd from '../CRUDs/LanguageAdd';
import LanguageUpdate from '../CRUDs/LanguageUpdate';
import LanguageDelete from '../CRUDs/LanguageDelete';
import PublisherView from '../CRUDs/PublisherView';
import PublisherAdd from '../CRUDs/PublisherAdd';
import PublisherUpdate from '../CRUDs/PublisherUpdate';
import PublisherDelete from '../CRUDs/PublisherDelete';
import SeriesView from '../CRUDs/SeriesView';
import SeriesAdd from '../CRUDs/SeriesAdd';
import SeriesUpdate from '../CRUDs/SeriesUpdate';
import SeriesDelete from '../CRUDs/SeriesDelete';

function Moderator(props) { 

  const [verticalTab, setVerticalTab] = useState(1);
  const [horizontalTab, setHorizontalTab] = useState(1);

  // Sets the focus on the search fields of each category
  useEffect(() => {
    if(horizontalTab === 1) {
      switch(verticalTab) {
        case 1: document.getElementById("book-view-search").focus(); break;
        case 2: document.getElementById("author-view-search").focus(); break;
        case 3: document.getElementById("format-view-search").focus(); break;
        case 4: document.getElementById("genre-view-search").focus(); break;
        case 5: document.getElementById("language-view-search").focus(); break;
        case 6: document.getElementById("publisher-view-search").focus(); break;
        case 7: document.getElementById("series-view-search").focus(); break;
        default: break;
      }
    }
  }, [horizontalTab, verticalTab])

  return(
    <div className="max-width pad-10 flex mod">
      <div className="mod__sidebar">
        <ul className="vertical__tabs">
          <li className="bg-neutral-100 hide-for-mobile"><button></button></li>
          <li className={verticalTab === 1 ? `bg-primary-500` : `bg-primary-100`}><button onClick={() => {setVerticalTab(1); setHorizontalTab(1)}}>Books</button></li>
          <li className={verticalTab === 2 ? `bg-primary-500` : `bg-primary-100`}><button onClick={() => {setVerticalTab(2); setHorizontalTab(1)}}>Authors</button></li>
          <li className={verticalTab === 3 ? `bg-primary-500` : `bg-primary-100`}><button onClick={() => {setVerticalTab(3); setHorizontalTab(1)}}>Formats</button></li>
          <li className={verticalTab === 4 ? `bg-primary-500` : `bg-primary-100`}><button onClick={() => {setVerticalTab(4); setHorizontalTab(1)}}>Genres</button></li>
          <li className={verticalTab === 5 ? `bg-primary-500` : `bg-primary-100`}><button onClick={() => {setVerticalTab(5); setHorizontalTab(1)}}>Languages</button></li>
          <li className={verticalTab === 6 ? `bg-primary-500` : `bg-primary-100`}><button onClick={() => {setVerticalTab(6); setHorizontalTab(1)}}>Publishers</button></li>
          <li className={verticalTab === 7 ? `bg-primary-500` : `bg-primary-100`}><button onClick={() => {setVerticalTab(7); setHorizontalTab(1)}}>Series</button></li>
        </ul>
      </div>
      <div className="mod__main">
        <ul className="flex flex-fd-r flex-jc-c horizontal__tabs">
          <li className={horizontalTab === 1 ? `bg-primary-500` : `bg-primary-100`}><button onClick={() => setHorizontalTab(1)}>View</button></li>
          <li className={horizontalTab === 2 ? `bg-primary-500` : `bg-primary-100`}><button onClick={() => setHorizontalTab(2)}>Add</button></li>
          <li className={horizontalTab === 3 ? `bg-primary-500` : `bg-primary-100`}><button onClick={() => setHorizontalTab(3)}>Update</button></li>
          <li className={horizontalTab === 4 ? `bg-primary-500` : `bg-primary-100`}><button onClick={() => setHorizontalTab(4)}>Delete</button></li>
          <li className={`${verticalTab !== 1 ? 'hide' : ''} ${horizontalTab === 5 ? `bg-primary-500` : `bg-primary-100`}`}><button onClick={() => setHorizontalTab(5)}>Qty.</button></li>
          <li className={`${verticalTab !== 1 ? 'hide' : ''} ${horizontalTab === 6 ? `bg-primary-500` : `bg-primary-100`}`}><button onClick={() => setHorizontalTab(6)}>Disc.</button></li>
        </ul>
        <div className="tab__container">
          <div className={`hide ${verticalTab === 1 && horizontalTab === 1 ? 'show' : ''}`}><BookView refreshToken = {props.refreshToken}/></div>
          <div className={`hide ${verticalTab === 1 && horizontalTab === 2 ? 'show' : ''}`}><BookAdd refreshToken = {props.refreshToken}/></div>
          <div className={`hide ${verticalTab === 1 && horizontalTab === 3 ? 'show' : ''}`}><BookUpdate refreshToken = {props.refreshToken}/></div>
          <div className={`hide ${verticalTab === 1 && horizontalTab === 4 ? 'show' : ''}`}><BookDelete refreshToken = {props.refreshToken}/></div>
          <div className={`hide ${verticalTab === 1 && horizontalTab === 5 ? 'show' : ''}`}><BookQuantity refreshToken = {props.refreshToken}/></div>
          <div className={`hide ${verticalTab === 1 && horizontalTab === 6 ? 'show' : ''}`}><BookDiscount refreshToken = {props.refreshToken}/></div>

          <div className={`hide ${verticalTab === 2 && horizontalTab === 1 ? 'show' : ''}`}><AuthorView refreshToken = {props.refreshToken}/></div>
          <div className={`hide ${verticalTab === 2 && horizontalTab === 2 ? 'show' : ''}`}><AuthorAdd refreshToken = {props.refreshToken}/></div>
          <div className={`hide ${verticalTab === 2 && horizontalTab === 3 ? 'show' : ''}`}><AuthorUpdate refreshToken = {props.refreshToken}/></div>
          <div className={`hide ${verticalTab === 2 && horizontalTab === 4 ? 'show' : ''}`}><AuthorDelete refreshToken = {props.refreshToken}/></div>

          <div className={`hide ${verticalTab === 3 && horizontalTab === 1 ? 'show' : ''}`}><FormatView refreshToken = {props.refreshToken}/></div>
          <div className={`hide ${verticalTab === 3 && horizontalTab === 2 ? 'show' : ''}`}><FormatAdd refreshToken = {props.refreshToken}/></div>
          <div className={`hide ${verticalTab === 3 && horizontalTab === 3 ? 'show' : ''}`}><FormatUpdate refreshToken = {props.refreshToken}/></div>
          <div className={`hide ${verticalTab === 3 && horizontalTab === 4 ? 'show' : ''}`}><FormatDelete refreshToken = {props.refreshToken}/></div>

          <div className={`hide ${verticalTab === 4 && horizontalTab === 1 ? 'show' : ''}`}><GenreView refreshToken = {props.refreshToken}/></div>
          <div className={`hide ${verticalTab === 4 && horizontalTab === 2 ? 'show' : ''}`}><GenreAdd refreshToken = {props.refreshToken}/></div>
          <div className={`hide ${verticalTab === 4 && horizontalTab === 3 ? 'show' : ''}`}><GenreUpdate refreshToken = {props.refreshToken}/></div>
          <div className={`hide ${verticalTab === 4 && horizontalTab === 4 ? 'show' : ''}`}><GenreDelete refreshToken = {props.refreshToken}/></div>

          <div className={`hide ${verticalTab === 5 && horizontalTab === 1 ? 'show' : ''}`}><LanguageView refreshToken = {props.refreshToken}/></div>
          <div className={`hide ${verticalTab === 5 && horizontalTab === 2 ? 'show' : ''}`}><LanguageAdd refreshToken = {props.refreshToken}/></div>
          <div className={`hide ${verticalTab === 5 && horizontalTab === 3 ? 'show' : ''}`}><LanguageUpdate refreshToken = {props.refreshToken}/></div>
          <div className={`hide ${verticalTab === 5 && horizontalTab === 4 ? 'show' : ''}`}><LanguageDelete refreshToken = {props.refreshToken}/></div>

          <div className={`hide ${verticalTab === 6 && horizontalTab === 1 ? 'show' : ''}`}><PublisherView refreshToken = {props.refreshToken}/></div>
          <div className={`hide ${verticalTab === 6 && horizontalTab === 2 ? 'show' : ''}`}><PublisherAdd refreshToken = {props.refreshToken}/></div>
          <div className={`hide ${verticalTab === 6 && horizontalTab === 3 ? 'show' : ''}`}><PublisherUpdate refreshToken = {props.refreshToken}/></div>
          <div className={`hide ${verticalTab === 6 && horizontalTab === 4 ? 'show' : ''}`}><PublisherDelete refreshToken = {props.refreshToken}/></div>

          <div className={`hide ${verticalTab === 7 && horizontalTab === 1 ? 'show' : ''}`}><SeriesView refreshToken = {props.refreshToken}/></div>
          <div className={`hide ${verticalTab === 7 && horizontalTab === 2 ? 'show' : ''}`}><SeriesAdd refreshToken = {props.refreshToken}/></div>
          <div className={`hide ${verticalTab === 7 && horizontalTab === 3 ? 'show' : ''}`}><SeriesUpdate refreshToken = {props.refreshToken}/></div>
          <div className={`hide ${verticalTab === 7 && horizontalTab === 4 ? 'show' : ''}`}><SeriesDelete refreshToken = {props.refreshToken}/></div>
        </div>
      </div>
    </div>
  )
}

export default Moderator;
import React, { useState, useEffect } from 'react'
import {withRouter} from 'react-router-dom'

import SearchPage from '../pages/Search'

function Search(props) {

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  // Calls the fetchers to fetch the books based on the URL
  useEffect(() => {
    if(props.type !== "searchTerm") {
      props.fetcher(props.match.params.id, result => setData(result), props.refreshToken)
    } else {
      props.fetcher(props.match.params.searchTerm, result => setData(result), props.refreshToken)
    }
  }, [])

  // Sets the values of different parameters in the sessionStorage, which will be used to compute the f iltered URL
  useEffect(() => {

    if(props.match.params.genre) {
      sessionStorage.setItem('genre-value', props.match.params.genre)
    } else {
      sessionStorage.setItem('genre-value', '0')
    }

    if(props.match.params.availability) {
      sessionStorage.setItem('availability-value', props.match.params.availability)
    } else {
      sessionStorage.setItem('availability-value', '0')
    }

    if(props.match.params.format) {
      sessionStorage.setItem('format-value', props.match.params.format)
    } else {
      sessionStorage.setItem('format-value', '0')
    }

    if(props.match.params.price) {
      sessionStorage.setItem('price-range-value', props.match.params.price)
    } else {
      sessionStorage.setItem('price-range-value', '0')
    }

    if(props.match.params.language) {
      sessionStorage.setItem('language-value', props.match.params.language)
    } else {
      sessionStorage.setItem('language-value', '0')
    }

  }, [props.match.params])

  // Filters the data by different fields (format, genre, availability, price)
  useEffect(() => {
    var tempData = data;

    if(props.match.params.format && props.match.params.format !== "0") {
      var formatFilteredData = [];
      tempData.forEach(element =>  {
        if(element.format.id === Number(props.match.params.format)) {
          formatFilteredData.push(element);
        }
      })
      tempData = formatFilteredData;
    } 

    if(props.match.params.genre && props.match.params.genre !== "0") {
      var genreFilteredData = [];
      tempData.forEach(element =>  {

        element.genres.forEach(elementGenres => {
          if(elementGenres.id === Number(props.match.params.genre)) {
            genreFilteredData.push(element);
          }
        })
      })
      tempData = genreFilteredData;
    } 

    if(props.match.params.language && props.match.params.language !== "0") {
      var languageFilteredData = [];
      tempData.forEach(element =>  {
        if(element.language.id === Number(props.match.params.language)) {
          languageFilteredData.push(element);
        }
      })
      tempData = languageFilteredData;
    } 

    if(props.match.params.availability && props.match.params.availability !== "0") {
      var availabilityFilteredData = [];
      tempData.forEach(element =>  {
        if(element.quantity > 0) {
          availabilityFilteredData.push(element);
        }
      })
      tempData = availabilityFilteredData;
    }

    if(props.match.params.price && props.match.params.price !== "0") {
      var priceRangeFilteredData = [];
      if(props.match.params.price === "1") {
        tempData.forEach(element =>  {
          if(Number(element.price) - (Number(element.discount)/100 * Number(element.price)) < 10) {
            priceRangeFilteredData.push(element);
          }
        })
      } else if(props.match.params.price === "2") {
        tempData.forEach(element =>  {
          if(Number(element.price) - (Number(element.discount)/100 * Number(element.price)) >= 10 && Number(element.price) - (Number(element.discount)/100 * Number(element.price)) < 20) {
            priceRangeFilteredData.push(element);
          }
        })
      } else if(props.match.params.price === "3") {
        tempData.forEach(element =>  {
          if(Number(element.price) - (Number(element.discount)/100 * Number(element.price)) > 20) {
            priceRangeFilteredData.push(element);
          }
        })
      }
      tempData = priceRangeFilteredData;
    }

    setFilteredData(tempData)
  }, [data, props.match.params])

  return(

    <SearchPage
      data={filteredData}
      term={props.type !== "searchTerm" ? props.match.params.id : props.match.params.searchTerm}
      refreshToken = { props.refreshToken }
      urlAppend={props.urlAppend}
    />

    
  )
}

export default withRouter(Search);
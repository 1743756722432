
// Toggles the Favorite state of a book
function toggleFavorites(book, target, faves) {
  var newFavorites = [];
  var found = false;
  if(faves !== undefined) {
    faves.forEach(item => {
      if(item.isbn13 === book.isbn13) {
        found = true;
      } else {
        newFavorites.push(item);
      }
    })
    if(!found) {
      newFavorites.push({
        id: book.id,
        isbn13: book.isbn13,
        name: book.name,
        author: book.author,
        price: book.price,
        quantity: book.quantity,
        discount: book.discount,
        series: book.series,
        amount: 1
      });
    }
  }
  return newFavorites;
}

// Function that checks if a book is favorited or not (by ISBN13)
function checkFavorites(isbn13, faves) {
  var found = false;
  if(faves !== undefined) {
    faves.forEach(item => {
      if(item.isbn13 === isbn13) {
        found = true;
      }
    })
  }
  return found;
}

export { toggleFavorites, checkFavorites };
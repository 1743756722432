import React, { useState, useEffect } from 'react'
import jwt_decode from 'jwt-decode'

import * as ORDERS_API from '../api/orders-api'

function Orders(props) {

  const [noOrders, setNoOrders] = useState(true)
  const [orders, setOrders] = useState([]);

  // On load, fetch the user's orders
  useEffect(() => {
    if(localStorage.getItem('accessToken') !== undefined)  {
      ORDERS_API.getByUserId(jwt_decode(localStorage.getItem('accessToken')).sub, result => {
        if(result.length > 0) {
          setNoOrders(false);
          setOrders(result);
          console.log(result);
        }
      }, props.refreshToken)
    }
  }, [])

  // Sorts and prepares the orders to be rendered
  const toRender = [];
  if(!noOrders) {
    orders.sort((a, b) => b.date - a.date);
    orders.forEach(order => {
      var date = new Date(order.date * 1000);
      var format = {day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit'}
      toRender.push(
      <div key={order.id} className="order flex flex-jc-sb flex-ai-c">
        <div>
          <h1>Order #{order.id}</h1>
          <p>Placed on: {date.toLocaleDateString("en-GB", format)}</p>
          <p>Total: {order.total} €</p>
          <p>Status: <span className="order__status">{order.status.value}</span></p>
        </div>
        <a className="btn--cta" href={`/order/${order.id}`}>Details</a>
      </div>)
    })
  }

  // Renders the orders
  function OrdersRenderer() {
    if(noOrders) {
      return(
        <div className="orders__wrapper">
          <h1>You have no orders</h1>
        </div>
      )
    } else {
      return(
        <div className="orders__wrapper">
          <h1>Your Orders</h1>
          {toRender}
        </div>
      )
    }
  }

  return(
    <main>
      <div className="max-width orders__container">
        <OrdersRenderer refreshToken = { props.refreshToken }/>
      </div>
    </main>
  )
}

export default Orders;
import React from 'react'
import { withRouter } from 'react-router-dom'

import LoginForm from '../components/LoginForm'
import RegisterForm from '../components/RegisterForm'

function Login(props) {

  // Function that slides the pannel to the right and handles transitions
  function slideRight() {
    let leftPanel = document.getElementById("leftPanel");
    let rightPanel = document.getElementById("rightPanel");
    let leftPanelLeft = document.getElementById("leftPanelLeft");
    let leftPanelRight = document.getElementById("leftPanelRight");
    let rightPanelLeft = document.getElementById("rightPanelLeft");
    let rightPanelRight = document.getElementById("rightPanelRight");

    leftPanel.classList.add("slideRight")
    leftPanel.classList.remove("slideLeft");

    rightPanel.classList.add("slideLeftWide");
    rightPanel.classList.remove("slideRightWide")

    setTimeout(() => {
      leftPanelLeft.classList.add("hide");
      rightPanelLeft.classList.add("hide");

      setTimeout(() => {
        rightPanelRight.classList.remove("hide");
        leftPanelRight.classList.remove("hide");
      }, 650)
    }, 200)
  }

  // Function that slides the pannel to the left and handles transitions
  function slideLeft() {
    let leftPanel = document.getElementById("leftPanel");
    let rightPanel = document.getElementById("rightPanel");
    let leftPanelLeft = document.getElementById("leftPanelLeft");
    let leftPanelRight = document.getElementById("leftPanelRight");
    let rightPanelLeft = document.getElementById("rightPanelLeft");
    let rightPanelRight = document.getElementById("rightPanelRight");

    leftPanel.classList.add("slideLeft");
    leftPanel.classList.remove("slideRight")

    rightPanel.classList.add("slideRightWide")
    rightPanel.classList.remove("slideLeftWide");

    setTimeout(() => {
      leftPanelRight.classList.add("hide");
      rightPanelRight.classList.add("hide");

      setTimeout(() => {
        leftPanelLeft.classList.remove("hide");
        rightPanelLeft.classList.remove("hide");
      }, 650)
    }, 200)
  }

  return(
    <main>
      <div className="main max-width flex flex-fd-c">

        <div id="login-register--mobile" className="flex hide-for-tablet">
          <div id="panel" className="flex flex-fd-c">

            <div id="login-form">
              <LoginForm setLoggedIn={props.setLoggedIn} refreshToken={props.refreshToken} setRefreshToken={props.setRefreshToken}/>
            </div>

            <div id="register-form">
              <RegisterForm setLoggedIn={props.setLoggedIn} refreshToken={props.refreshToken} setRefreshToken={props.setRefreshToken}/>
            </div>
          
          </div>
        </div>

        <div id="login-register" className="flex hide-for-mobile">
          <img  id="login-image" src="https://beyondbooks.sergiudeaj.ro/assets/images/others/reader2.png" alt="Reader" width="365" height="412"/>
            <div id="leftPanel">

              <div id="leftPanelLeft" className="flex flex-fd-c flex-ai-c">
                <img  src="https://beyondbooks.sergiudeaj.ro/assets/images/others/reader4.png" alt="Reader" width="207" height="200"/>
                <h1 className="login__small__header">Welcome Back!</h1>
                <p className="login__small__text">Log in to your account to stay in touch with us and your favorite books.</p>
                <p className="login__small__text">Don't have an account? 
                  <button className="login__link--button" onClick={() => slideRight()}>Create Account</button>
                </p>
              </div>

              <div id="leftPanelRight" className="flex flex-fd-c flex-ai-c hide">
                <img  src="https://beyondbooks.sergiudeaj.ro/assets/images/others/reader1.png" alt="Reader" width="220" height="200"/>
                <h1 className="login__small__header">Hello, friend!</h1>
                <p className="login__small__text">Create an account and adventure through our virtual shelves!</p>
                <p className="login__small__text">Already have an account? 
                  <button className="login__link--button" onClick={() => slideLeft()}>&nbsp;Log In</button>
                </p>
              </div>

            </div>

          <div id="rightPanel" className="flex">

            <div id="rightPanelLeft" className="flex flex-fd-c flex-ai-c flex-jc-c">
              <LoginForm setLoggedIn={props.setLoggedIn} refreshToken={props.refreshToken} setRefreshToken={props.setRefreshToken}/>
            </div>

            <div id="rightPanelRight" className="flex flex-fd-c flex-ai-c flex-jc-c hide">
              <RegisterForm setLoggedIn={props.setLoggedIn} refreshToken={props.refreshToken} setRefreshToken={props.setRefreshToken}/>
            </div>

          </div>
        </div>
      </div>
    </main>
  )
}

export default withRouter(Login);
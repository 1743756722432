import React, { useState, useEffect } from 'react'

import { withRouter } from 'react-router-dom'

// Filters for the book list pages
function SidebarFilter(props) {

  const [data, setData] = useState([]);

  const [availabilityOptions, setAvailabilityOptions] = useState([]);
  const [genreOptions, setGenreOptions] = useState([]);
  const [formatOptions, setFormatOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [priceRangeOptions, setPriceRangeOptions] = useState([]);

  const [formatsToRender, setFormatsToRender] = useState([]);
  const [languagesToRender, setLanguagesToRender] = useState([]);
  const [genresToRender, setGenresToRender] = useState([]);
  const [priceRangesToRender, setPriceRangesToRender] = useState([]);
  const [availabilitiesToRender, setAvailabilitiesToRender] = useState([]);

  const [formatSelectedValue, setFormatSelectedValue] = useState("0");
  const [languageSelectedValue, setLanguageSelectedValue] = useState("0");
  const [genreSelectedValue, setGenreSelectedValue] = useState("0");
  const [priceRangeSelectedValue, setPriceRangeSelectedValue] = useState("0");
  const [availabilitySelectedValue, setAvailabilitySelectedValue] = useState("0");

  // Function that populates the filter selects
  function populateSelects() {

    var newFormatOptions = [];
    var newGenreOptions = [];
    var newAvailabilityOptions = [];
    var newLanguageOptions = [];
    var newPriceRangeOptions = [];

    newPriceRangeOptions.push({id: 1, name: "Under 10 €"})
    newPriceRangeOptions.push({id: 2, name: "10 € to 20 €"})
    newPriceRangeOptions.push({id: 3, name: "Over 20 €"})

    newAvailabilityOptions.push({id: 1, name: "In stock"})

    data.forEach(element => {

      var formatFound = false;
      if(element.format) {
        newFormatOptions.forEach(format => {
          if(element.format.id === format.id) {
            formatFound = true;
          }
        })
        if(!formatFound) {
          newFormatOptions.push(element.format);
        }
      }

      var languageFound = false;
      if(element.language) {
        newLanguageOptions.forEach(language => {
          if(element.language.id === language.id) {
            languageFound = true;
          }
        })
        if(!languageFound) {
          newLanguageOptions.push(element.language);
        }
      }

      if(element.genres) {
        element.genres.forEach(elementGenre => {
          var genreFound = false;
          newGenreOptions.forEach(genre => {
            if(elementGenre.id === genre.id) {
              genreFound = true;
            }
          })
          if(!genreFound) {
            newGenreOptions.push(elementGenre);
          }
        })
      }
    })

    setFormatOptions(newFormatOptions);
    setLanguageOptions(newLanguageOptions);
    setGenreOptions(newGenreOptions);
    setPriceRangeOptions(newPriceRangeOptions);
    setAvailabilityOptions(newAvailabilityOptions);
  }

  useEffect(populateSelects, [data]);

  // Function that redirects to the appropriate URL when the filter button is clicked
  function onButtonClick() {
    props.history.push(`${props.urlAppend}${props.term}/genre/${genreSelectedValue}/availability/${availabilitySelectedValue}/language/${languageSelectedValue}/format/${formatSelectedValue}/priceRange/${priceRangeSelectedValue}`);
  }

  useEffect(() => {
    setData(props.data);
  }, [props.data])

  useEffect(() => {
    props.dataSetter(data);
  }, [data])

  // Updates the price range options that are getting rendered when the priceRangeOptions state changes
  useEffect(() => {
    const priceRanges = [];
    priceRanges.push(<option key={0} value="0">All</option>);
    priceRangeOptions.forEach( element => {
      priceRanges.push(<option key={"price-range " + Math.random()} value={element.id}>{element.name}</option>)
    })
    setPriceRangesToRender(priceRanges);
    if(sessionStorage.getItem(`${props.pre}price-range-value`)) {
      let counter = 0;
      priceRangeOptions.forEach(element => {
        if(String(element.id) === sessionStorage.getItem(`${props.pre}price-range-value`)) {
          var foundCounter = counter;
          setTimeout(() => { 
            document.getElementById(`${props.pre}price-range-select`).selectedIndex = foundCounter+1;
          }, 5);
        }
        counter += 1;
      })
    }
  }, [priceRangeOptions])

  // Updates the availability options that are getting rendered when the availabilityOptions state changes
  useEffect(() => {
    const availabilities = [];
    availabilities.push(<option key={0} value="0">All</option>);
    availabilityOptions.forEach( element => {
      availabilities.push(<option key={"availability " + Math.random()} value={element.id}>{element.name}</option>)
    })
    setAvailabilitiesToRender(availabilities);
    if(sessionStorage.getItem(`${props.pre}availability-value`)) {
      let counter = 0;
      availabilityOptions.forEach(element => {
        if(String(element.id) === sessionStorage.getItem(`${props.pre}availability-value`)) {
          var foundCounter = counter;
          setTimeout(() => { 
            document.getElementById(`${props.pre}availability-select`).selectedIndex = foundCounter+1; 
          }, 5);
        }
        counter += 1;
      })
    }
  }, [availabilityOptions])

  // Updates the format options that are getting rendered when the formatOptions state changes
  useEffect(() => {
    const formats = [];
    formats.push(<option key={0} value="0">All</option>);
    formatOptions.forEach( element => {
      formats.push(<option key={"format " + Math.random()} value={element.id}>{element.name}</option>)
    })
    setFormatsToRender(formats);
    if(sessionStorage.getItem(`${props.pre}format-value`)) {
      let counter = 0;
      formatOptions.forEach(element => {
        if(String(element.id) === sessionStorage.getItem(`${props.pre}format-value`)) {
          var foundCounter = counter;
          setTimeout(() => { 
            document.getElementById(`${props.pre}format-select`).selectedIndex = foundCounter+1; 
          }, 5);
        }
        counter += 1;
      })
    }
  }, [formatOptions])

  // Updates the language options that are getting rendered when the languageOptions state changes
  useEffect(() => {
    const languages = [];
    languages.push(<option key={0} value="0">All</option>);
    languageOptions.forEach( element => {
      languages.push(<option key={"language " + Math.random()} value={element.id}>{element.name}</option>)
    })
    setLanguagesToRender(languages);
    if(sessionStorage.getItem(`${props.pre}language-value`)) {
      let counter = 0;
      languageOptions.forEach(element => {
        if(String(element.id) === sessionStorage.getItem(`${props.pre}language-value`)) {
          var foundCounter = counter;
          setTimeout(() => { 
            document.getElementById(`${props.pre}language-select`).selectedIndex = foundCounter+1; 
          }, 5);
        }
        counter += 1;
      })
    }
  }, [languageOptions])

  // Updates the genre options that are getting rendered when the genreOptions state changes
  useEffect(() => {
    const genres = [];
    genres.push(<option key={0} value="0">All</option>);
    genreOptions.forEach( element => {
      genres.push(<option key={"genre " + Math.random()} value={element.id}>{element.name}</option>)
    })
    setGenresToRender(genres);
    if(sessionStorage.getItem(`${props.pre}genre-value`)) {
      let counter = 0;
      genreOptions.forEach(element => {
        if(String(element.id) === sessionStorage.getItem(`${props.pre}genre-value`)) {
          var foundCounter = counter;
          setTimeout(() => { 
            document.getElementById(`${props.pre}genre-select`).selectedIndex = foundCounter+1; 
            console.log(foundCounter)
          }, 5);
        }
        counter += 1;
      })
    }
  }, [genreOptions])

  return(
    <div className="sidebar-element">
      <h3 className="sidebar-element__title">Filter your Search</h3>
      <div className="filter__wrapper">
        <label className="filter__label">Availability</label>
        <select id={`${props.pre}availability-select`} className="filter__select" onChange={e => {sessionStorage.setItem(`${props.pre}availability-value`, e.target.value); setAvailabilitySelectedValue(e.target.value)}} defaultValue={'DEFAULT'}>
          {availabilitiesToRender}
        </select>
      </div>
      <div className="filter__wrapper">
        <label className="filter__label">Genres</label>
        <select id={`${props.pre}genre-select`} className="filter__select" onChange={e => {sessionStorage.setItem(`${props.pre}genre-value`, e.target.value); setGenreSelectedValue(e.target.value)}} defaultValue={'DEFAULT'}>
          {genresToRender}
        </select>
      </div>
      <div className="filter__wrapper">
        <label className="filter__label">Format</label>
        <select id={`${props.pre}format-select`} className="filter__select" onChange={e => {sessionStorage.setItem(`${props.pre}format-value`, e.target.value); setFormatSelectedValue(e.target.value)}} defaultValue={'DEFAULT'}>
          {formatsToRender}
        </select>
      </div>
      <div className="filter__wrapper">
        <label className="filter__label">Language</label>
        <select id={`${props.pre}language-select`} className="filter__select" onChange={e => {sessionStorage.setItem(`${props.pre}language-value`, e.target.value); setLanguageSelectedValue(e.target.value)}} defaultValue={'DEFAULT'}>
          {languagesToRender}
        </select>
      </div>
      <div className="filter__wrapper">
        <label className="filter__label">Price Range</label>
        <select id={`${props.pre}price-range-select`} className="filter__select" onChange={e => {sessionStorage.setItem(`${props.pre}price-range-value`, e.target.value); setPriceRangeSelectedValue(e.target.value)}} defaultValue={'DEFAULT'}>
          {priceRangesToRender}
        </select>
      </div>
      <button className="filter__button filter__button--phone" onClick={() => {props.setShowFilter(false); onButtonClick()}}>Filter results</button>
      <button className="filter__button filter__button--full-screen" onClick={() => onButtonClick()}>Filter results</button>
    </div>
  )
}

export default withRouter(SidebarFilter);
import React, { useState, useEffect } from 'react'

import BooksRenderer from '../utils/BookRendererUtils'

import * as BOOKS_API from '../api/books-api'

function Bestsellers(props) {

  const [books, setBooks] = useState([])

  // Calls the API to get the bestsellers and sets the state on page load
  useEffect(() => {
    BOOKS_API.getBestsellers(result => {
      setBooks(result);
    })
  }, [])

  if(books.length > 0) {
    return(
      <div className="max-width">
        <BooksRenderer data={books}/>
      </div>
    )
  } else {
    return(
      <div></div>
    )
  }
}

export default Bestsellers;
import React, { useState, useEffect } from 'react'
import {withRouter} from 'react-router-dom'
import { useCookies } from 'react-cookie'

import * as BOOK_FETCHER from '../utils/BookFetchUtils'
import * as SHOPPING_CART_UTILS from '../utils/ShoppingCartUtils'
import * as FAVORITES_UTILS from '../utils/FavoritesUtils'
import Carousel from '../components/Carousel'

const DESCRIPTION_CHARACTER_LIMIT = 850;

function Book(props) {

  const [book, setBook] = useState({
    author: {}, 
    publisher: {}, 
    format: {}, 
    language: {}, 
    series: {}, 
    genres: [{}]
  });
  const [cookies, setCookie] = useCookies(['shoppingCart', 'favorites']);
  const [showMore, setShowMore] = useState(false);
  const [showLess, setShowLess] = useState(true);

  // On load, fetches the book by the ISBN13 typed in the URL
  useEffect(() => {
    BOOK_FETCHER.fetchBookByIsbn13(props.match.params.isbn13, result => {
      setBook(result);
    }, props.refreshToken);
  }, [])

  // Function that adds a book to the cart
  function addToCartButtonFunction(book) {
    if(book.quantity > 1 && SHOPPING_CART_UTILS.getAmount(book, cookies.shoppingCart) < book.quantity) {
      var newCart = SHOPPING_CART_UTILS.addToShoppingCart(book, cookies.shoppingCart);
      setCookie('shoppingCart', newCart, { path: '/', maxAge: 7776000 });
    }
  }

  // Function that toggles the favorite status of the book
  function toggleFavoritesFunction(book, target) {
    if(book.author !== undefined) {
      var favorites = FAVORITES_UTILS.toggleFavorites(book, target, cookies.favorites);
      setCookie('favorites', favorites, { path: '/', maxAge: 7776000 });  
    }
  }

  const setShowMoreFunction = () => { setShowMore(true); setShowLess(false); };
  const setShowLessFunction = () => { setShowMore(false); setShowLess(true); };

  // Renders the descriptions
  function renderDescription(description) { 
    var toReturn = [];
    var newDescription = "";
    if(description !== undefined) {
      newDescription = description;
      if(showMore === false) {
        newDescription = description.substring(0, DESCRIPTION_CHARACTER_LIMIT);
      }
      toReturn.push(newDescription)
    }
    toReturn.push(
      <span key={Math.random()}>
        <button key={Math.random()} className="show-button" style={{display: book.description && book.description.length > DESCRIPTION_CHARACTER_LIMIT && showLess ? 'inline' : 'none'}} onClick={() => setShowMoreFunction()}> Read more</button>
        <br/>
        <button className="show-button" style={{display: book.description && showMore ? 'inline' : 'none'}} onClick={() => setShowLessFunction()}>Read less</button>
      </span>)
    return toReturn;
  }

  // Renders teh availability
  function renderAvailability(value) {
    if(value <= 0) {
      return "OUT OF STOCK";
    } else if (value === 1) {
      return "ONLY 1 LEFT";
    } else if (value === 2) {
      return "ONLY 2 LEFT";
    } else if (value === 3) {
      return "ONLY 3 LEFT";
    } else if (value <= 10) {
      return "LIMITED QTY";
    } else if (value > 10) {
      return "AVAILABLE";
    }
  }

  const buttonColor = {
    color: `${book.quantity > 10 ? 'green' : (book.quantity > 0 ? '#fcb103' : 'red')}`
  }

  // Renders the genres
  function renderGenres() {
    var elements = [];
    book.genres.forEach(genre => {
      var text = `${genre.name},`
      if(elements.length === book.genres.length - 1) text = text.substring(0, text.length-1);
      elements.push(
        <div key={Math.random()} style={{display: 'inline-block', paddingRight: '3px'}}><a className="primary-dark" href={`/book/genreId/${genre.id}`}>{text}</a></div>
      )
    })
    return elements;
  }

  // Renders the date
  function renderDate(date) {
    var options = { year: 'numeric', month: 'short', day: 'numeric' };
    var timeThen = parseInt(String(date).replace('.', ""));
    var dateThen = new Date(timeThen);
    return dateThen.toLocaleDateString("en-GB", options);
  }

  // Function that fetches similar books
  function fetchSimilarBooks(isbn13, callback) {
    BOOK_FETCHER.fetchSimilarBooks(isbn13, result => {
      callback(result)
    }, props.refreshToken);
  }

  // Function that fetches books by genre
  function fetchBooksByGenre(genre, callback) {
    BOOK_FETCHER.fetchBooksByGenre(genre, result => {
      callback(result)
    }, props.refreshToken);
  }

  // Function that fetches books belonging in the same series
  function fetchBooksBySeriesId(seriesId, callback) {
    BOOK_FETCHER.fetchBooksBySeriesId(seriesId, result => {
      callback(result)
    }, props.refreshToken);
  }

  // Function that returns a genre (that's not fiction or nonfiction)
  function findValidGenre(genres) {
    var genreToReturn = ""
    
    if(genres.length === 1) {
      genreToReturn = genres[0];
    } else {
      genres.forEach(genre => {
        if(String(genre.name).toLowerCase() !== "fiction" && String(genre.name).toLowerCase() !== "Nonfiction") {
          genreToReturn = genre;
        }
      })
    }
    return genreToReturn;
  }

  var genreToRender = findValidGenre(book.genres);

  return(
    <main>
      {book.author && book.series && book.format && book.publisher && <div className="book max-width">
        <div className="book-block details-block flex flex-jc-c">
          <div className="book__title hide-for-tablet">
            {book.name}
            {book.author && <div className="book__author">by <a href={`/book/authorId/${book.author.id}`} className="primary-dark">{book.author.name}</a></div> }
          </div>
          <div className="image__wrapper">
            {book.isbn13 && <img src={`https://beyondbooks.sergiudeaj.ro/assets/images/books/${book.isbn13}.jpg`} alt={book.isbn13} width={280} className="book__image"/> }
          </div>
          
          <div className="hide-for-not-desktop">
            <div className="description-block">
              <div className="book__title--desktop">
                {book.name}
                {book.author && <div className="book__author">by <a href={`/book/authorId/${book.author.id}`} className="primary-dark">{book.author.name}</a></div> }
              </div>
              <div className="book__description">
                {book.description && renderDescription(book.description)}
              </div>
            </div>
          </div>

          <div>
            <div className="book__title hide-for-desktop hide-for-mobile">
              {book.name}
              {book.author && <div className="book__author">by <a href={`/book/authorId/${book.author.id}`} className="primary-dark">{book.author.name}</a></div> }
            </div>
            <div className="book__details flex flex-fd-c">
              <button className="book__button" onClick={() => addToCartButtonFunction(book)}>ADD TO BASKET</button>
              <div className="flex">
                <div className="flex flex-fd-c" style={{width: '100%'}}>
                  <div className="book__details--price flex flex-jc-c primary-dark">{book.discount === 0 ? null : <><span>{book.price}</span>&nbsp;</>} {Math.round((book.price - (book.discount/100 * book.price)) * 100)/100} €</div>
                  <div className="book__details--availability flex flex-jc-c" style={buttonColor}>{renderAvailability(book.quantity)}</div>
                </div>
                <div className="book__details--favorite flex flex-jc-c flex-ai-c" onClick={(event) => {toggleFavoritesFunction(book, event.target)}}><i className={`${FAVORITES_UTILS.checkFavorites(book.isbn13, cookies.favorites) ? 'fas' : 'far'} fa-heart ${FAVORITES_UTILS.checkFavorites(book.isbn13, cookies.favorites) ? 'fa-heart--liked' : ''}`}></i></div>
              </div>
              <div className="book__details--genres">Genres: {renderGenres()}</div>
              <div className="book__details--series" style={{display: `${book.series.id === 1 ? 'none' : 'block'}`}}>Series: <a className="primary-dark" href={`/book/seriesId/${book.series.id}`}>{book.series.name}</a></div>
              <div className="book__details--format">Format: <span>{book.format.name}</span></div>
              <div className="book__details--language">Language: <span>{book.language.name}</span></div>
              <div className="book__details--pages">Pages: <span>{book.pages}</span></div>
              <div className="book__details--dimensions">Dimensions: <span>{book.width} x {book.height}mm</span> </div>
              <div className="book__details--publisher">Publisher: <span>{book.publisher.name}</span></div>
              <div className="book__details--isbn13">ISBN13: <span>{book.isbn13}</span></div>
              <div className="book__details--release-date">Release Date: <span>{renderDate(book.releaseDate)}</span></div>
            </div>
          </div>
        </div> 

        <div className="book-block description-block hide-for-desktop">
          <div className="block__title primary-dark">Description</div>
          <div className="book__description">
            {book.description && renderDescription(book.description)}
          </div>
        </div>   

        <div className="block">
        <h2>{`More like ${book.name}`}</h2>
        {book.isbn13 !== undefined && <Carousel fetcher={fetchSimilarBooks} value={book.isbn13}/>}
      </div>     
      <div className="block">
        <h2>{`Bestsellers in ${genreToRender.name}`}</h2>
        {genreToRender.name !== undefined && <Carousel fetcher={fetchBooksByGenre} value={genreToRender.name}/>}
      </div>   
      <div className="block" style={{display: `${book.series.id === 1 ? 'none' : 'block'}`}}>
        <h2>{`Other books in ${book.series.name}`}</h2>
        {book.series.id !== undefined && <Carousel fetcher={fetchBooksBySeriesId} value={book.series.id}/>}
      </div>  
    </div> }
  </main>
  )
}

export default withRouter(Book);
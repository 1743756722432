import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'

import * as API_USERS from '../api/users-api'
import * as MESSAGE_BOX_UTILS from '../utils/MessageBoxUtils'

function LoginForm(props) {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Validation for the Email address
  function validateEmail() {
    let emailCheck = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email.length > 0 && emailCheck.test(email);
  }

  // Validation for the Password
  function validatePassword() {
    return password.length > 7;
  }

  // Validates both the Password and Email
  function validateFields() {
    return validateEmail() && validatePassword();
  }

  //Function that logs the user in
  function handleLogin(event) {
    event.preventDefault();

    // If the fields are valid, call the API to log the user in. If this results in status 401, announce that the email or password were wrong
    if(validateFields()) {
      let user = {
        email: String(email),
        password: String(password)
      }
      API_USERS.loginUser(user, result => {
        if(result != null) {
          if(result === 401) {
            MESSAGE_BOX_UTILS.addMessageBox("Invalid email or password. Please try again.", "OK")
          } else {
            props.setRefreshToken(result.refreshToken);
            localStorage.setItem('accessToken', result.accessToken);
            props.setLoggedIn(true);
            props.history.push('/');
          }
        }
      })
    } else {
      if(!validateEmail()) {
        MESSAGE_BOX_UTILS.addMessageBox("Invalid email address. Please use a valid email address.", "OK")
      } else if(!validatePassword()) {
        MESSAGE_BOX_UTILS.addMessageBox("Invalid password! Passwords must be at least 8 characters long.", "OK")
      }
    }
  }

  const onInputSelect = (target) => target.parentElement.style.backgroundColor = "white";
  const onInputDeselect = (target) => target.parentElement.style.backgroundColor = "#FFDACC"
  const showPassword = (target) => target.parentElement.previousSibling.type = "text"
  const hidePassword = (target) => target.parentElement.previousSibling.type = "password";

  return(
    <form className="login flex flex-fd-c flex-ai-c flex-jc-c">
      <img className="hide-for-tablet" src="https://beyondbooks.sergiudeaj.ro/assets/images/others/reader4.png" alt="Reader" width="207" height="200"/>
      <h1 className="login__header">Sign in</h1>
      <div className="input__wrapper flex flex-ai-c">
        <i className="fas fa-envelope"></i>
        <input type="text" className="login__input" onSelect={e => onInputSelect(e.target)} onBlur={e => onInputDeselect(e.target)} onChange={e => setEmail(e.target.value)} placeholder="Email"/>
      </div>
      <div className="input__wrapper flex flex-ai-c">
        <i className="fas fa-lock"></i>
        <input type="password" className="login__input" onSelect={e => onInputSelect(e.target)} onBlur={e => onInputDeselect(e.target)} onChange={e => setPassword(e.target.value)} placeholder="Password"/>
        <div className="vision-toggle" onClick={e => e.preventDefault()} onMouseDown={e => showPassword(e.target)} onMouseUp={e => hidePassword(e.target)} onMouseLeave={e =>  hidePassword(e.target)}><i className="fas fa-eye"></i></div>
      </div>
      <a className="login__link" href="/login/recovery">Forgot your Password?</a>
      <button className="login__button" onClick={event => handleLogin(event)}>Sign In</button>
      <div className="error-box__space"></div>
    </form>
  )
}

export default withRouter(LoginForm);
import React, { useState, useEffect } from 'react'

import Book from '../components/Book'
import SidebarFilter from '../components/SidebarFilter'

function BooksRendererUtils(props) {

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);

  function nextPage() { setPage(page + 1); }
  function previousPage() { setPage(page - 1); }
  function setPageFun(value) { setPage(Number(value))}
  function resetPage() { setPage(1); }

  const SHOW_COUNT = 20;
  var elements = [];
  var buttons = [];

  useEffect(() => {
    elements = [];
    renderFilteredResults();
  }, [page])

  useEffect(() => {
    resetPage();
  }, [data])

  function dataSetter(filteredData) {
    setData(filteredData);
  }

  // Renders the filtered results
  function renderFilteredResults() {
    const initialBooks = data;
    sessionStorage.setItem('shownDataSize', initialBooks.length);
    if(initialBooks !== null) {
      for(let i = SHOW_COUNT * (page - 1); i < (SHOW_COUNT * page); i++) {
        if(i <= initialBooks.length && initialBooks[i] !== undefined) {
          elements.push(<Book book={initialBooks[i]} key={"book " + initialBooks[i].id}/>)
        }
      }
    }
  }

  renderFilteredResults();

  // Function that decides if the page button should be rendered or not
  function doIRenderButtonFunction(i) {
    var boolean = false;
    var numberOfPages = Math.ceil(data.length / SHOW_COUNT);

      if(page === 1 || page === 2) {
        if(i === 1 || i === 2 || i === 3 || i === 4 || i === 5) {
          boolean = true;
        }
      } else if(page === numberOfPages || page === numberOfPages - 1) {
        if(i === numberOfPages || i === numberOfPages-1 || i === numberOfPages-2 || i === numberOfPages-3 || i === numberOfPages-4) {
          boolean = true;
        }
      } else {
        if(i > page - 3 && i < page + 3) {
          boolean = true;
        }
      }

    return boolean;
  }

  // Function that renders the buttons
  function renderButtons() {
    buttons = [];

    var numberOfPages = Math.ceil(data.length / SHOW_COUNT);
  
    for(let i = 1; i <= numberOfPages; i++) {
      buttons.push(<button key={Math.random()}
        style={{  display: `${doIRenderButtonFunction(i) ? 'inline-block' : 'none'}`, 
                  backgroundColor: `${i === page ? 'lightsalmon' : 'white'}`}} 
        className={`page__button ${i === page ? 'page__button--active' : 'page__button--inactive'}`} onClick={() => setPageFun(i)}>{i}</button>)
    }
  }

  renderButtons();

  return(
    <div className="grid grid--search-page">
      <div className="sidebar__filter__wrapper--phone">
        <div className="sidebar__wrapper" style = {{display: `${props.showFilter ? 'block' : 'none'}`}}>
          <SidebarFilter 
            data={props.data} 
            term={props.term} 
            urlAppend = {props.urlAppend}
            dataSetter={dataSetter}
            setShowFilter = {props.setShowFilter}
            refreshToken = { props.refreshToken }
            pre = {'phone-'}
          />
        </div>
      </div>
      <div className="hide-phone sidebar__wrapper">
        <SidebarFilter 
          data={props.data} 
          term={props.term} 
          urlAppend = {props.urlAppend}
          dataSetter={dataSetter}
          setShowFilter = {props.setShowFilter}
          refreshToken = { props.refreshToken }
          pre = {''}
        />
      </div>
      <div className="content__wrapper">
        <div className="buttons__container" style={{display: `${data.length <= SHOW_COUNT ? 'none' : 'block'}`}}>
          <div className="buttons__wrapper">
            <button className="page__button page__button--previous" style={{display: `${page === 1 ? 'none' : 'inline-block'}`}} onClick={() => previousPage()}><i className="fas fa-angle-left"></i></button>
              {buttons}
            <button className="page__button page__button--next" style={{display: `${page * SHOW_COUNT > sessionStorage.getItem('shownDataSize') ? 'none' : 'inline-block'}`}} onClick={() => nextPage()}><i className="fas fa-angle-right"></i></button>
          </div>
        </div>
        <div className="books__wrapper">
          {elements}
        </div>
        <div className="buttons__container" style={{display: `${data.length <= SHOW_COUNT ? 'none' : 'block'}`}}>
          <div className="buttons__wrapper">
            <button className="page__button page__button--previous" style={{display: `${page === 1 ? 'none' : 'inline-block'}`}} onClick={() => previousPage()}><i className="fas fa-angle-left"></i></button>
              {buttons}
            <button className="page__button page__button--next" style={{display: `${page * SHOW_COUNT > sessionStorage.getItem('shownDataSize') ? 'none' : 'inline-block'}`}} onClick={() => nextPage()}><i className="fas fa-angle-right"></i></button>
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default BooksRendererUtils;
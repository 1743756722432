import React from 'react'
import { useCookies } from 'react-cookie'

import * as SHOPPING_CART_UTILS from '../utils/ShoppingCartUtils'
import * as FAVORITES_UTILS from '../utils/FavoritesUtils'

//Component that renders a book displayed in a carousel or in a list
function Book(props) {

  const bookNameLen = props.book.name.length;
  const [cookies, setCookie] = useCookies(['shoppingCart', 'favorites']);

  // Function that adds the rendered book the shopping cart
  function addToCartButtonFunction(book) {
    if(book.quantity > 1 && SHOPPING_CART_UTILS.getAmount(book, cookies.shoppingCart) < book.quantity) {
      var newCart = SHOPPING_CART_UTILS.addToShoppingCart(book, cookies.shoppingCart);
      setCookie('shoppingCart', newCart, { path: '/',  maxAge: 7776000 });
    }
  }

  // Function that toggles the favorites state of the rendered book
  function toggleFavoritesFunction(book, target) {
    var favorites = FAVORITES_UTILS.toggleFavorites(book, target, cookies.favorites);
    setCookie('favorites', favorites, { path: '/', maxAge: 7776000 });
  }

  return(
    <>
      <div className="book-card">
        
        <a href={"/book/" + props.book.isbn13}><img  className="book-card__image"
              src={`https://beyondbooks.sergiudeaj.ro/assets/images/books/${props.book.isbn13}.jpg`} 
              alt={props.book.name} 
              height={290}/></a>
        <a href={"/book/" + props.book.isbn13} className={`book-card__name ${bookNameLen > 23 ? 'book-card__name--twoliner' : 'book-card__name--oneliner'}`}>
          {props.book.name}
        </a>
        <a  href={"/book/authorId/" + props.book.author.id}
            className="book-card__author">
              {props.book.author.name}
        </a>
        <div className="flex">
          <button className="book-card__button"
                  onClick={() => addToCartButtonFunction(props.book)}
                  onMouseEnter={(event) => {event.target.innerHTML = "Add To Basket"}}
                  onMouseLeave={(event) => {
                    event.target.innerHTML = `${props.book.discount === 0 ? '' : '<span>' + `${props.book.price}` + '</span>&nbsp;'} ${Math.round((props.book.price - (props.book.discount/100 * props.book.price)) * 100)/100} €`;
                  }}>
            {props.book.discount === 0 ? null : <><span>{props.book.price}</span>&nbsp;</>} {Math.round((props.book.price - (props.book.discount/100 * props.book.price)) * 100)/100} €
          </button>
          <div className="book-card__favorite flex flex-jc-c flex-ai-c" onClick={(event) => {toggleFavoritesFunction(props.book, event.target)}}><i className={`${FAVORITES_UTILS.checkFavorites(props.book.isbn13, cookies.favorites) ? 'fas' : 'far'} fa-heart ${FAVORITES_UTILS.checkFavorites(props.book.isbn13, cookies.favorites) ? 'fa-heart--liked' : ''}`}></i></div>
        </div>
      </div>
    </>
  )
}

export default Book;
import RestClient from './rest-client'
import { HOST } from './hosts'

import jwt_decode from 'jwt-decode'

const endpoint = {
  get_books: '/api/book/getAll',
  get_book_by_isbn13: '/api/book/getByIsbn13/?isbn13=',
  get_books_similar_by_isbn13: '/api/book/getSimilar/?isbn13=',
  get_books_by_genre: '/api/book/getByGenre/?genre=',
  get_books_by_genre_id:  '/api/book/byGenre/?id=',
  get_books_by_author_id:  '/api/book/byAuthor/?id=',
  get_books_by_series_id: '/api/book/bySeries/?id=',
  get_books_by_keyword: '/api/book/search/?searchTerm=',
  get_bestsellers: '/api/book/getBestsellers',
  get_new: '/api/book/getNew',
  save_book_image: '/api/book/add',
  update_book: '/api/book/update',
  delete_book: '/api/book/delete/?id=',
  recommendations_purchase_history: '/api/book/recommendationsByPurchaseHistory/?id='
}

// Function that retrieves a list of the top 100 most sold books
function getBestsellers(callback) {
  let request = new Request(HOST.backend_api + endpoint.get_bestsellers, {
    method: 'GET',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  });
  RestClient.performRequest(request, callback);
}

// Function that retrieves a list of the 100 newest books
function getNewReleases(callback) {
  let request = new Request(HOST.backend_api + endpoint.get_new, {
    method: 'GET',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  });
  RestClient.performRequest(request, callback);
}

// Function that retrieves a list of recommended books based on the user's purchase history
function getRecommendationsBasedOnPurchaseHistory(callback, refresh) {
  let id = -1;
  if(Date.now() >= jwt_decode(localStorage.getItem('accessToken')).exp * 1000) { 
    RestClient.refreshAccessToken(refresh, result => {
      id = jwt_decode(result).sub;
    });
  } else {
    id = jwt_decode(localStorage.getItem('accessToken')).sub;
  }
  let request = new Request(HOST.backend_api + endpoint.recommendations_purchase_history + id, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  })
  if(Date.now() >= jwt_decode(localStorage.getItem('accessToken')).exp * 1000) { 
    RestClient.refreshAccessToken(refresh, result => {
      request.headers.append('Authorization', `Bearer ${result}`)
      RestClient.performRequest(request, callback);
    });
  } else {
    request.headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
    RestClient.performRequest(request, callback);
  }
}

// Function that retrieves a list of books that have the genre specified by the genre id
function getByGenreId(id, callback) {
  let request = new Request(HOST.backend_api + endpoint.get_books_by_genre_id + id, {
    method: 'GET',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  });
  RestClient.performRequest(request, callback);
}

// Function that retrieves a list of books that have the author specified by the author id
function getByAuthorId(id, callback) {
  let request = new Request(HOST.backend_api + endpoint.get_books_by_author_id + id, {
    method: 'GET',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  });
  RestClient.performRequest(request, callback);
}

// Function that retrieves a list of books that have the series specified by the series id
function getBySeriesId(id, callback) {
  let request = new Request(HOST.backend_api + endpoint.get_books_by_series_id + id, {
    method: 'GET',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  });
  RestClient.performRequest(request, callback);
}

// Function that retrieves a list of books that match the searched keyword
function getByKeyword(keyword, callback) {
  let request = new Request(HOST.backend_api + endpoint.get_books_by_keyword + keyword, {
    method: 'GET',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  });
  RestClient.performRequest(request, callback);
}

// Function that retrieves all books
function getAll(callback) {
  let request = new Request(HOST.backend_api + endpoint.get_books, {
    method: 'GET',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  });
  RestClient.performRequest(request, callback);
}

// Function that retrieves a list of books similar to the book identified by its' ISBN13
function getSimilar(isbn13, callback) {
  let request = new Request(HOST.backend_api + endpoint.get_books_similar_by_isbn13 + isbn13, {
    method: 'GET',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  });
  RestClient.performRequest(request, callback);
}

// Function that retrieves a book based on its' ISBN13
function getByISBN13(isbn13, callback) {
  let request = new Request(
    HOST.backend_api + 
    endpoint.get_book_by_isbn13 + 
    isbn13, {
    method: 'GET',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  });
  RestClient.performRequest(request, callback);
}

// Function that retrieves a list of books that have the genre specified by the genre name
function getByGenre(genre, callback) {
  let request = new Request(HOST.backend_api + endpoint.get_books_by_genre + genre, {
    method: 'GET',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  });
  RestClient.performRequest(request, callback);
}

// Function that adds a new book
function save(book, callback, refresh) {
  let request = new Request(HOST.backend_api + endpoint.save_book_image, {
    method: 'POST',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(book)
  });
  if(Date.now() >= jwt_decode(localStorage.getItem('accessToken')).exp * 1000) { 
    RestClient.refreshAccessToken(refresh, result => {
      request.headers.append('Authorization', `Bearer ${result}`)
      RestClient.performRequest(request, callback);
    });
  } else {
    request.headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
    RestClient.performRequest(request, callback);
  }
}

// Function that updates a book
function update(book, callback, refresh) {
  let request = new Request(HOST.backend_api + endpoint.update_book, {
    method: 'PUT',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(book)
  });
  if(Date.now() >= jwt_decode(localStorage.getItem('accessToken')).exp * 1000) { 
    RestClient.refreshAccessToken(refresh, result => {
      request.headers.append('Authorization', `Bearer ${result}`)
      RestClient.performRequest(request, callback);
    });
  } else {
    request.headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
    RestClient.performRequest(request, callback);
  }
}

// Function that deletes a book identified by its' ID
function deleteById(id, callback, refresh) {
  let request = new Request(HOST.backend_api + endpoint.delete_book + id, {
    method: 'DELETE',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  });
  if(Date.now() >= jwt_decode(localStorage.getItem('accessToken')).exp * 1000) { 
    RestClient.refreshAccessToken(refresh, result => {
      request.headers.append('Authorization', `Bearer ${result}`)
      RestClient.performRequest(request, callback);
    });
  } else {
    request.headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
    RestClient.performRequest(request, callback);
  }
}

export { getAll, getBestsellers, getNewReleases, getByGenreId, getByAuthorId, getBySeriesId, getSimilar, getByISBN13, getByKeyword, getByGenre, save, update, deleteById, getRecommendationsBasedOnPurchaseHistory };
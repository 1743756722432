import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'

import * as API_USERS from '../api/users-api'
import * as MESSAGE_BOX_UTILS from '../utils/MessageBoxUtils'

function RegisterForm(props) {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");

  // Validation for the Email address
  function validateEmail() {
    let emailCheck = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email.length > 0 && emailCheck.test(email);
  }

  // Validation for the Password
  function validatePassword() {
    return password.length > 7;
  }

  // Validation for the Name
  function validateName() {
    return name.length > 0;
  }

  // Validation for the Email address, Password and Name
  function validateFields() {
    return validateEmail() && validatePassword() && validateName();
  }

  // If the fields are valid, call the API to register a new user. If the fields aren't valid, throw an error message
  function handleRegister(event) {
    event.preventDefault();
    if(validateFields()) {
      var success = false;
      let user = {
        email: String(email),
        password: String(password),
        name: String(name)
      }
      API_USERS.registerUser(user, result => {
        if(result != null) {
          setTimeout(function afterOneSeconds() {
            success = result.success;
            if(success) {
              API_USERS.loginUser(user, result => {
                if(result != null) {
                  props.setRefreshToken(result.refreshToken);
                  localStorage.setItem('accessToken', result.accessToken);
                  props.setLoggedIn(true);
                  props.history.push('/');
                }
              });
            }
          })
        }
      })
    } else {
      if(!validateEmail()) {
        MESSAGE_BOX_UTILS.addMessageBox("Invalid email address. Please use a valid email address.", "OK")
      } else if(!validatePassword()) {
        MESSAGE_BOX_UTILS.addMessageBox("Invalid password! Passwords must be at least 8 characters long.", "OK")
      } else if(!validateName()) {
        MESSAGE_BOX_UTILS.addMessageBox("Invalid name!", "OK")
      }
    }
  }

  const onInputSelect = (target) => target.parentElement.style.backgroundColor = "white";
  const onInputDeselect = (target) => target.parentElement.style.backgroundColor = "#FFDACC"
  const showPassword = (target) => target.parentElement.previousSibling.type = "text";
  const hidePassword = (target) => target.parentElement.previousSibling.type = "password";

  return(
    <form className="login flex flex-fd-c flex-ai-c flex-jc-c">
      <img className="hide-for-tablet" src="https://beyondbooks.sergiudeaj.ro/assets/images/others/reader1.png" alt="Reader" width="220" height="200"/>
      <h1 className="register__header">Sign Up</h1>
      <div className="input__wrapper flex flex-ai-c">
        <i className="fas fa-user"></i>
        <input type="text" className="login__input" onSelect={e => onInputSelect(e.target)} onBlur={e => onInputDeselect(e.target)} onChange={e => setName(e.target.value)} placeholder="Name"/>
      </div>
      <div className="input__wrapper flex flex-ai-c">
        <i className="fas fa-envelope"></i>
        <input type="text" className="login__input" onSelect={e => onInputSelect(e.target)} onBlur={e => onInputDeselect(e.target)} onChange={e => setEmail(e.target.value)} placeholder="Email"/>
      </div>
      <div className="input__wrapper flex flex-ai-c">
        <i className="fas fa-lock"></i>
        <input type="password" className="login__input" onSelect={e => onInputSelect(e.target)} onBlur={e => onInputDeselect(e.target)} onChange={e => setPassword(e.target.value)} placeholder="Password"/>
        <div className="vision-toggle" onClick={e => e.preventDefault()} onMouseDown={e => showPassword(e.target)} onMouseUp={e => hidePassword(e.target)} onMouseLeave={e =>  hidePassword(e.target)}><i className="fas fa-eye"></i></div>
      </div>
      <p className="input__text">Passwords must be at least 8 characters long</p>
      <button className="login__button" onClick={event => handleRegister(event)}>Sign Up</button>
    </form>
  )
}

export default withRouter(RegisterForm);
import React, { useState } from 'react'

import * as API from '../api/author-api'
import FormInput from '../components/FormInput'

function AuthorUpdate(props) {

  const [id, setId] = useState(-1);
  const [name, setName] = useState("");

  // Function that updates an author
  function update(event) {
    event.preventDefault();
    let element = { name: name }
    API.update(id, element, result => {
      window.location.reload();
    }, props.refreshToken)
  }

  return(
    <div className="form-block__wrapper">
      <form className="form-block">
        <div className="form-block__element--id form-block__element">
          <label className="form-block__label">ID</label>
          <input type="text" className="form-block__input--id form-block__input" placeholder="1" onChange={event => {setId(event.target.value)}}/>
        </div>
        <FormInput label="NAME" placeholder="Humanitas" onChange={setName}/>
        <button className="form-block__button" onClick={event => {update(event)}}>Update</button>
      </form>
    </div>
  )
}

export default AuthorUpdate;
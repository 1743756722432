import React, { useState, useEffect } from 'react'

import Book from '../components/Book'

function Favorites(props) {

  const [favoritesSize, setFavoritesSize] = useState(-1);

  useEffect(() => {
    setFavoritesSize(props.favorites.length);
  }, [props.favorites])

  const books = [];

  // Prepares the books for rendering
  props.favorites.forEach(book => {
    books.push(<Book book={book} key={"book " + book.id}/>)
  })

  // Renders the favorites
  function FavoritesRenderer() {
    if(favoritesSize === 0) {
      return <div className="empty-basket max-width pad-10 flex flex-jc-c flex-ai-c flex-fd-c">
                <h3>You don't have any books on your wishlist</h3>
                <p>To add products to your wishlist please return to the store</p>
                <a href="/">Return to the store</a>
             </div>
    }
    return <div className="favorites-page__container">
      <div className="max-width pad-10">
        <div className="favorites-page">
          <h2>Your Wishlist</h2>
          <div className="books__wrapper">
            {books}
          </div>
        </div>
      </div>
    </div>
  }

  return(<FavoritesRenderer refreshToken = { props.refreshToken }/>)
}

export default Favorites;
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'

import * as USERS_API from '../api/users-api'

function RecoverySuccess(props) {

  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Function that validates the password, and calls the API, to call the backend to update the user's password
  function onSubmit(event) {
    event.preventDefault();
    if(validatePassword()) {
      let req = {
        token: props.match.params.token,
        newPassword: password,
      }
      USERS_API.completeRecovery(req, callback => {
        window.location.reload();
      }, props.refreshToken)
    } else {
      setErrorMessage("Invalid Password! Passwords must be at least 8 characters long.")
    }
  }

  function validatePassword() {
    return password.length > 7;
  }

  const onInputSelect = (target) => target.parentElement.style.backgroundColor = "white";
  const onInputDeselect = (target) => target.parentElement.style.backgroundColor = "#FFDACC";
  const showPassword = (target) => target.parentElement.previousSibling.type = "text"
  const hidePassword = (target) => target.parentElement.previousSibling.type = "password";

  return(
    <div className="recovery__container">
      <div className="recovery__wrapper">
        <form className="flex flex-ai-c flex-jc-sb flex-fd-c">
          <h1>Please choose a strong new password</h1>
          <p>Passwords must be at least 8 characters long.</p>
          <div className="input__wrapper flex flex-ai-c">
            <i className="fas fa-lock"></i>
            <input type="password" className="login__input" onSelect={e => onInputSelect(e.target)} onBlur={e => onInputDeselect(e.target)} onChange={e => setPassword(e.target.value)} placeholder="Password"/>
            <div className="vision-toggle" onClick={e => e.preventDefault()} onMouseDown={e => showPassword(e.target)} onMouseUp={e => hidePassword(e.target)} onMouseLeave={e =>  hidePassword(e.target)}><i className="fas fa-eye"></i></div>
          </div>
          <button className="cta__button" onClick={(event) => onSubmit(event)}>Submit</button>
          <div className="error-box__space"></div>
          <div className="error-box flex-ai-c" style={{display: `${errorMessage === "" ? 'none' : 'flex'}`}}>{errorMessage}<span className="closebtn" onClick={(event) => event.target.parentElement.style.display='none'}>&times;</span></div>
        </form>
      </div>
    </div>
  )
}

export default withRouter(RecoverySuccess);
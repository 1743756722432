import React, { useState } from 'react'

import * as API from '../api/books-api'

function BookQuantity(props) {

  const [isbn13, setIsbn13] = useState("");
  const [quantity, setQuantity] = useState(0);

  // Function that returns the list of genre IDs of the genres in the list it gets as a parameter
  function returnListOfGenreIds(genres) {
    var genreIds = [];
    genres.forEach(genre => {
      genreIds.push(genre.id);
    })
    return genreIds;
  }

  //Function that adds quantity to a certain book
  function addQuantity(event) {
    event.preventDefault()
    API.getByISBN13(isbn13.trim(), result => {
      event.preventDefault()

      let book = {
        isbn13: result.isbn13,
        name: result.name,
        pages: result.pages,
        width: result.width,
        height: result.height,
        depth: result.depth,
        weight: result.weight,
        author: result.author.id,
        quantity: parseInt(result.quantity) + parseInt(quantity),
        publisher: result.publisher.id,
        language: result.language.id,
        format: result.format.id,
        genres: returnListOfGenreIds(result.genres),
        series: result.series.id,
        discount: result.discount,
        releaseDate: result.releaseDate,
        sold: result.sold,
        price: result.price,
        description: result.description
      }
      API.update(book, res => {
        window.location.reload();
      }, props.refreshToken)
    })
  }

  return(
    <div className="form-block__wrapper">
      <form className="form-block">
        <div className="form-block__element--isbn13 form-block__element">
          <label className="form-block__label">ISBN13</label>
          <input type="text" className="form-block__input--isbn13 form-block__input" placeholder="9780575089938" onChange={event => {setIsbn13(event.target.value)}}/>
        </div>
        <div className="form-block__element--quantity form-block__element">
          <label className="form-block__label">QUANTITY</label>
          <input type="text" className="form-block__input--quantity form-block__input" placeholder="50" onChange={event => {setQuantity(event.target.value)}}/>
        </div>
        <button className="form-block__button" onClick={event => {addQuantity(event)}}>Add Quantity</button>
      </form>
    </div>
  )
}

export default BookQuantity;
import jwt_decode from 'jwt-decode'

import RestClient from './rest-client'
import { HOST } from './hosts'

const endpoint = {
  get_all: '/api/format/getAll',
  save: '/api/format/add',
  get_by_name: '/api/format/get',
  get_by_id: '/api/format/getById/?id=',
  delete: '/api/format/delete/?id=',
  update: '/api/format/update/?id='
};

// Function that adds a new format
function save(format, callback, refresh) {
  let request = new Request(HOST.backend_api + endpoint.save, {
    method: 'POST',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(format)
  });
  if(Date.now() >= jwt_decode(localStorage.getItem('accessToken')).exp * 1000) { 
    RestClient.refreshAccessToken(refresh, result => {
      request.headers.append('Authorization', `Bearer ${result}`)
      RestClient.performRequest(request, callback);
    });
  } else {
    request.headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
    RestClient.performRequest(request, callback);
  }
}

// Function that retrieves all formats
function getAll(callback) {
  let request = new Request(HOST.backend_api + endpoint.get_all, {
    method: 'GET',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  });
  RestClient.performRequest(request, callback);
}

// Function that retrieves a format identified by its' name
function getByName(format, callback, refresh) {
  let request = new Request(HOST.backend_api + endpoint.get_by_name, {
    method: 'GET',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(format)
  });
  if(Date.now() >= jwt_decode(localStorage.getItem('accessToken')).exp * 1000) { 
    RestClient.refreshAccessToken(refresh, result => {
      request.headers.append('Authorization', `Bearer ${result}`)
      RestClient.performRequest(request, callback);
    });
  } else {
    request.headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
    RestClient.performRequest(request, callback);
  }
}

// Function that retrieves a format identified by its' ID
function getById(id, callback, refresh) {
  let request = new Request(HOST.backend_api + endpoint.get_by_id + id, {
    method: 'GET',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  });
  if(Date.now() >= jwt_decode(localStorage.getItem('accessToken')).exp * 1000) { 
    RestClient.refreshAccessToken(refresh, result => {
      request.headers.append('Authorization', `Bearer ${result}`)
      RestClient.performRequest(request, callback);
    });
  } else {
    request.headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
    RestClient.performRequest(request, callback);
  }
}

// Function that deletes a format identified by its' ID
function deleteById(id, callback, refresh) {
  let request = new Request(HOST.backend_api + endpoint.delete + id, {
    method: 'DELETE',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  });
  if(Date.now() >= jwt_decode(localStorage.getItem('accessToken')).exp * 1000) { 
    RestClient.refreshAccessToken(refresh, result => {
      request.headers.append('Authorization', `Bearer ${result}`)
      RestClient.performRequest(request, callback);
    });
  } else {
    request.headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
    RestClient.performRequest(request, callback);
  }
}

// Function that updates a format identified by its' ID
function update(id, format, callback, refresh) {
  let request = new Request(HOST.backend_api + endpoint.update + id, {
    method: 'PUT',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(format)
  });
  if(Date.now() >= jwt_decode(localStorage.getItem('accessToken')).exp * 1000) { 
    RestClient.refreshAccessToken(refresh, result => {
      request.headers.append('Authorization', `Bearer ${result}`)
      RestClient.performRequest(request, callback);
    });
  } else {
    request.headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
    RestClient.performRequest(request, callback);
  }
}

export { getAll, save, getByName, getById, deleteById, update };
import React from 'react'

function Footer(props) {

  return(
    <footer className="max-width pad-10 flex">
      <div>
        <h1>Follow Us</h1>
        <a href="https://www.facebook.com"><i className="fab fa-facebook-square"></i></a>
        <a href="https://www.twitter.com"><i className="fab fa-twitter-square"></i></a>
        <a href="https://www.instagram.com"><i className="fab fa-instagram-square"></i></a>
        <a href="https://www.youtube.com"><i className="fab fa-youtube-square"></i></a>
      </div>
      <div>
        <h1>Free Delivery</h1>
        <div>for all orders above 50€</div>
      </div>
      <div>
        <h1>Contact Us</h1>
        <div>Monday - Friday: 09:00 - 21:00</div>
        <div>Saturday - Sunday: 09:00 - 18:00</div>
        <div>Phone: 0123 456 789</div>
        <div>Email: contact@beyondbooks.ro</div>
      </div>
    </footer>
  )
}

export default Footer;
import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Redirect } from 'react-router-dom'

import jwt_decode from 'jwt-decode'

import Home from '../pages/Home'
import Login from '../pages/Login'
import Book from '../pages/Book'
import Basket from '../pages/Basket'
import Delivery from '../pages/Delivery'
import Recovery from '../pages/Recovery'
import RecoverySuccess from '../pages/RecoverySuccess'
import Search from '../utils/Search'
import Favorites from '../pages/Favorites'
import Orders from '../pages/Orders'
import Order from '../pages/Order'
import PersonalData from '../pages/PersonalData'
import Bestsellers from '../pages/Bestsellers'
import NewReleases from '../pages/NewReleases'
import Moderator from '../pages/Moderator'
import ModOrders from '../pages/ModOrders'
import Admin from '../pages/Admin'
import * as SEARCH_FETCHERS from '../utils/SearchFetchers'

// Function that renders the main part of the web page dynamically
function Content(props) {

  const shoppingCart = props.shoppingCart;
  const favorites = props.favorites;
  const setNumberOfBooks = props.cartBookCountSetter;
  const setFavoritesCount = props.favoritesCountSetter;

  // Hook that calculates and updates the number of books in the shopping cart
  useEffect(() => {
    var counter = 0;
    if(shoppingCart != null) { 
      shoppingCart.forEach(book => {
        counter += parseInt(book.amount);
      })
    }  
    setNumberOfBooks(counter);
  }, [shoppingCart, setNumberOfBooks])

  // Hook that calculates and updates the number of books in the favorites list
  useEffect(() => {
    var counter = 0;
    if(favorites != null) { 
      favorites.forEach(book => {
        counter += parseInt(book.amount);
      })
    }  
    setFavoritesCount(counter);
  }, [favorites, setFavoritesCount])

  // Function that redirects to the Home Page
  function redirectToHome() {
    return <Redirect to="/" />
  }

  // Function that prevents unauthenticated users from accessing the Delivery Page by URL, redirecting them to the Login Page
  function handleDeliverLogin() {
    if(localStorage.getItem('accessToken') != null) {
      return <Delivery  refreshToken={props.refreshToken} 
                            shoppingCart={props.shoppingCart} 
                            cartBookCount={props.cartBookCount}/>
    }
    return <Redirect to="/login"/>
  }

  // Function that prevents unauthenticated users from accessing the Personal Data Page by URL, redirecting them to the Login Page
  function handlePersonalDataLogin() {
    if(localStorage.getItem('accessToken') != null) {
      return <PersonalData refreshToken = { props.refreshToken }/>
    }
    return <Redirect to="/login"/>
  }

  // Function that prevents unauthenticated users from accessing the Orders Page by URL, redirecting them to the Login Page
  function handleOrdersLogin() {
    if(localStorage.getItem('accessToken') != null) {
      return <Orders refreshToken = { props.refreshToken }/>
    }
    return <Redirect to="/login"/>
  }

  // Function that prevents unauthenticated users from accessing the Moderator Page by URL, redirecting them to the Home Page
  function handleModeratorLogin() {
    if(localStorage.getItem('accessToken') !== null && (jwt_decode(localStorage.getItem('accessToken')).role === 2 || jwt_decode(localStorage.getItem('accessToken')).role === 3)) {
      return <Moderator refreshToken={props.refreshToken}/> 
    } 
    return <Redirect to="/" />
  }

  // Function that prevents unauthenticated users from accessing the Moderator Orders Page by URL, redirecting them to the Home Page
  function handleModOrdersLogin() {
    if(localStorage.getItem('accessToken') !== null && (jwt_decode(localStorage.getItem('accessToken')).role === 2 || jwt_decode(localStorage.getItem('accessToken')).role === 3)) {
      return <ModOrders refreshToken={props.refreshToken}/> 
    } 
    return <Redirect to="/" />
  }

  // Function that prevents unauthenticated users from accessing the Admin Page by URL, redirecting them to the Home Page
  function handleAdminLogin() {
    if(localStorage.getItem('accessToken') !== null && jwt_decode(localStorage.getItem('accessToken')).role === 3) {
      return <Admin refreshToken={props.refreshToken}/> 
    } 
    return <Redirect to="/" />
  }

  // The Router renders the correct Page based on the URL accessed
  return(
    <>
      <Router>
        <Switch>
          <Route exact path='/' render={() => 
            <Home 
              isLoggedIn={props.isLoggedIn}
              refreshToken={props.refreshToken}
            />}
          />

          <Route path='/book/:isbn13' render={() => 
            <Book 
              refreshToken = { props.refreshToken }
            />}
          />

          <Route path='/book/genreId/:id/genre/:genre/availability/:availability/language/:language/format/:format/priceRange/:price' render={() => <Search fetcher={SEARCH_FETCHERS.fetchByGenreId} urlAppend='/book/genreId/' refreshToken = { props.refreshToken }/>}/>
          <Route path='/book/genreId/:id' render={() => <Search fetcher={SEARCH_FETCHERS.fetchByGenreId} urlAppend='/book/genreId/' refreshToken = { props.refreshToken }/>}/>

          <Route path='/book/authorId/:id/genre/:genre/availability/:availability/language/:language/format/:format/priceRange/:price' render={() => <Search fetcher={SEARCH_FETCHERS.fetchByAuthorId} urlAppend='/book/authorId/' refreshToken = { props.refreshToken }/>}/>
          <Route path='/book/authorId/:id' render={() => <Search fetcher={SEARCH_FETCHERS.fetchByAuthorId} urlAppend='/book/authorId/' refreshToken = { props.refreshToken }/>}/>

          <Route path='/book/seriesId/:id/genre/:genre/availability/:availability/language/:language/format/:format/priceRange/:price' render={() => <Search fetcher={SEARCH_FETCHERS.fetchBySeriesId} urlAppend='/book/seriesId/' refreshToken = { props.refreshToken }/>}/>
          <Route path='/book/seriesId/:id' render={() => <Search fetcher={SEARCH_FETCHERS.fetchBySeriesId} urlAppend='/book/seriesId/' refreshToken = { props.refreshToken }/>}/>

          <Route path='/search/searchTerm/:searchTerm/genre/:genre/availability/:availability/language/:language/format/:format/priceRange/:price' render={() => <Search fetcher={SEARCH_FETCHERS.fetchByKeyword} type='searchTerm' urlAppend='/search/searchTerm/' refreshToken = { props.refreshToken }/>}/>
          <Route path='/search/searchTerm/:id' type='searchTerm' render={() => <Search fetcher={SEARCH_FETCHERS.fetchByKeyword} urlAppend='/search/searchTerm/' refreshToken = { props.refreshToken }/>}/>

          <Route exact path='/basket/delivery' render={() => handleDeliverLogin()}/>
          <Route exact path='/basket' render={() => <Basket shoppingCart={props.shoppingCart} refreshToken = { props.refreshToken }/>}/>
          <Route exact path='/wishlist' render={() => <Favorites favorites={props.favorites} refreshToken = { props.refreshToken }/>}/>

          <Route exact path='/personal-data' render={() => handlePersonalDataLogin()}/>
          <Route path='/order/:orderId' render={() => <Order refreshToken = { props.refreshToken }/>}/>
          <Route exact path='/orders' render={() => handleOrdersLogin()}/>

          <Route exact path='/bestsellers' render={() => <Bestsellers refreshToken = { props.refreshToken }/>}/>
          <Route exact path='/new-releases' render={() => <NewReleases refreshToken = { props.refreshToken }/>}/>

          <Route exact path='/mod/orders' render={() => handleModOrdersLogin()}/>
          <Route exact path='/mod' render={() => handleModeratorLogin()}/>
          <Route exact path='/admin' render={() => handleAdminLogin()}/>

          <Route exact path='/recovery/:token' render={() => <RecoverySuccess refreshToken = { props.refreshToken }/>}/>
          <Route exact path='/login/recovery' render={() => <Recovery refreshToken = { props.refreshToken }/>}/>
          <Route exact path='/login' render={() => 
            <Login
              setLoggedIn={props.setLoggedIn}
              refreshToken={props.refreshToken}
              setRefreshToken={props.setRefreshToken}
            />}/>
          <Route exact path='/logout' render={() => redirectToHome()}/>
        </Switch>
      </Router>
    </>
  )
}

export default Content;
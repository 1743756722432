import React, { useState, useEffect } from 'react'
import * as USERS_API from '../api/users-api'
import * as MESSAGE_BOX_UTILS from '../utils/MessageBoxUtils'
import jwt_decode from 'jwt-decode'

function PersonalData(props) {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [newName, setNewName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newPassword, setNewPassword]  = useState("");
  const [password, setPassword]  = useState("");

  // On page load, loads the personal data of the user 
  useEffect(() => {
    if(localStorage.getItem('accessToken') !== null && localStorage.getItem('accessToken') !== undefined) {
      var id = jwt_decode(localStorage.getItem('accessToken')).sub;
      if(id !== undefined) {
        USERS_API.getPersonalData(id, result => {
          setName(result.name);
          setEmail(result.email);
        }, props.refreshToken)
      }
    }
  }, [])

  // Function that updates the name of the user
  function updateNameFunction(event) {
    event.preventDefault();
    if(jwt_decode(localStorage.getItem('accessToken')).sub !== null && jwt_decode(localStorage.getItem('accessToken')).sub !== undefined) {
      let id = jwt_decode(localStorage.getItem('accessToken')).sub;
      let data = {
        name: newName,
        password: password,
      }
      USERS_API.updateName(id, data, result => {
        if(result === "401") {
          MESSAGE_BOX_UTILS.addMessageBox("Invalid Password. Please try again.", "OK");
        } else { 
          document.location.reload();
        }
      }, props.refreshToken);
    } else document.location.reload();
  }

  // Function that updates the email of the user
  function updateEmailFunction(event) {
    event.preventDefault();
    if(jwt_decode(localStorage.getItem('accessToken')).sub !== null && jwt_decode(localStorage.getItem('accessToken')).sub !== undefined) {
      let id = jwt_decode(localStorage.getItem('accessToken')).sub;
      let data = {
        email: newEmail,
        password: password,
      }
      USERS_API.updateEmail(id, data, result => {
        if(result === "401") {
          MESSAGE_BOX_UTILS.addMessageBox("Invalid Password. Please try again.", "OK");
        } else { 
          document.location.reload();
        }
      }, props.refreshToken);
    } else document.location.reload();
  }

  // Function that updates the password of the user
  function updatePasswordFunction(event) {
    event.preventDefault();
    if(jwt_decode(localStorage.getItem('accessToken')).sub !== null && jwt_decode(localStorage.getItem('accessToken')).sub !== undefined) {
      let id = jwt_decode(localStorage.getItem('accessToken')).sub;
      let data = {
        oldPassword: password,
        newPassword: newPassword,
      }
      USERS_API.updatePassword(id, data, result =>  {
        if(result === "401") {
          MESSAGE_BOX_UTILS.addMessageBox("Invalid Old Password. Please try again.", "OK");
        } else { 
          document.location.reload();
        }
      }, props.refreshToken);
    } else document.location.reload();
  }

  const onInputSelect = (target) => target.parentElement.style.backgroundColor = "white";
  const onInputDeselect = (target) => target.parentElement.style.backgroundColor = "#FFDACC"
  const showPassword = (target) => target.parentElement.previousSibling.type = "text";
  const hidePassword = (target) => target.parentElement.previousSibling.type = "password";

  // Function that closes the pop-up when clicked off of it
  function closeOnClickOutside(event) {
    if(event.target.id === "form-box-area--name" || event.target.id === "form-box-area--email" || event.target.id === "form-box-area--password") {
      event.target.classList.remove("show");
    }
  }

  // Function that clears the fields
  function clearFields() {
    setNewName("");
    setNewEmail("");
    setNewPassword("");
    setPassword("");
  }

  return(
    <>
      <main className="flex">
        <div className="personal-data flex flex-fd-c flex-ai-c flex-jc-c">
          <h1>Your Personal Data</h1>
          <div className="personal-data__element--name personal-data__element flex flex-fd-r flex-jc-sb">
            <div className="flex flex-fd-c">
              <div className="bold">Name:</div>
              <div>{name}</div>
            </div>
            <div id="form-box-area--name" className="hide" onClick={(event) => closeOnClickOutside(event)}>
              <form id="form-box" className="flex flex-fd-c flex-ai-c flex-jc-sb">
                <div id="form-box__header">Change your Name</div>
                <div className="input__wrapper flex flex-ai-c">
                  <i className="fas fa-user"></i>
                  <input type="text" className="login__input" onSelect={e => onInputSelect(e.target)} onBlur={e => onInputDeselect(e.target)} onChange={e => {e.preventDefault(); setNewName(e.target.value)}} placeholder="New Name"/>
                </div>
                <div className="input__wrapper flex flex-ai-c">
                  <i className="fas fa-lock"></i>
                  <input type="password" className="login__input" onSelect={e => onInputSelect(e.target)} onBlur={e => onInputDeselect(e.target)} onChange={e => setPassword(e.target.value)} placeholder="Current Password"/>
                  <div className="vision-toggle" onClick={e => e.preventDefault()} onMouseDown={e => showPassword(e.target)} onMouseUp={e => hidePassword(e.target)} onMouseLeave={e =>  hidePassword(e.target)}><i className="fas fa-eye"></i></div>
                </div>
                <button id="form-box__button" className="btn--cta" onClick={(event) => updateNameFunction(event)}>Save</button>
                <button className="btn--close--black" onClick={() => document.getElementById("form-box-area--name").classList.remove("show")}>
                  <i className="fas fa-times"></i>
                </button>
              </form>
            </div>
            <button className="btn--cta" onClick={() => {clearFields(); document.getElementById("form-box-area--name").classList.add("show")}}>Edit</button>
          </div>
          <div className="personal-data__element--email personal-data__element flex flex-fd-r flex-jc-sb">
            <div className="flex flex-fd-c">
              <div className="bold">Email:</div>
              <div>{email}</div>
            </div>
            <div id="form-box-area--email" className="hide" onClick={(event) => closeOnClickOutside(event)}>
              <form id="form-box" className="flex flex-fd-c flex-ai-c flex-jc-sb">
                <div id="form-box__header">Change your Email</div>
                <div className="input__wrapper flex flex-ai-c">
                  <i className="fas fa-envelope"></i>
                  <input type="text" className="login__input" onSelect={e => onInputSelect(e.target)} onBlur={e => onInputDeselect(e.target)} onChange={e => setNewEmail(e.target.value)} placeholder="New Email"/>
                </div>
                <div className="input__wrapper flex flex-ai-c">
                  <i className="fas fa-lock"></i>
                  <input type="password" className="login__input" onSelect={e => onInputSelect(e.target)} onBlur={e => onInputDeselect(e.target)} onChange={e => setPassword(e.target.value)} placeholder="Current Password"/>
                  <div className="vision-toggle" onClick={e => e.preventDefault()} onMouseDown={e => showPassword(e.target)} onMouseUp={e => hidePassword(e.target)} onMouseLeave={e =>  hidePassword(e.target)}><i className="fas fa-eye"></i></div>
                </div>
                <button id="form-box__button" className="btn--cta"  onClick={(event) => updateEmailFunction(event)}>Save</button>
                <button className="btn--close--black" onClick={() => document.getElementById("form-box-area--email").classList.remove("show")}>
                  <i className="fas fa-times"></i>
                </button>
              </form>
            </div>
            <button className="btn--cta" onClick={() => {clearFields(); document.getElementById("form-box-area--email").classList.add("show")}}>Edit</button>
          </div>
          <div className="personal-data__element--password personal-data__element flex flex-fd-r flex-jc-sb">
            <div className="flex flex-fd-c">
              <div className="bold">Password:</div>
              <div>********</div>
            </div>
            <div id="form-box-area--password" className="hide" onClick={(event) => closeOnClickOutside(event)}>
              <form id="form-box" className="flex flex-fd-c flex-ai-c flex-jc-sb">
                <div id="form-box__header">Change your Password</div>
                <div className="input__wrapper flex flex-ai-c">
                  <i className="fas fa-lock"></i>
                  <input type="password" className="login__input" onSelect={e => onInputSelect(e.target)} onBlur={e => onInputDeselect(e.target)} onChange={e => setPassword(e.target.value)} placeholder="Current Password"/>
                  <div className="vision-toggle" onClick={e => e.preventDefault()} onMouseDown={e => showPassword(e.target)} onMouseUp={e => hidePassword(e.target)} onMouseLeave={e =>  hidePassword(e.target)}><i className="fas fa-eye"></i></div>
                </div>
                <div className="input__wrapper flex flex-ai-c">
                  <i className="fas fa-lock"></i>
                  <input type="password" className="login__input" onSelect={e => onInputSelect(e.target)} onBlur={e => onInputDeselect(e.target)} onChange={e => setNewPassword(e.target.value)} placeholder="New Password"/>
                  <div className="vision-toggle" onClick={e => e.preventDefault()} onMouseDown={e => showPassword(e.target)} onMouseUp={e => hidePassword(e.target)} onMouseLeave={e =>  hidePassword(e.target)}><i className="fas fa-eye"></i></div>
                </div>
                <button id="form-box__button" className="btn--cta"  onClick={(event) => updatePasswordFunction(event)}>Save</button>
                <button className="btn--close--black" onClick={() => document.getElementById("form-box-area--password").classList.remove("show")}>
                  <i className="fas fa-times"></i>
                </button>
              </form>
            </div>
            <button className="btn--cta" onClick={() => {clearFields(); document.getElementById("form-box-area--password").classList.add("show")}}>Edit</button>
          </div>
        </div>
      </main>
    </>
  )
}

export default PersonalData;
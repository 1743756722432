/* 
  Function that creates an announcement with the given text and book and adds it to the announcement area.
  After 7.5 seconds, the announcement goes away and after another .350 seconds, the announcement is removed compeltely.
*/
function addAnnouncement(text, optionalBook) {
  let area = document.getElementById("announcement-area");
  let announcement = document.createElement("div");
  announcement.classList.add("announcement");
  announcement.classList.add("announcement--active");
  announcement.innerHTML = `${text}`;
  if(optionalBook != null) announcement.innerHTML = `<p><span>${optionalBook}</span> ${text}</p>`
  area.appendChild(announcement);

  setTimeout(() => {
    announcement.classList.add("announcement--inactive")
  }, 7500);

  setTimeout(() => {
    area.removeChild(announcement);
  }, 7850);
}

export { addAnnouncement };
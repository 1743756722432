import React, { useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'
import { useCookies } from 'react-cookie'

import * as API_USERS from '../api/users-api'
import * as API_AUTHORS from '../api/author-api'
import * as API_SERIES from '../api/series-api'
import * as SHOPPING_CART_UTILS from '../utils/ShoppingCartUtils'
import * as FAVORITES_UTILS from '../utils/FavoritesUtils'

var favoritesDivHovered = false;
var favoritesButtonHovered = false;
var cartDivHovered = false;
var cartButtonHovered = false;
var accountDivHovered = false;
var accountButtonHovered = false;
var categoriesDivHovered = false;
var categoriesButtonHovered = false;

function Header(props) {

  const [searchTerm, setSearchTerm] = useState("");
  const [favoritesHoverShow, setFavoritesHoverShow] = useState(false);
  const [cartHoverShow, setCartHoverShow] = useState(false);
  const [accountHoverShow, setAccountHoverShow] = useState(false);
  const [categoriesShow, setCategoriesShow] = useState(false);
  const [favoritesSize, setFavoritesSize] = useState(-1);
  const [cartSize, setCartSize] = useState(-1);
  const [authorsSold, setAuthorsSold] = useState([]);
  const [seriesSold, setSeriesSold] = useState([]);
  const [categoriesShowMobile, setCategoriesShowMobile] = useState(false);

  const [cookies, setCookie] = useCookies(['shoppingCart', 'favorites']);

  //Updates the authors and series rankings on load
  useEffect(() => {
    API_AUTHORS.getRankedAuthors((result) => {
      setAuthorsSold(result);
    }, props.refreshToken)

    API_SERIES.getRankedSeries((result) => {
      setSeriesSold(result);
    }, props.refreshToken)
  }, [])

  // function that calls the utility class to add a new book to the shopping cart
  function addToCartButtonFunction(book) {
    var newCart = SHOPPING_CART_UTILS.addToShoppingCart(book, cookies.shoppingCart);
    setCookie('shoppingCart', newCart, { path: '/',  maxAge: 7776000 });
  }

  // function that calls the utility class to remove a new book to the shopping cart
  function removeBookFromCart(bookToRemove) {
    let newCart = SHOPPING_CART_UTILS.removeBookFromCart(bookToRemove, cookies.shoppingCart);
    setCookie('shoppingCart', newCart, { path: '/',  maxAge: 7776000 });
  }

  // function that calls the utility class to toggle the favorite status of a book
  function toggleFavoritesFunction(book, target) {
    var favorites = FAVORITES_UTILS.toggleFavorites(book, target, cookies.favorites);
    setCookie('favorites', favorites, { path: '/', maxAge: 7776000 });
  }

  // When the favorites list changes, update the displayed size
  useEffect(() => {
    if(props.favorites !== undefined && props.favorites !== null) {
      setFavoritesSize(props.favorites.length);
    }
  }, [props.favorites])

  // When the shopping cart changes, update the displayed size
  useEffect(() => {
    if(props.shoppingCart !== undefined && props.shoppingCart !== null) {
      setCartSize(props.shoppingCart.length);
    }
  }, [props.shoppingCart])

  // Toggle for the hamburger button
  function hamburgerFunction(classlist) {
    var btnHamburger = undefined;
    if(classlist.contains("mobile-burger")) {
      btnHamburger = document.getElementById("hamburger");
    } else if(classlist.contains("desktop-burger")) {
      btnHamburger = document.getElementById("hamburger2");
    }
    if(btnHamburger.classList.contains("hamburger-open")) {
      btnHamburger.classList.remove("hamburger-open");
    } else {
      btnHamburger.classList.add("hamburger-open");
    }
  }

  // Checks if the favorites button or div are hovered over after 1 second, hides the div if they aren't hovered over
  function onFavoritesLeave() {
    setTimeout(() => {
      if(!favoritesDivHovered && !favoritesButtonHovered) {
        setFavoritesHoverShow(false);
      }
    }, 1000)
  }

  // Checks if the shopping cart button or div are hovered over after 1 second, hides the div if they aren't hovered over
  function onCartLeave() {
    setTimeout(() => {
      if(!cartDivHovered && !cartButtonHovered) {
        setCartHoverShow(false);
      }
    }, 1000)
  }

  // Checks if the account button or div are hovered over after 1 second, hides the div if they aren't hovered over
  function onAccountLeave() {
    setTimeout(() => {
      if(!accountDivHovered && !accountButtonHovered) {
        setAccountHoverShow(false);
      }
    }, 1000)
  }

  // Checks if the category button or div are hovered over after 1 second, hides the div if they aren't hovered over
  function onCategoriesLeave() {
    setTimeout(() => {
      if(!categoriesDivHovered && !categoriesButtonHovered) {
        setCategoriesShow(false);
      }
    }, 1000)
  }

  // Function that calculates the total price
  function calculateTotal() {
    var total = 0;
      if(props.shoppingCart !== undefined && props.shoppingCart !== null) {
      props.shoppingCart.forEach(book => {
        total += (book.amount * (book.price - (book.discount/100 * book.price)))
      })
    }
    return Math.round((total + Number.EPSILON) * 100)/100;
  }

  // Builds the list of books to render for the favorites list hover
  const favoritesList = [];
  if(props.favorites !== undefined && props.favorites !== null) {
    props.favorites.forEach((book) => {
      favoritesList.push(
      <div className="hover__book flex flex-fd-r flex-jc-c flex-ai-c relative" key={book.isbn13}>
        <a href={"/book/" + book.isbn13}><img src={`https://beyondbooks.sergiudeaj.ro/assets/images/books/${book.isbn13}.jpg`} alt={book.name} height="100" className="hover__book--image"/></a>
        <a href={"/book/" + book.isbn13} className="texts">
          <div className="title">
            {book.name}
          </div>
          <div className="author">
            {book.author.name}
          </div>
        </a>
        <div className="price flex flex-fd-c">
          <div>{book.price}&nbsp;</div>
          <div>{Math.round((book.price - (book.discount/100 * book.price)) * 100)/100} €</div>
        </div>
        <button className="hover__add" onClick={() => addToCartButtonFunction(book)}><i className="fas fa-shopping-cart"></i><span>&nbsp;</span>Add to Cart</button>
        <button className="hover__remove" onClick={(event) => {toggleFavoritesFunction(book, event.target)}}><i className="fas fa-times"></i></button>
      </div>)
    })
  }

  // Builds the list of books to render for the shopping cart hover
  const cartList = [];
  if(props.shoppingCart !== undefined && props.shoppingCart !== null) {
    props.shoppingCart.forEach((book) => {
      cartList.push(<div className="hover__book flex flex-fd-r flex-jc-c flex-ai-c relative" key={book.isbn13}>
      <a href={"/book/" + book.isbn13}><img src={`https://beyondbooks.sergiudeaj.ro/assets/images/books/${book.isbn13}.jpg`} alt={book.name} height="100" className="hover__book--image"/></a>
      <a href={"/book/" + book.isbn13} className="texts">
        <div className="title">
          {book.name}
        </div>
        <div className="author">
          {book.author.name}
        </div>
      </a>
      <div className="amount">x{book.amount}</div>
      <div className="price flex flex-fd-c">
        <div>{book.price}&nbsp;</div>
        <div>{Math.round((book.price - (book.discount/100 * book.price)) * 100)/100} €</div>
      </div>
      <button className="hover__remove" onClick={() => removeBookFromCart(book)}><i className="fas fa-times"></i></button>
    </div>)
    })
  }

  // Renders the shopping cart hover
  function CartHoverRenderer() {
    if(cartSize === 0) {
      return  <div className="flex flex-ai-c flex-jc-c flex-fd-c empty-hover">
                You have no items in the shopping cart.
              </div>
    }
    return  <div className="flex flex-ai-c flex-jc-c flex-fd-c">
              <div className="hover__title flex flex-jc-c flex-ai-c">Your Basket</div>
              <div className="cart__wrapper">{cartList}</div>
              <div className="hover__total flex flex-jc-sb">
                <div>TOTAL: <span>{props.cartBookCount} books</span></div>
                <div>{calculateTotal()} €</div>
              </div>
              <a href="/basket" className="hover__link">Go to your Basket</a>
            </div>
  }

  // Renders the favorites hover
  function FavoritesHoverRenderer() {
    if(favoritesSize === 0) {
      return  <div className="flex flex-ai-c flex-jc-c flex-fd-c empty-hover">
                You have no books on your wishlist.
              </div>
    }
    return  <div className="flex flex-ai-c flex-jc-c flex-fd-c">
              <div className="hover__title flex flex-jc-c flex-ai-c">Your Wishlist</div>
              <div className="favorites__wrapper">{favoritesList}</div>
              <a href="/wishlist" className="hover__link">See your Wishlist</a>
            </div>
  }

  // Renders the account hover
  function AccountHoverRenderer() {
    if(props.loggedIn) {
      return(
        <div className="flex flex-fd-c flex-ai-c flex-jc-c hover__account">
          <ul className="flex flex-fd-c flex-ai-c flex-jc-c hover__account__ul">
            <li><a className="hover__button flex flex-jc-c flex-ai-c" href="/personal-data">Personal Data</a></li>
            <li><a className="hover__button flex flex-jc-c flex-ai-c" href="/orders">Your Orders</a></li>
            <li><a className="hover__button flex flex-jc-c flex-ai-c" href="/logout" onClick={handleLogout}>Logout</a></li>
          </ul>
        </div>
      )
    } else {
      return(
        <div className="flex flex-fd-c flex-ai-c flex-jc-c">
          <div className="flex flex-jc-c flex-ai-c flex-fd-c empty-hover">
            Login into your account or Register.
            <a href="/login" className="btn--cta">Login/Register</a>
          </div>
        </div>
      )
    }
  }

  // Function that logs the user out
  function handleLogout() {
    let refreshToken = {
      refreshToken: props.refreshToken,
    }
    API_USERS.logoutUser(refreshToken);
    props.removeRefreshToken();
    localStorage.removeItem('accessToken');
    props.setLoggedIn(false);
  }

  const adminButtonStyle = { 
    display: `${localStorage.getItem('accessToken') !== undefined && localStorage.getItem('accessToken') !== null && jwt_decode(localStorage.getItem('accessToken')).role === 3 ? 'flex' : 'none'}`
  }
  const modButtonStyle = { 
    display: `${localStorage.getItem('accessToken') !== undefined && localStorage.getItem('accessToken') !== null && (jwt_decode(localStorage.getItem('accessToken')).role === 2 || jwt_decode(localStorage.getItem('accessToken')).role === 3) ? 'flex' : 'none'}`
  }

  // Builds the list of authors to render for the categories hover
  const authorsToRender = [];
  if(authorsSold.length > 10) {
    authorsSold.slice(0, 10).forEach(authorSold => {
      authorsToRender.push(
        <li key={authorSold.author.id}><a href={`/book/authorId/${authorSold.author.id}`}>{authorSold.author.name}</a></li>
      )
    })
  }

  // Builds the list of series to render for the categories hover
  const seriesToRender = [];
  if(seriesSold.length > 10) {
    seriesSold.slice(0, 10).forEach(serieSold => {
      seriesToRender.push(
        <li key={serieSold.series.id}><a href={`/book/seriesId/${serieSold.series.id}`}>{serieSold.series.name}</a></li>
      )
    })
  }

  return(
    <header id="header">
      <nav className="flex flex-ai-c flex-jc-sb max-width">
        <ul className="flex">
          <li className="flex flex-ai-c">
            <button onClick={(event) => {hamburgerFunction(event.target.classList); if(categoriesShowMobile) setCategoriesShowMobile(false); else setCategoriesShowMobile(true)}} id="hamburger" className="hamburger hide-for-tablet mobile-burger">
              <span className="mobile-burger"></span>
              <span className="mobile-burger"></span>
              <span className="mobile-burger"></span>
            </button>
            <div id="categories-menu--mobile" className={`hide ${categoriesShowMobile ? 'show' : ''}`}>
              <div className="categories-list--mobile">
                  <ul className="genre-ul">
                    <li><h1>Categories</h1></li>
                    <li><a href="/book/genreId/2">Adventure</a></li>
                    <li><a href="/book/genreId/19">Classics</a></li>
                    <li><a href="/book/genreId/3">Crime</a></li>
                    <li><a href="/book/genreId/1">Fantasy</a></li>
                    <li><a href="/book/genreId/17">Fiction</a></li>
                    <li><a href="/book/genreId/4">Food & Drink</a></li>
                    <li><a href="/book/genreId/5">For Kids</a></li>
                    <li><a href="/book/genreId/6">Geography</a></li>
                    <li><a href="/book/genreId/8">History</a></li>
                    <li><a href="/book/genreId/9">Horror</a></li>
                    <li><a href="/book/genreId/18">Nonfiction</a></li>
                    <li><a href="/book/genreId/28">Personal Development</a></li>
                    <li><a href="/book/genreId/26">Poetry</a></li>
                    <li><a href="/book/genreId/10">Political & Legal</a></li>
                    <li><a href="/book/genreId/11">Romance</a></li>
                    <li><a href="/book/genreId/12">Science Fiction</a></li>
                    <li><a href="/book/genreId/23">Social</a></li>
                    <li><a href="/book/genreId/13">Sport</a></li>
                    <li><a href="/book/genreId/14">Technology</a></li>
                    <li><a href="/book/genreId/15">Thriller</a></li>
                    {authorsToRender}
                    {seriesToRender}
                  </ul>
                <button className="categories__close--mobile" onClick={() => {setCategoriesShowMobile(false)}}><i className="fas fa-times"></i></button>
              </div>
            </div>
          </li>
          <li className="relative hide-for-tablet" onMouseEnter={() => {accountButtonHovered = true; setAccountHoverShow(true); setCartHoverShow(false); setFavoritesHoverShow(false)}} onMouseLeave={() => {accountButtonHovered = false; onAccountLeave()}}>
            <div className="link">
              <i className="fas fa-user"></i>
            </div>
            <div id="hover__account" className={`hover__element hover__element--left ${accountHoverShow ? 'hover__element--active' : ''}`} onMouseEnter={() => {accountDivHovered = true; setAccountHoverShow(true)}} onMouseLeave={() => {accountDivHovered = false; onAccountLeave()}}>
              <AccountHoverRenderer refreshToken = { props.refreshToken }/>
            </div>
          </li>
        </ul>
        <a href="/" id="logo">
          <span>beyond</span><span>books</span>
        </a>
        <form className="search flex flex-ai-c flex-jc-c hide-for-mobile" onSubmit={(event) => {event.preventDefault(); window.location.href=`/search/searchTerm/${searchTerm}`}}>
          <button type="submit"><i className="fa fa-search"/></button>
          <input type="text" placeholder="Search by keyword, title, author or ISBN" onChange={event => {setSearchTerm(event.target.value)}}/>
        </form>
        <ul className="flex">
          <li className="relative hide-for-mobile" onMouseEnter={() => {accountButtonHovered = true; setAccountHoverShow(true); setCartHoverShow(false); setFavoritesHoverShow(false)}} onMouseLeave={() => {accountButtonHovered = false; onAccountLeave()}}>
            <div className="link flex flex-fd-r">
              <i className="fas fa-user"></i>
              <span className="hide-for-not-desktop  flex flex-ai-c flex-jc-c">My Account</span>
            </div>
            <div id="hover__account" className={`hover__element ${accountHoverShow ? 'hover__element--active' : ''}`} onMouseEnter={() => {accountDivHovered = true; setAccountHoverShow(true)}} onMouseLeave={() => {accountDivHovered = false; onAccountLeave()}}>
              <AccountHoverRenderer refreshToken = { props.refreshToken }/>
            </div>
          </li>
          <li className="relative" onMouseEnter={() => {favoritesButtonHovered = true; setFavoritesHoverShow(true); setCartHoverShow(false); setAccountHoverShow(false)}} onMouseLeave={() => {favoritesButtonHovered = false; onFavoritesLeave()}}>
            <a href="/wishlist" className="link flex flex-fd-r">
              <i className="fas fa-heart">
                <div className="circle">
                  {props.favoritesCount}
                </div>
              </i>
              <span className="hide-for-not-desktop flex flex-ai-c flex-jc-c">My Wishlist</span>
            </a>
            <div id="hover__favorites" className={`hover__element ${favoritesHoverShow ? 'hover__element--active' : ''}`} onMouseEnter={() => {favoritesDivHovered = true; setFavoritesHoverShow(true)}} onMouseLeave={() => {favoritesDivHovered = false; onFavoritesLeave()}}>
              <FavoritesHoverRenderer refreshToken = { props.refreshToken }/>
            </div>
          </li>
          <li className="relative" onMouseEnter={() => {cartButtonHovered = true; setCartHoverShow(true); setFavoritesHoverShow(false); setAccountHoverShow(false)}} onMouseLeave={() => {cartButtonHovered = false; onCartLeave()}}>
            <a href="/basket" className="link flex flex-fd-r">
              <i className="fas fa-shopping-cart">
                <div className="circle">
                  {props.cartBookCount}
                </div>
              </i>
              <span className="hide-for-not-desktop  flex flex-ai-c flex-jc-c">Shopping Cart</span>
            </a>
            <div id="hover__cart" className={`hover__element ${cartHoverShow ? 'hover__element--active' : ''}`} onMouseEnter={() => {cartDivHovered = true; setCartHoverShow(true)}} onMouseLeave={() => {cartDivHovered = false; onCartLeave()}}>
              <CartHoverRenderer refreshToken = { props.refreshToken }/>
            </div>
          </li>
        </ul>
      </nav>

      <nav className="flex flex-ai-c flex-jc-c max-width hide-for-tablet">
        <form className="search flex flex-ai-c flex-jc-c" onSubmit={(event) => {event.preventDefault(); window.location.href=`/search/searchTerm/${searchTerm}`}}>
          <input type="text" placeholder="Search by keyword, title, author or ISBN" onChange={event => {setSearchTerm(event.target.value)}}/>
          <button type="submit"><i className="fa fa-search"/></button>
        </form>
      </nav>

      <div id="header-two__container">
        <nav className="flex flex-ai-c flex-jc-sb max-width">
          <ul className="flex">
            <li>
              <button className="hamburger hide-for-mobile categories" onMouseEnter={() => {categoriesButtonHovered = true; setCategoriesShow(true)}} onMouseLeave={() => {categoriesButtonHovered = false; onCategoriesLeave()}}>Categories</button>
              <div className="categories__wrapper" onMouseEnter={() => {categoriesDivHovered = true; setCategoriesShow(true)}} onMouseLeave={() => {categoriesDivHovered = false; onCategoriesLeave()}}>
                <div id="categories-menu" className={`hide ${categoriesShow ? 'show' : ''}`}>
                  <div className="categories-list flex">
                    <div className="genre-list">
                      <h1>Top Genres</h1>
                      <ul className="genre-ul">
                        <li><a href="/book/genreId/2">Adventure</a></li>
                        <li><a href="/book/genreId/19">Classics</a></li>
                        <li><a href="/book/genreId/3">Crime</a></li>
                        <li><a href="/book/genreId/1">Fantasy</a></li>
                        <li><a href="/book/genreId/17">Fiction</a></li>
                        <li><a href="/book/genreId/4">Food & Drink</a></li>
                        <li><a href="/book/genreId/5">For Kids</a></li>
                        <li><a href="/book/genreId/6">Geography</a></li>
                        <li><a href="/book/genreId/8">History</a></li>
                        <li><a href="/book/genreId/9">Horror</a></li>
                        <li><a href="/book/genreId/18">Nonfiction</a></li>
                        <li><a href="/book/genreId/28">Personal Development</a></li>
                        <li><a href="/book/genreId/26">Poetry</a></li>
                        <li><a href="/book/genreId/10">Political & Legal</a></li>
                        <li><a href="/book/genreId/11">Romance</a></li>
                        <li><a href="/book/genreId/12">Science Fiction</a></li>
                        <li><a href="/book/genreId/23">Social</a></li>
                        <li><a href="/book/genreId/13">Sport</a></li>
                        <li><a href="/book/genreId/14">Technology</a></li>
                        <li><a href="/book/genreId/15">Thriller</a></li>
                      </ul>
                    </div>
                    <div className="author-list">
                      <h1>Top Authors</h1>
                      <ul className="author-ul">
                        {authorsToRender}
                      </ul>
                    </div>
                    <div className="series-list">
                      <h1>Top Series</h1>
                      <ul className="series-ul">
                        {seriesToRender}
                      </ul>
                    </div>
                    <button className="categories__close" onClick={() => {setCategoriesShow(false)}}><i className="fas fa-times"></i></button>
                  </div>
                </div>
              </div>
            </li>
            <li><a className="link" href="/bestsellers">Bestsellers</a></li>
            <li><a className="link" href="/new-releases">New Releases</a></li>
          </ul>
          <ul className="flex">
            <li><a className="link" href="/mod/orders" style={modButtonStyle}>Orders</a></li>
            <li><a className="link" href="/mod" style={modButtonStyle}>Books</a></li>
            <li><a className="link" href="/admin" style={adminButtonStyle}>Users</a></li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default Header;
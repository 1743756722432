import * as API_BOOKS from '../api/books-api'

// Fetches a book by its' ISBN13
function fetchBookByIsbn13(isbn13, callback, refreshToken) {
  return API_BOOKS.getByISBN13(isbn13, result => {
    if(result != null) {
      callback(result);
    }
  }, refreshToken)
}

// Fetches the books that match a genre, identified by its' name
function fetchBooksByGenre(genre, callback, refreshToken) {
  return API_BOOKS.getByGenre(String(genre).toLowerCase(), result => {
    var books = [];
    result.forEach(book => books.push(book));
    books.sort((a,b) => (a.sold > b.sold) ? -1 : ((b.sold > a.sold) ? 1 : 0));
    books = books.slice(0, 20);
    callback(books);
  }, refreshToken);
}

// Fetches the books that match a genre, identified by its' ID
function fetchBooksByGenreId(genreId, callback, refreshToken) {
  return API_BOOKS.getByGenreId(genreId, result => {
    var books = [];
    result.forEach(book => books.push(book));
    books.sort((a,b) => (a.sold > b.sold) ? -1 : ((b.sold > a.sold) ? 1 : 0));
    callback(books);
  }, refreshToken);
}

// Fetches the books that match a author, identified by its' ID
function fetchBooksByAuthorId(authorId, callback, refreshToken) {
  return API_BOOKS.getByAuthorId(authorId, result => {
    var books = [];
    result.forEach(book => books.push(book));
    books.sort((a,b) => (a.sold > b.sold) ? -1 : ((b.sold > a.sold) ? 1 : 0));
    callback(books);
  }, refreshToken);
}

// Fetches the books that match a series, identified by its' ID
function fetchBooksBySeriesId(seriesId, callback, refreshToken) {
  return API_BOOKS.getBySeriesId(seriesId, result => {
    var books = [];
    result.forEach(book => books.push(book));
    books.sort((a,b) => (a.sold > b.sold) ? -1 : ((b.sold > a.sold) ? 1 : 0));
    callback(books);
  }, refreshToken);
}

// Fetches the books that match a keyword
function fetchBooksByKeyword(keyword, callback, refreshToken) {
  return API_BOOKS.getByKeyword(keyword, result => {
    var books = [];
    result.forEach(book => books.push(book));
    books.sort((a,b) => (a.sold > b.sold) ? -1 : ((b.sold > a.sold) ? 1 : 0));
    callback(books);
  }, refreshToken);
}

// Fetches the books that are similar to a book, identified by its' ISBN13
function fetchSimilarBooks(isbn13, callback, refreshToken) {
  return API_BOOKS.getSimilar(isbn13, result => {
    var books = [];
    result.forEach(book => books.push(book));
    // books.sort((a,b) => (a.sold > b.sold) ? -1 : ((b.sold > a.sold) ? 1 : 0));
    callback(books);
  }, refreshToken)
}

export { fetchBooksByGenre, fetchBookByIsbn13, fetchBooksByGenreId, fetchBooksByAuthorId, fetchBooksBySeriesId, fetchBooksByKeyword, fetchSimilarBooks };
import React, { useState, useEffect } from 'react'
import jwt_decode from 'jwt-decode'
import { useCookies } from 'react-cookie'

import * as ADDRESS_API from '../api/address-api'
import * as ORDERS_API from '../api/orders-api'
import * as SHOPPING_CART_UTILS from '../utils/ShoppingCartUtils'

const FREE_DELIVERY_SUM = 50;

function Delivery(props) {

  const [addresses, setAddresses] = useState([]);
  const [cartSize, setCartSize] = useState(-1);
  const [delivery, setDelivery] = useState(-1);
  const [billing, setBilling] = useState(0);
  const [deliveryOptions, setDeliveryOptions] = useState(1);
  const [payment, setPayment] = useState(1);

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [city, setCity] = useState("");
  const [county, setCounty] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [cookies, setCookie] = useCookies(['shoppingCart', 'favorites']);

  // On load, get the addresses of the user
  useEffect(() => {
    ADDRESS_API.getByUserId(jwt_decode(localStorage.getItem('accessToken')).sub, result => {
      const array = [];
      if(result.length > 0) {
        result.forEach(address => {
          array.push(address);
        });
      }
      setAddresses(array);
    }, props.refreshToken)
  }, [])

  useEffect(() => {
    setCartSize(props.shoppingCart.length);
  }, [props.shoppingCart])

  // Function that places an order
  function placeOrderFunction() {
    const books = [];
    props.shoppingCart.forEach(book => {
      books.push({
        bookId: `${book.id}`,
        amount: `${book.amount}`,
        price: `${Math.round((book.price - (book.discount/100 * book.price))*100)/100}`
      })
    })

    let order = {
      userId: jwt_decode(localStorage.getItem('accessToken')).sub,
      deliveryAddressId: delivery,
      billingAddressId: `${billing === 0 ? delivery : billing}`,
      paymentTypeId: payment,
      deliveryTypeId: deliveryOptions,
      books: books,
      total: `${calculateTotalIncludingDelivery()}`
    }
    ORDERS_API.save(order, response => {
      console.log(response);
      window.location.href = '/'
      setCookie('shoppingCart', [], { path: '/',  maxAge: 7776000 });
    }, props.refreshToken)
  }

  // Functino that calculates the total
  function calculateTotal() {
    var total = 0;
    props.shoppingCart.forEach(book => {
      total += (book.amount * (book.price - (book.discount/100 * book.price)))
    })
    return Math.round((total + Number.EPSILON) * 100)/100;
  }

  // Function that calculates the total including delivery
  function calculateTotalIncludingDelivery() {
    var total = 0;
    props.shoppingCart.forEach(book => {
      total += (book.amount * (book.price - (book.discount/100 * book.price)))
    })
    if(total < FREE_DELIVERY_SUM) {
      if(deliveryOptions === 1) {
        total += 9.95;
      } else if(deliveryOptions === 2) {
        total += 12.95;
      }
    }
    return Math.round((total + Number.EPSILON) * 100)/100;
  }

  // Function that removes a book from the cart
  function removeBookFromCart(bookToRemove) {
    let newCart = SHOPPING_CART_UTILS.removeBookFromCart(bookToRemove, cookies.shoppingCart);
    setCookie('shoppingCart', newCart, { path: '/',  maxAge: 7776000 });
  }

  // Prepares teh books for rendering
  const cartList = [];
  props.shoppingCart.forEach((book) => {
    cartList.push(<div className="hover__book flex flex-fd-r flex-jc-c flex-ai-c relative" key={book.isbn13}>
    <a href={"/book/" + book.isbn13}><img src={`https://beyondbooks.sergiudeaj.ro/assets/images/books/${book.isbn13}.jpg`} alt={book.name} height="100" className="hover__book--image"/></a>
    <a href={"/book/" + book.isbn13} className="texts">
      <div className="title">
        {book.name}
      </div>
      <div className="author">
        {book.author.name}
      </div>
    </a>
    <div className="amount">x{book.amount}</div>
    <div className="price flex flex-fd-c">
      <div>{book.price}&nbsp;</div>
      <div>{Math.round((book.price - (book.discount/100 * book.price)) * 100)/100} €</div>
    </div>
    <button className="hover__remove" onClick={() => removeBookFromCart(book)}><i className="fas fa-times"></i></button>
  </div>)
  })

  // Renders the Delivery Address
  function DeliveryAddressRenderer() {
    if(addresses.length === 0) {
      return <div>You have no addresses. Please add one.</div>
    } 
    return <div>{data}</div>
  }

  // Renders the Billing Address
  function BillingAddressRenderer() {
    if(addresses.length === 0) {
      return <div>You have no addresses. Please add one.</div>
    } 
    return <div>{data2}</div>
  }

  // Renders the Delivery Options
  function DeliveryOptionsRenderer() {
    return <><div className={`radio__element ${1 === deliveryOptions ? 'radio--active' : ''}`} key={1} onClick={() => setDeliveryOptions(1)}>
            <input type="radio" name="delivery__options" value={1} defaultChecked={1 === deliveryOptions ? "checked" : false}/>
            <div className="address__element">
              <div className="flex flex-jc-sb"><p className="bold">Standard Delivery</p><p className="bold">{calculateTotal() > FREE_DELIVERY_SUM ? 'FREE' : '9.95 €'}</p></div>
              <div>Receive in 2-7 working days.</div>
            </div>
          </div>
          <div className={`radio__element ${2 === deliveryOptions ? 'radio--active' : ''}`} key={2} onClick={() => setDeliveryOptions(2)}>
            <input type="radio" name="delivery__options" value={2} defaultChecked={2 === deliveryOptions ? "checked" : false}/>
            <div className="address__element">
              <div className="flex flex-jc-sb"><p className="bold">Express Delivery</p><p className="bold">12.95 €</p></div>
              <div>Receive in 2-3 working days.</div>
            </div>
          </div></>
  }

  // Renders the Payment options
  function PaymentRenderer() {
    return (
    <>
      <div className={`radio__element ${1 === payment ? 'radio--active' : ''}`} key={1} onClick={() => setPayment(1)}>
        <input type="radio" name="payment__options" value={1} defaultChecked={1 === payment ? "checked" : false}/>
        <div className="address__element">
          <p className="bold">Cash on Delivery</p>
        </div>
      </div>
    </>)
  }

  // Renders the hovered shopping cart
  function CartHoverRenderer() {
    if(cartSize === 0) {
      return  <div className="flex flex-ai-c flex-jc-c flex-fd-c empty-hover">
                You have no items in the shopping cart.
              </div>
    }
    return  <div className="flex flex-ai-c flex-jc-c flex-fd-c">
              <h1>Your Basket</h1>
              <div className="cart__wrapper">{cartList}</div>
              <div className="hover__total flex flex-jc-sb" style={{paddingBottom: '0'}}>
                <div>Delivery Fees:</div>
                <div>{calculateTotal() > FREE_DELIVERY_SUM ? 'FREE' : deliveryOptions === 1 ? '9.95 €' : '12.95 €'}</div>
              </div>
              <div className="hover__total flex flex-jc-sb" style={{paddingTop: '2px'}}>
                <div>TOTAL: <span>{props.cartBookCount} books</span></div>
                <div>{Math.round((calculateTotalIncludingDelivery()) * 100) / 100} €</div>
              </div>
              <a href="/basket" className="cta__button">Go to your Basket</a>
            </div>
  }

  // Prepares the delivery addresses for rendering
  const data = [];
  let tester = false;
  addresses.forEach(element => {
    if(delivery === -1 && !tester) setDelivery(element.id); tester = true;
    data.push(
      <div className={`radio__element ${element.id === delivery ? 'radio--active' : ''}`} key={"element " + element.id} onClick={() => setDelivery(element.id)}>
        <input className="address__delivery" type="radio" name="address__delivery" value={element.id} defaultChecked={element.id !== delivery ? false : "checked"}/>
        <div className="address__element">
          <p className="bold">{element.name + " - " + element.phoneNumber}</p>
          {element.address + ", " + element.city + ", " + element.county + ", " + element.country}
          <br/>
          {element.postalCode}
        </div>
      </div>
    )
  })

  // Prepares the billing addresses for rendering
  const data2 = [];
  data2.push(
    <div className={`radio__element ${0 === billing ? 'radio--active' : ''}`} key="1" onClick={() => setBilling(0)}>
      <input type="radio" name="address__billing" value={0} defaultChecked={0 !== billing ? false : "checked"}/>
      <div className="address__element">Same as Delivery Address</div>
    </div>
  )
  addresses.forEach(element => {
    data2.push(
      <div className={`radio__element ${element.id === billing ? 'radio--active' : ''}`} key={"element " + element.id} onClick={() => setBilling(element.id)}>
        <input type="radio" name="address__billing" value={element.id} defaultChecked={element.id !== billing ? false : "checked"}/>
        <div className="address__element">
          <p className="bold">{element.name + " - " + element.phoneNumber}</p>
          {element.address + ", " + element.city + ", " + element.county + ", " + element.country}
          <br/>
          {element.postalCode}
        </div>
      </div>
    )
  })

  // Closes the popup when you click off of it
  function closeOnClickOutside(event) {
    if(event.target.classList.contains("add__address__parent")) {
      event.target.classList.remove("show");
    }
  }

  // Function that saves a new address
  function saveNewAddress(event) {
    event.preventDefault();
    let adrs = {
      userId: jwt_decode(localStorage.getItem('accessToken')).sub,
      name: name,
      phoneNumber: phoneNumber,
      address: address,
      city: city,
      county: county,
      country: country,
      postalCode: postalCode
    }
    ADDRESS_API.save(adrs, result => {
      window.location.reload();
    }, props.refreshToken)
  }

  return(
    <div className="delivery">
      <div className="delivery__container max-width flex flex-jc-c">
        
        <div className="delivery__left">
          <div className="delivery__left__container">
            <h1>Delivery Details</h1>
            <div className="delivery__address">
              <div className="flex flex-jc-sb"><h1>Delivery Address</h1><button className="cta__button" onClick={() => document.getElementsByClassName("add__address__parent")[0].classList.add("show")}>Add Address</button></div>
              <DeliveryAddressRenderer refreshToken = { props.refreshToken }/>
            </div>
            <div className="delivery__address">
              <div className="flex flex-jc-sb"><h1>Billing Address</h1><button className="cta__button" onClick={() => document.getElementsByClassName("add__address__parent")[0].classList.add("show")}>Add Address</button></div>
              <BillingAddressRenderer refreshToken = { props.refreshToken }/>
            </div>
            <div className="delivery__options">
              <h1>Delivery Options</h1>
              <DeliveryOptionsRenderer refreshToken = { props.refreshToken }/>
            </div>
            <div className="delivery__options">
              <h1>Payment Options</h1>
              <PaymentRenderer refreshToken = { props.refreshToken }/>
            </div>
            <div className="flex flex-jc-c flex-ai-c mg-b-10 hide-for-mobile"><button className="cta__button" onClick={() => placeOrderFunction()}>Place Order</button></div>
          </div>
        </div> 
        <div className="delivery__right">
          <div className="delivery__basket">
            <CartHoverRenderer refreshToken = { props.refreshToken }/>
            <div className="flex flex-jc-c flex-ai-c mg-t-10 hide-for-tablet"><button className="cta__button" onClick={() => placeOrderFunction()}>Place Order</button></div>
          </div>
        </div>
      </div>
      <div className="add__address__parent hide" onClick={(event) => closeOnClickOutside(event)}>
        <form className="add__address flex flex-fd-c flex-ai-c flex-jc-c">
          
          <h1>Add an Address</h1>
          
          <input type="text" name="Name" placeholder="Name" onChange={(event) => setName(event.target.value)}/>
          <input type="text" name="Phone" placeholder="Phone Number" onChange={(event) => setPhoneNumber(event.target.value)}/>
          <input type="text" name="Address" placeholder="Address" onChange={(event) => setAddress(event.target.value)}/>
          <input type="text" name="City" placeholder="City" onChange={(event) => setCity(event.target.value)}/>
          <input type="text" name="County" placeholder="County" onChange={(event) => setCounty(event.target.value)}/>
          <input type="text" name="Country" placeholder="Country" onChange={(event) => setCountry(event.target.value)}/>
          <input type="text" name="Postal Code" placeholder="Postal Code" onChange={(event) => setPostalCode(event.target.value)}/>

          <button className="cta__button" onClick={(event) => saveNewAddress(event)}>Add Address</button>

          <button className="add__address__button" onClick={() => document.getElementsByClassName("add__address__parent")[0].classList.remove("show")}>
            <i className="fas fa-times"></i>
          </button>
        </form>
      </div>
    </div>
  )
}

export default Delivery;
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import jwt_decode from 'jwt-decode'

import * as ORDERS_API from '../api/orders-api'

function Order(props) {

  const [isAuthorized, setIsAuthorized] = useState(false);
  const [order, setOrder] = useState([]);
  const [status, setStatus] = useState(1);

  // On load, fetches the Order that matches the ID
  useEffect(() => {
    if(props.match.params.orderId > 0 && props.refreshToken != undefined) {
      ORDERS_API.getById(props.match.params.orderId, result => {
        if(result.user !== undefined) {
          if(localStorage.getItem('accessToken') != undefined && localStorage.getItem('accessToken') != null && result.user.id == jwt_decode(localStorage.getItem('accessToken')).sub) {
            if(jwt_decode(localStorage.getItem('accessToken')).exp * 1000 >= Date.now()) {
              setIsAuthorized(true);
              setOrder(result);
              setStatus(result.status.id)
            }
          }
        }
      }, props.refreshToken)
    }
  }, [])

  // Prepares the order details for rendering
  const abc = [];
  if(order.entries.length > 0) {
    order.entries.sort((a, b) => a.id - b.id)
    order.entries.forEach(entry => {
      abc.push(
        <div key={entry.id} className="flex flex-ai-c flex-jc-sb order-book">
          <a href={`/book/${entry.book.isbn13}`}><img src={`https://beyondbooks.sergiudeaj.ro/assets/images/books/${entry.book.isbn13}.jpg`} alt={entry.book.title} height="125"/></a>
          <div className="flex flex-ai-c flex-jc-c flex-fd-c">
            <a href={`/book/${entry.book.isbn13}`}><div className="order-book--title">{entry.book.name}</div></a>
            <a href={`/book/${entry.book.isbn13}`}><div className="order-book--author">{entry.book.author.name}</div></a>
          </div>
          <div className="flex flex-ai-c flex-jc-c flex-fd-c order-book--details">
            <div>{entry.price} €</div>
            <div>x{entry.amount}</div>
          </div>
        </div>
      )
    })
  }

  // Calculates the total price
  function calculateTotal() {
    var total = 0;
    if(order.entries.length > 0) {
      if(order.entries !== undefined) {
        order.entries.forEach(entry => {
          total += (entry.amount * entry.price)
        })
      }
    }
    return Math.round((total + Number.EPSILON) * 100)/100;
  }

  // Calculates the cost of delivery based on the total price
  function calculateDelivery() {
    var delivery = "FREE";
    if(order.entries.length > 0) {
      if(calculateTotal() < 50.0) {
        if(order.deliveryType.id === 1) {
          delivery = "9.95 €";
        } else if(order.deliveryType.id === 2) {
          delivery = "12.95 €";
        }
      }
    }    
    return delivery;
  }

  if(isAuthorized) {
    return(
      <main>
        <div className="max-width flex">
          <div className="order-element flex flex-fd-c">
            <div className="order-delivery-details">
              <div className="order-delivery-details__images flex">
                <div className={`order-delivery-details__image ${status >= 1 ? 'order-delivery-details--active-image' : ''}`}><i className="fas fa-file-invoice-dollar"></i></div>
                <div className={`order-delivery-details__image ${status >= 2 ? 'order-delivery-details--active-image' : ''}`}><i className="fas fa-truck-loading"></i></div>
                <div className={`order-delivery-details__image ${status >= 3 ? 'order-delivery-details--active-image' : ''}`}><i className="fas fa-warehouse"></i></div> 
                <div className={`order-delivery-details__image ${status >= 4 ? 'order-delivery-details--active-image' : ''}`}><i className="fas fa-truck"></i></div>
                <div className={`order-delivery-details__image ${status >= 5 ? 'order-delivery-details--active-image' : ''}`}><i className="fas fa-calendar-check"></i></div>
              </div>
              <div className="order-delivery-details__main flex">
                <div className={`order-delivery-details__sub ${status >= 2 ? 'order-delivery-details--active' : ''}`}></div>
                <div className={`order-delivery-details__sub ${status >= 3 ? 'order-delivery-details--active' : ''}`}></div>
                <div className={`order-delivery-details__sub ${status >= 4 ? 'order-delivery-details--active' : ''}`}></div> 
                <div className={`order-delivery-details__sub ${status >= 5 ? 'order-delivery-details--active' : ''}`}></div>
              </div>
              <div className="order-delivery-details__texts flex">
                <div className={`order-delivery-details__text ${status >= 1 ? 'order-delivery-details--active-text' : ''}`}>Order placed</div>
                <div className={`order-delivery-details__text ${status >= 2 ? 'order-delivery-details--active-text' : ''}`}>Products handed to delivery</div>
                <div className={`order-delivery-details__text ${status >= 3 ? 'order-delivery-details--active-text' : ''}`}>Products at delivery warehouse</div> 
                <div className={`order-delivery-details__text ${status >= 4 ? 'order-delivery-details--active-text' : ''}`}>Products delivering</div>
                <div className={`order-delivery-details__text ${status >= 5 ? 'order-delivery-details--active-text' : ''}`}>Products delivered</div>
              </div>
            </div>
            <div className="flex order__wrapper">
              <div className="order-details">
                <div className="order-details__block">
                  <h1>Delivery Details</h1>
                  <div className="order-details__block--to">
                    {order.deliveryAddress !== undefined ? `${order.deliveryAddress.name}, ${order.deliveryAddress.phoneNumber}` : "None"}
                  </div>
                  <div>
                    {order.deliveryAddress !== undefined ? `${order.deliveryAddress.country}, ${order.deliveryAddress.county}, ${order.deliveryAddress.city}` : "None"}
                  </div>
                  <div>
                    {order.deliveryAddress !== undefined ? `${order.deliveryAddress.address}, ${order.deliveryAddress.postalCode}` : "None"}
                  </div>
                </div>
                <div className="order-details__block">
                  <h1>Billing Details</h1>
                  <div className="order-details__block--to">
                    {order.billingAddress !== undefined ? `${order.billingAddress.name}, ${order.billingAddress.phoneNumber}` : "None"}
                  </div>
                  <div>
                    {order.billingAddress !== undefined ? `${order.billingAddress.country}, ${order.billingAddress.county}, ${order.billingAddress.city}` : "None"}
                  </div>
                  <div>
                    {order.billingAddress !== undefined ? `${order.billingAddress.address}, ${order.billingAddress.postalCode}` : "None"}
                  </div>
                </div>
                <div className="order-details__block">
                  <h1>Payment Details</h1>
                  <div>{order.paymentType !== undefined ? order.paymentType.id === 1 ? 'Cash on Delivery' : 'Card Online' : 'None'}</div>
                  <div>Total: <span>{order.total} €</span></div>
                </div>
              </div>
              <div className="order-books">
                {abc}
                <div className="order-books__footer">
                  <p>Total Products: <span>{calculateTotal()} €</span></p>
                  <p>Delivery: <span>{calculateDelivery()}</span></p>
                  <p>Total: <span>{order.total} €</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    )
  } else {
    return(
      <main><div className="max-width flex"><div className="order-bad flex flex-ai-c flex-jc-c">Order not found</div></div></main>
    )
  }
}

export default withRouter(Order);
import React, { useState, useEffect, useRef } from 'react'

import * as API from  '../api/orders-api'

function ModOrders(props) {

  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState([]);
  const [status, setStatus] = useState(-1);

  const SHOW_COUNT = 10;
  var elements = [];

  const wrapperRef = useRef(null);
  
  function nextPage() { setPage(page + 1); }
  function previousPage() { setPage(page - 1); }
  function resetPage() { setPage(1); }

  useEffect(() => {
    elements = [];
    renderFilteredResults();
  }, [page])

  useEffect(() => {
    resetPage();
  }, [filter])

  function setFilterFunction(filterData) { setFilter(filterData); }

  // Loads the data and shows the popup when the moderator clicks on an order
  function onClickOrder(value) {
    document.getElementById("order-popup-fader").classList.add("show")
    setStatus(value.status.id);
    setOrder(value);
  }

  // Closes the popup when the moderator clicks off of the order
  function onClickOutside(event) {
    const fader = document.getElementById("order-popup-fader");
    if(fader === event.target) {
      fader.classList.remove("show")
    }
  }

  function changeStatus(value) {
    setStatus(value);
  }

  // Function that updates the status of an order
  function updateStatus(event, id) {
    if(status !== -1) {
      event.preventDefault();
      return API.updateStatus(id, status, result => {
        window.location.reload();
      }, props.refreshToken);
    }
  }

  // Function that renders the date
  function renderDate(date) {
    var options = { year: 'numeric', month: 'short', day: 'numeric' };
    var timeThen = parseInt(String(date * 1000).replace('.', ""));
    var dateThen = new Date(timeThen);
    return dateThen.toLocaleDateString("en-GB", options);
  }

  // Function that calculates the total
  function calculateTotal() {
    var total = 0;
    if(order.entries !== undefined) {
      if(order.entries.length > 0) {
        order.entries.forEach(entry => {
          total += (entry.amount * entry.price)
        })
      }
    }
    return Math.round((total + Number.EPSILON) * 100)/100;
  }

  // Function that calculates the cost of delivery based on the total
  function calculateDelivery() {
    var delivery = "FREE";
      if(order.entries !== undefined && order.entries.length > 0) {
        if(calculateTotal() < 50.0) {
          if(order.deliveryType.id === 1) {
            delivery = "9.95 €";
          } else if(order.deliveryType.id === 2) {
            delivery = "12.95 €";
          }
        }
    } 
    return delivery;
  }

  // Prepares the books for rendering
  const abc = [];
  if(order.entries !== undefined) {
    if(order.entries.length > 0) {
      order.entries.sort((a, b) => a.id - b.id)
      order.entries.forEach(entry => {
        abc.push(
          <div key={entry.id} className="flex flex-ai-c flex-jc-sb order-book">
            <a href={`/book/${entry.book.isbn13}`}><img src={`https://beyondbooks.sergiudeaj.ro/assets/images/books/${entry.book.isbn13}.jpg`} alt={entry.book.title} height="125"/></a>
            <div className="flex flex-ai-c flex-jc-c flex-fd-c">
              <a href={`/book/${entry.book.isbn13}`}><div className="order-book--title">{entry.book.name}</div></a>
              <a href={`/book/${entry.book.isbn13}`}><div className="order-book--author">{entry.book.author.name}</div></a>
            </div>
            <div className="flex flex-ai-c flex-jc-c flex-fd-c order-book--details">
              <div>{entry.price} €</div>
              <div>x{entry.amount}</div>
            </div>
          </div>
        )
      })
    }
  }

  // Fetches all the orders
  function findAll() {
    document.getElementById("orders-view-search").focus();
    return API.getAll(result => {
      const orders = [];
      result.forEach(element => {
        orders.push(element);
      });
      setData(orders);
    }, props.refreshToken);
  }

  // Filters the results
  function filterResults() {
    const filteredResults = [];
    if(data != null) {
      data.forEach(element => {
        if(String(element.id).match(filter) || String(element.user.id).match(filter) || String(element.deliveryAddress.phoneNumber).match(filter) || String(element.user.name).toLowerCase().match(filter.toLowerCase()) || String(element.user.email).toLowerCase().match(filter.toLowerCase())) {
          filteredResults.push({
            id: element.id,
            user: element.user,
            deliveryAddress: element.deliveryAddress,
            billingAddress: element.billingAddress,
            paymentType: element.paymentType,
            deliveryType: element.deliveryType,
            status: element.status,
            entries: element.entries,
            date: element.date,
            price: element.total,
            total: element.total
          })
        }
      })
    }
    return filteredResults;
  }

  useEffect(findAll, []);

  // Renders the filtered results
  function renderFilteredResults() {
    const filteredResults = filterResults();
    sessionStorage.setItem('shownDataSize', filteredResults.length);
    if(filteredResults !== null) {
      for(let i = SHOW_COUNT * (page - 1); i < (SHOW_COUNT * page); i++) {
        if(i <= filteredResults.length && filteredResults[i] !== undefined) {
          elements.push(
            <div className="form-block__element--orders" key={filteredResults[i].id} onClick={() => onClickOrder(filteredResults[i])}>
              <div>
                {filteredResults[i].id}
              </div>
              <div>
                {filteredResults[i].user.id}
              </div>
              <div>
                {filteredResults[i].user.name}
              </div>
              <div>
                {filteredResults[i].user.email}
              </div>
              <div>
                {filteredResults[i].deliveryAddress.phoneNumber}
              </div>
              <div>
                {renderDate(filteredResults[i].date)}
              </div>
              <div>
                {filteredResults[i].deliveryType.value}
              </div>
              <div>
                {filteredResults[i].status.value}
              </div>
              <div>
                {filteredResults[i].paymentType.value}
              </div>
              <div>
                {filteredResults[i].price}
              </div>
            </div>
          );
        }
      }
    }
  }

  renderFilteredResults();

  return(
    <div className="max-width--orders pad-10">
      <div className="hide" id="order-popup-fader" onClick={(event) => onClickOutside(event)}>
        <div id="order-popup" ref={wrapperRef}>
          <div className="flex order__wrapper">
              <div className="order-details">
                <div className="order-details__block">
                  <h1>Order Details</h1>
                  <div className="order-details__block--to">
                    <div>ID: {order.id}</div>
                    <div>Status: {order.status !== undefined ? order.status.value : ''}</div>
                    <div>Date: {renderDate(order.date)}</div>
                  </div>
                </div>
                <div className="order-details__block">
                  <h1>Update Status</h1>
                  <form className="order-details__block--to">
                    <div><input type="radio" name="stat" id="stat-1" value={1} checked={1 === status ? "checked" : false} onChange={() => changeStatus(1)}/><label htmlFor="stat-1">RECEIVED</label></div>
                    <div><input type="radio" name="stat" id="stat-2" value={2} checked={2 === status ? "checked" : false} onChange={() => changeStatus(2)}/><label htmlFor="stat-2">HANDED TO DELIVERY</label></div>
                    <div><input type="radio" name="stat" id="stat-3" value={3} checked={3 === status ? "checked" : false} onChange={() => changeStatus(3)}/><label htmlFor="stat-3">AT WAREHOUSE</label></div>
                    <div><input type="radio" name="stat" id="stat-4" value={4} checked={4 === status ? "checked" : false} onChange={() => changeStatus(4)}/><label htmlFor="stat-4">DELIVERING</label></div>
                    <div className="order-details__block--to"><input type="radio" name="stat" id="stat-5" value={5} checked={5 === status ? "checked" : false} onChange={() => changeStatus(5)}/><label htmlFor="stat-5">DELIVERED</label></div>
                    <button className="btn--cta" onClick={(event) => updateStatus(event, order.id)}>Update Status</button>
                  </form>
                </div>
                <div className="order-details__block">
                  <h1>Delivery Details</h1>
                  <div className="order-details__block--to">
                    {order.deliveryAddress !== undefined ? `${order.deliveryAddress.name}, ${order.deliveryAddress.phoneNumber}` : "None"}
                  </div>
                  <div>
                    {order.deliveryAddress !== undefined ? `${order.deliveryAddress.country}, ${order.deliveryAddress.county}, ${order.deliveryAddress.city}` : "None"}
                  </div>
                  <div>
                    {order.deliveryAddress !== undefined ? `${order.deliveryAddress.address}, ${order.deliveryAddress.postalCode}` : "None"}
                  </div>
                </div>
                <div className="order-details__block">
                  <h1>Billing Details</h1>
                  <div className="order-details__block--to">
                    {order.billingAddress !== undefined ? `${order.billingAddress.name}, ${order.billingAddress.phoneNumber}` : "None"}
                  </div>
                  <div>
                    {order.billingAddress !== undefined ? `${order.billingAddress.country}, ${order.billingAddress.county}, ${order.billingAddress.city}` : "None"}
                  </div>
                  <div>
                    {order.billingAddress !== undefined ? `${order.billingAddress.address}, ${order.billingAddress.postalCode}` : "None"}
                  </div>
                </div>
                <div className="order-details__block">
                  <h1>Payment Details</h1>
                  <div>{order.paymentType !== undefined ? order.paymentType.id === 1 ? 'Cash on Delivery' : 'Card Online' : 'None'}</div>
                  <div>Total: <span>{order.total} €</span></div>
                </div>
              </div>
              <div className="order-books">
                {abc}
                <div className="order-books__footer">
                  <p>Total Products: <span>{calculateTotal()} €</span></p>
                  <p>Delivery: <span>{calculateDelivery()}</span></p>
                  <p>Total: <span>{order.total} €</span></p>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div className="mod-orders__container">
        <div className="form-block__search-input" onChange={event => setFilterFunction(event.target.value)}>
          <input type="text" placeholder="Type to filter the results" id="orders-view-search"></input>
        </div>
        <div className="scroll-x">
          <div className="no-overflow">
            <div className="form-block__header--orders">
              <div className="form-block__header__element">ID</div>
              <div className="form-block__header__element">User ID</div>
              <div className="form-block__header__element">Name</div>
              <div className="form-block__header__element">Email</div>
              <div className="form-block__header__element">Phone</div>
              <div className="form-block__header__element">Date</div>
              <div className="form-block__header__element">Delivery</div>
              <div className="form-block__header__element">Status</div>
              <div className="form-block__header__element">Payment</div>
              <div className="form-block__header__element">Price</div>
            </div>
            <div className="form-block__body">
              {elements}
            </div>
          </div>
        </div>
        <div className="form-block__buttons">
          <button className="form-block__button" style={{display: `${page === 1 ? 'none' : 'block'}`}} onClick={() => previousPage()}><i className="fas fa-angle-left"></i></button>
          <button className="form-block__button" style={{display: `${page * SHOW_COUNT > sessionStorage.getItem('shownDataSize') ? 'none' : 'block'}`}} onClick={() => nextPage()}><i className="fas fa-angle-right"></i></button>
        </div>
      </div>
    </div>
  )
}

export default ModOrders;
import React, { useState, useEffect, useRef } from 'react'

import Book from './Book'

// Component that renders a list of books with the posibility of scrolling through the list
function Carousel(props) {

  const [booksToRender, setBooksToRender] = useState([]);
  const childRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [width, setWidth] = useState(0);
  const [oldWidth, setOldWidth] = useState(0);
  const [leftMargin, setLeftMargin] = useState(false);
  const [bookWidth, setBookWidth] = useState(0);

  //updates the width of the page when the page has been resized
  function handleResize() {
    setWidth(childRef.current.offsetWidth);
  }

  //updates the width of the page when the page has been resized, updates the active index and detects if the left margin has been reached due to the resizing
  useEffect(() => {
    setWidth(childRef.current.offsetWidth);
    window.addEventListener('resize', handleResize)

    if(leftMargin) {
      if(oldWidth < width) {
        setActiveIndex(booksToRender.length*bookWidth - width);
      } else {
        setLeftMargin(false);
      }
    }

    if(activeIndex + width > booksToRender.length*bookWidth && activeIndex !== 0) {
      if(oldWidth < width) {
        setActiveIndex(booksToRender.length*bookWidth - width);
        setLeftMargin(true);
      } else {
        setLeftMargin(false);
      }
    }

    setOldWidth(width);

    return _ => {
      window.removeEventListener('resize', handleResize)
    }

  }, [width, activeIndex, booksToRender, leftMargin, oldWidth])

  // When the Next Button of the Carousel is pressed, updates the active index (current position in the list) and detects if the left margin has been hit
  function onNextClick(event){
    event.preventDefault()
    setWidth(childRef.current.offsetWidth);
    const viewPort = Math.floor(width/bookWidth);
    if(activeIndex + viewPort*bookWidth >= booksToRender.length*bookWidth - viewPort*bookWidth) {
      setActiveIndex(booksToRender.length*bookWidth - width);
      setLeftMargin(true);
    } else {
      setActiveIndex(activeIndex + viewPort*bookWidth);
    }
  }

  // When the Previous Button of the Carousel is pressed, updates the active index (current position in the list) and detects if the left margin has been hit
  function onPreviousClick(event) {
    event.preventDefault()
    setWidth(childRef.current.offsetWidth);
    const viewPort = Math.floor(width/bookWidth);
    setActiveIndex(activeIndex - viewPort*bookWidth <= 0 ? 0 : activeIndex - viewPort*bookWidth);
    if(leftMargin) { 
      setLeftMargin(false);
    }
  }

  // Calls the fetcher that's been passed to it, sets the books that need to be rendered and calculates and sets the width of a book (including its' margins)
  useEffect(() => {
    props.fetcher(props.value, result => {
      if(result !== undefined && result !== null && result.length > 0) {
        const books = [];
        result.forEach(book => books.push(<Book book={book} key={book.id}/>));
        setBooksToRender(books);

        setTimeout(() => {
          let bookItem = document.getElementsByClassName("book-card")[1];
          let computedWidth = parseInt(window.getComputedStyle(bookItem).getPropertyValue("margin-left").slice(0, -2)) + parseInt(window.getComputedStyle(bookItem).getPropertyValue("margin-right").slice(0, -2)) + parseInt(window.getComputedStyle(bookItem).getPropertyValue("width").slice(0, -2))
          setBookWidth(computedWidth)
        }, 100)
      }
    }, props.refreshToken)
  }, [])

  // dynamic transformation based on the active index
  const sliderStyle = {
    transform:`translateX(${-(activeIndex)}px)`,
    transition: `1s`,
  }

  return(
    <div className="carousel">
      <div className="carousel__viewport" ref={childRef}>
        <div className="carousel__tab" style={sliderStyle}>
          <div className="flex card-container">
            {booksToRender}
          </div>
        </div>
      </div>
    
      <a href="#/" className={`carousel__button${activeIndex <= '0' ? '--inactive' : '--previous'}`} onClick={(event) => onPreviousClick(event)}> </a>

      <a href="#/" className={`carousel__button${activeIndex >= bookWidth * booksToRender.length - width ? '--inactive' : '--next'}`} onClick={(event) => onNextClick(event)}> </a>
    </div>
  )
}

export default Carousel;
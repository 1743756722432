import React, { useState, useEffect } from 'react'

import * as API_BOOKS from '../api/books-api'
import * as API_AUTHOR from '../api/author-api'
import * as API_PUBLISHER from '../api/publisher-api'
import * as API_FORMAT from '../api/format-api'
import * as API_GENRE from '../api/genre-api'
import * as API_LANGUAGE from '../api/language-api'
import * as API_SERIES from '../api/series-api'

import FormSelect from '../components/FormSelect'
import FormInput from '../components/FormInput'

function BookUpdate(props) {

  const [bookIsbn13, setBookIsbn13] = useState("");
  const [bookName, setBookName] = useState("");
  const [bookUrl, setBookUrl] = useState("");
  const [authors, setAuthors] = useState([]);
  const [publishers, setPublishers] = useState([]);
  const [formats, setFormats] = useState([]);
  const [genres, setGenres] = useState([]);
  const [series, setSeries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [bookPages, setBookPages] = useState("");
  const [bookQuantity, setBookQuantity] = useState("");
  const [bookWidth, setBookWidth] = useState("");
  const [bookHeight, setBookHeight] = useState("");
  const [bookAuthor, setBookAuthor] = useState("");
  const [bookSeries, setBookSeries] = useState("");
  const [bookDiscount, setBookDiscount] = useState("");
  const [bookPublisher, setBookPublisher] = useState("");
  const [bookLanguage, setBookLanguage] = useState("");
  const [bookFormat, setBookFormat] = useState("");
  const [bookDescription, setBookDescription] = useState("");
  const [bookPrice, setBookPrice] = useState("");
  const [bookDay, setBookDay] = useState("");
  const [bookMonth, setBookMonth] = useState("");
  const [bookYear, setBookYear] = useState("");

  const [listOfGenres, setListOfGenres] = useState([]);

  // Function that adds a new genre to the list of existing genres
  function addToListOfGenres(newValue) {
    var oldList = listOfGenres;
    if(oldList === null) {
      oldList = [newValue]
    } else {
      oldList.push(newValue)
    }
    setListOfGenres(oldList);
  }

  // Function that removes a genre from the list of existing genres
  function removeFromListOfGenres(newValue) {
    var oldList = listOfGenres;
    var newList = []
    oldList.forEach(element => {
      if(!element === newValue) {
        if(newList === null) {
          newList = [element]
        } else {
          newList.push(element);
        }
      }
    })
    setListOfGenres(newList);
  }

  function setAuthorFun(newAuthor) {
    setBookAuthor(newAuthor);
  }
  function setPublisherFun(newPublisher) {
    setBookPublisher(newPublisher);
  }
  function setSeriesFun(newSeries) {
    setBookSeries(newSeries);
  }
  function setLanguageFun(newLanguage) {
    setBookLanguage(newLanguage);
  }
  function setFormatFun(newFormat) {
    setBookFormat(newFormat);
  }
  function setDayFun(newDay) {
    setBookDay(newDay);
  }
  function setMonthFun(newMonth) {
    setBookMonth(newMonth);
  }
  function setYearFun(newYear) {
    setBookYear(newYear);
  }

  // Function that returns the list of genres
  function getOldGenresList(genres) {
    var genresList = [];
    genres.forEach(genre => {
      genresList.push(String(genre.id));
    })
    return genresList;
  }

  // Function that updates a book
  function updateBook(event) {
    event.preventDefault()
    API_BOOKS.getByISBN13(bookIsbn13.trim(), originalBook => {
      event.preventDefault()

      var genresToSend = [];
      if(listOfGenres !== "") {
        genresToSend = listOfGenres;
      } else {
        genresToSend = getOldGenresList(originalBook.genres)
      }

      let book = {
        isbn13: originalBook.isbn13,
        name: `${bookName !== "" ? bookName : originalBook.name}`,
        pages: `${bookPages !== "" ? bookPages : originalBook.pages}`,
        width: `${bookWidth !== "" ? bookWidth : originalBook.width}`,
        height: `${bookHeight !== "" ? bookHeight : originalBook.height}`,
        author: `${bookAuthor !== "" ? bookAuthor : originalBook.author.id}`,
        series: `${bookSeries !== "" ? bookSeries : originalBook.series.id}`,
        quantity: `${bookQuantity !== "" ? bookQuantity : originalBook.quantity}`,
        publisher: `${bookPublisher !== "" ? bookPublisher : originalBook.publisher.id}`,
        language: `${bookLanguage !== "" ? bookLanguage : originalBook.language.id}`,
        format: `${bookFormat !== "" ?  bookFormat : originalBook.format.id}`,
        genres: genresToSend,
        price: `${bookPrice !== "" ? bookPrice : originalBook.price}`,
        sold: originalBook.sold,
        discount: `${bookDiscount !== "" ? bookDiscount : originalBook.discount}`,
        description: `${bookDescription !== "" ? bookDescription : originalBook.description}`,
        releaseDate: `${bookYear !== "" && bookMonth !== "" && bookDay !== "" ? new Date(bookYear, bookMonth-1, bookDay).getTime() : originalBook.releaseDate}`
      }

      API_BOOKS.update(book, res => {
        window.location.reload();
      }, props.refreshToken);
    })
  }

  // Function that validates a date
  function isValidDate(dayTestValue) {
    var testBool = false;
    var d = new Date(bookYear, bookMonth-1, dayTestValue);
    if(d.getFullYear() === bookYear && d.getMonth() === bookMonth-1 && d.getDate() === dayTestValue) {
      testBool = true;
    }
    console.log(new Date(bookYear, bookMonth, bookDay).getTime());
    return testBool;
  }

  // Function that fetches all authors
  function fetchAuthors()  {
    return API_AUTHOR.getAll(result => {
      if(result !== null) {
        const array = [];
        result.forEach(author => {
          array.push({id: author.id, name: author.name});
        });
        setAuthors(array);
      }
    })
  }
  useEffect(fetchAuthors, []);

  // Function that fetches all publishers
  function fetchPublishers()  {
    return API_PUBLISHER.getAll(result => {
      if(result !== null) {
        const array = [];
        result.forEach(publisher => {
          array.push({id: publisher.id, name: publisher.name});
        });
        setPublishers(array);
      }
    })
  }
  useEffect(fetchPublishers, []);

  // Function that fetches all series
  function fetchSeries()  {
    return API_SERIES.getAll(result => {
      if(result !== null) {
        const array = [];
        result.forEach(series => {
          array.push({id: series.id, name: series.name});
        });
        setSeries(array);
      }
    })
  }
  useEffect(fetchSeries, []);

  // Function that fetches all genres
  function fetchGenres()  {
    return API_GENRE.getAll(result => {
      if(result !== null) {
        const array = [];
        result.forEach(genre => {
          array.push({id: genre.id, name: genre.name});
        });
        setGenres(array);
      }
    })
  }
  useEffect(fetchGenres, []);

  // Function that fetches all formats
  function fetchFormats()  {
    return API_FORMAT.getAll(result => {
      if(result !== null) {
        const array = [];
        result.forEach(format => {
          array.push({id: format.id, name: format.name});
        });
        setFormats(array);
      }
    })
  }
  useEffect(fetchFormats, []);

  // Function that fetches all languages
  function fetchLanguages()  {
    return API_LANGUAGE.getAll(result => {
      if(result !== null) {
        const array = [];
        result.forEach(language => {
          array.push({id: language.id, name: language.name});
        });
        setLanguages(array);
      }
    })
  }
  useEffect(fetchLanguages, []);

  var dayValues = ["1", "2", "3", "4", "5", "6", "7", "8", "9" , "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"];
  var monthValues = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
  var yearValues = [];

  var d = new Date();
  var n = d.getFullYear();

  for(let i = 1970; i < n+2; i++) {
    yearValues.push(i);
  }

  const days = [];
  if(bookYear && bookMonth) {
    dayValues.forEach(element => {
      if(isValidDate(element)) {
        days.push(<option key={"element " + element} value={element}>{element}</option>)
      }
    })
  }

  const months = [];
  monthValues.forEach(element => {
    months.push(<option key={"element " + element} value={element}>{element}</option>)
  })

  const years = [];
  yearValues.forEach(element => {
    years.push(<option key={"element " + element} value={element}>{element}</option>)
  })
  years.reverse();

  const renderedGenres = [];
  genres.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
  genres.forEach(genre => {
    renderedGenres.push(
    <div key={genre.id}>
      <input type="checkbox" id={genre.id} name={genre.name} value={genre.id} onChange={event => {event.target.checked ? addToListOfGenres(event.target.value) : removeFromListOfGenres(event.target.value)}}/>
      <label>{genre.name}</label>
    </div>);
  })

  return(
    <div className="form-block__container">
      <form className="form-block--books form-block">

      <FormInput id="isbn13" label="ISBN13" placeholder="9780575089938" onChange={setBookIsbn13}/>
        <FormInput id="name" label="NAME" placeholder="The Well of Ascension" onChange={setBookName}/>
        <FormSelect id="author" label="AUTHOR" dataSelect={setAuthorFun} data={authors}/>
        <FormSelect id="publisher" label="PUBLISHER" dataSelect={setPublisherFun} data={publishers}/>
        <FormSelect id="series" label="SERIES" dataSelect={setSeriesFun} data={series}/>

        <div id="language-format" className="form-block__wrapper--two form-block__wrapper">
          <FormSelect label="LANGUAGE" dataSelect={setLanguageFun} data={languages}/>
          <FormSelect label="FORMAT" dataSelect={setFormatFun} data={formats}/>
        </div>

        <div id="price-discount-quantity" className="form-block__wrapper--three form-block__wrapper">
          <FormInput label="PRICE" placeholder="12.14" onChange={setBookPrice}/>
          <FormInput label="DISCOUNT" placeholder="12.14" onChange={setBookDiscount}/>
          <FormInput label="QUANTITY" placeholder="50" onChange={setBookQuantity}/>
        </div>

        <div id="pages-width-height" className="form-block__wrapper--three form-block__wrapper">
          <FormInput label="PAGES" placeholder="100" onChange={setBookPages}/>
          <FormInput label="WIDTH" placeholder="100" onChange={setBookWidth}/>
          <FormInput label="HEIGHT" placeholder="100" onChange={setBookHeight}/>
        </div>

        <div id="year-month-day" className="form-block__wrapper--three form-block__wrapper">
        <div className="form-block__element">
            <label className="form-block__label">YEAR</label>
            <select onChange={e => setYearFun(e.target.value)} className="form-block__select" defaultValue={'DEFAULT'}>
              <option value="DEFAULT" disabled>Choose One</option>
              {years}
            </select>
          </div>
          
          <div className="form-block__element">
            <label className="form-block__label">MONTH</label>
            <select onChange={e => setMonthFun(e.target.value)} className="form-block__select" defaultValue={'DEFAULT'}>
              <option value="DEFAULT" disabled>Choose One</option>
              {months}
            </select>
          </div>
          
          <div className="form-block__element">
            <label className="form-block__label">DAY</label>
            <select style={{userSelect: `${bookYear && bookMonth ? 'text' : 'none'}`}} onChange={e => setDayFun(e.target.value)} className="form-block__select" defaultValue={'DEFAULT'}>
              <option value="DEFAULT" disabled>Choose One</option>
              {days}
            </select>
          </div>
        </div>

        <FormInput id="image" label="IMAGE URL" placeholder="https://imgur.com" onChange={setBookUrl}/>


        <div id="genres" className="form-block__wrapper--genres form-block__wrapper">
          <label className="form-block__label">GENRES</label>
          <div className="genres__wrapper">
            {renderedGenres}
          </div>
        </div>

        <div id="description" className="form-block__wrapper--description">
          <label className="form-block__label">DESCRIPTION </label>
          <textarea type="text" className="form-block__textarea" placeholder="Vin was a ..." onChange={e => {setBookDescription(e.target.value)}}/>
        </div>

        <button id="button" className="form-block__button" onClick={event => {updateBook(event)}}>Update</button>
      </form>
    </div>
  )
}

export default BookUpdate;
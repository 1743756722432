import RestClient from './rest-client'
import { HOST } from './hosts'
import jwt_decode from 'jwt-decode'

const endpoint = {
  get_addresses_by_user: '/api/address/getByUserId/?id=',
  save_address: '/api/address/add',
  delete_address: '/api/address/delete/?id=',
  update_address: '/api/address/update/?id='
};

// Function that saves a new address
function save(address, callback, refresh) {
  let request = new Request(HOST.backend_api + endpoint.save_address, {
    method: 'POST',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(address)
  });
  if(Date.now() >= jwt_decode(localStorage.getItem('accessToken')).exp * 1000) { 
    RestClient.refreshAccessToken(refresh, result => {
      request.headers.append('Authorization', `Bearer ${result}`)
      RestClient.performRequest(request, callback);
    });
  } else {
    request.headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
    RestClient.performRequest(request, callback);
  }
}

// Function that retrieves an address by its' ID
function getByUserId(id, callback) {
  let request = new Request(HOST.backend_api + endpoint.get_addresses_by_user + id, {
    method: 'GET',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  });
  RestClient.performRequest(request, callback);
}

// Function that deletes an address based on its' ID
function deleteById(id, callback, refresh) {
  let request = new Request(HOST.backend_api + endpoint.delete_address + id, {
    method: 'DELETE',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  });
  if(Date.now() >= jwt_decode(localStorage.getItem('accessToken')).exp * 1000) { 
    RestClient.refreshAccessToken(refresh, result => {
      request.headers.append('Authorization', `Bearer ${result}`)
      RestClient.performRequest(request, callback);
    });
  } else {
    request.headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
    RestClient.performRequest(request, callback);
  }
}

// Function that updates an address based on its' ID
function update(id, address, callback, refresh) {
  let request = new Request(HOST.backend_api + endpoint.update_address + id, {
    method: 'PUT',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(address)
  });
  if(Date.now() >= jwt_decode(localStorage.getItem('accessToken')).exp * 1000) { 
    RestClient.refreshAccessToken(refresh, result => {
      request.headers.append('Authorization', `Bearer ${result}`)
      RestClient.performRequest(request, callback);
    });
  } else {
    request.headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
    RestClient.performRequest(request, callback);
  }
}

export { save, getByUserId, deleteById, update };
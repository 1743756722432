import * as USERS_API from './users-api'

// Function that sends the request to the backend and retrieves the response
function performRequest(request, callback) {
  fetch(request)
    .then((response) => {
      if(response.ok) {
        if(response.headers.get('Content-Type').includes('application/json')) {
          response.json().then(json => callback(json));
        } else {
          response.text().then(text => callback && callback(text));
        }
      } else {  
        callback(response.status);
      }
    })
    .catch((error) => callback(error));
}

// Function that refresh the access token of an user
function refreshAccessToken(refreshToken, callback) {
  USERS_API.refreshToken({refreshToken: `${refreshToken}`}, result => {
    if(result.accessToken !== undefined) {
      localStorage.setItem('accessToken', result.accessToken);
      callback(result.accessToken);
    }
  });
}

export default { performRequest, refreshAccessToken }
import * as BOOK_FETCHER from './BookFetchUtils'

// Fetches books with a certain genre
function fetchByGenreId(genreId, callback, refreshToken) {
  return BOOK_FETCHER.fetchBooksByGenreId(genreId, result => {
    if(result != null) {
      callback(result);
    }
  }, refreshToken)
}

// Fetches books with a certain series
function fetchBySeriesId(seriesId, callback, refreshToken) {
  return BOOK_FETCHER.fetchBooksBySeriesId(seriesId, result => {
    if(result != null) {
      callback(result);
    }
  }, refreshToken)
}

// Fetches books by a certain author
function fetchByAuthorId(authorId, callback, refreshToken) {
  return BOOK_FETCHER.fetchBooksByAuthorId(authorId, result => {
    if(result != null) {
      callback(result);
    }
  }, refreshToken)
}

// Fetches books that match a searched keyword
function fetchByKeyword(keyword, callback, refreshToken) {
  return BOOK_FETCHER.fetchBooksByKeyword(keyword, result => {
    if(result != null) {
      callback(result);
    }
  }, refreshToken)
}

export { fetchByGenreId, fetchBySeriesId, fetchByAuthorId, fetchByKeyword }
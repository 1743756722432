import RestClient from './rest-client'
import { HOST } from './hosts'
import jwt_decode from 'jwt-decode'

const endpoint = {
  login: '/api/auth/login',
  logout: '/api/auth/logout',
  register: '/api/auth/register',
  refreshToken: '/api/auth/refreshToken',
  recovery: '/api/auth/recovery',
  completeRecovery: '/api/auth/recovery/success',
  personalData: '/api/user/getPersonalData/?id=',
  updateName: '/api/user/updateName/?id=',
  updateEmail: '/api/user/updateEmail/?id=',
  updatePassword: '/api/user/updatePassword/?id=',
  get_all: '/api/user/getAll',
  add_mod: '/api/user/addMod/?id=',
  remove_mod: '/api/user/removeMod/?id='
}

// Functino that retrieves all users
function getAll(callback, refresh) {
  let request = new Request(HOST.backend_api + endpoint.get_all, {
    method: 'GET',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  });
  if(Date.now() >= jwt_decode(localStorage.getItem('accessToken')).exp * 1000) { 
    RestClient.refreshAccessToken(refresh, result => {
      request.headers.append('Authorization', `Bearer ${result}`)
      RestClient.performRequest(request, callback);
    });
  } else {
    request.headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
    RestClient.performRequest(request, callback);
  }
}

// Function that logs an user in based on the credentials provided
function loginUser(user, callback) {
  let request = new Request(HOST.backend_api + endpoint.login, {
    method: 'POST',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user)
  });
  RestClient.performRequest(request, callback);
}

// Function that logs an user out
function logoutUser(refreshToken, callback) {
  let request = new Request(HOST.backend_api + endpoint.logout, {
    method: 'POST',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(refreshToken)
  });
  RestClient.performRequest(request, callback);
}

// Function that registers a new account
function registerUser(user, callback) {
  let request = new Request(HOST.backend_api + endpoint.register, {
    method: 'POST',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user)
  });
  RestClient.performRequest(request, callback);
}

// Function that refreshes the access token
function refreshToken(refreshToken, callback) {
  let request = new Request(HOST.backend_api + endpoint.refreshToken, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(refreshToken)
  });
  RestClient.performRequest(request, callback);
}

// Function that begins the account recovery process to help a user change their forgotten password
function initializeRecovery(email, callback) {
  let request = new Request(HOST.backend_api + endpoint.recovery, {
    method: 'POST',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(email)
  });
  RestClient.performRequest(request, callback);
}

// Function that completes the account recovery process to help a user change their forgotten password
function completeRecovery(req, callback) {
  let request = new Request(HOST.backend_api + endpoint.completeRecovery, {
    method: 'POST',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(req)
  });
  RestClient.performRequest(request, callback);
}

// Function that retrieves the name and email of a user identified by their ID
function getPersonalData(id, callback, refresh) {
  let request = new Request(HOST.backend_api + endpoint.personalData + id, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  });
  if(Date.now() >= jwt_decode(localStorage.getItem('accessToken')).exp * 1000) { 
    RestClient.refreshAccessToken(refresh, result => {
      request.headers.append('Authorization', `Bearer ${result}`)
      RestClient.performRequest(request, callback);
    });
  } else {
    request.headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
    RestClient.performRequest(request, callback);
  }
}

// Function that updates the name of user identified by their ID
function updateName(id, data, callback, refresh) {
  let request = new Request(HOST.backend_api + endpoint.updateName + id, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  });
  if(Date.now() >= jwt_decode(localStorage.getItem('accessToken')).exp * 1000) { 
    RestClient.refreshAccessToken(refresh, result => {
      request.headers.append('Authorization', `Bearer ${result}`)
      RestClient.performRequest(request, callback);
    });
  } else {
    request.headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
    RestClient.performRequest(request, callback);
  }
}

// Function that updates the email of user identified by their ID
function updateEmail(id, data, callback, refresh) {
  let request = new Request(HOST.backend_api + endpoint.updateEmail + id, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  });
  if(Date.now() >= jwt_decode(localStorage.getItem('accessToken')).exp * 1000) { 
    RestClient.refreshAccessToken(refresh, result => {
      request.headers.append('Authorization', `Bearer ${result}`)
      RestClient.performRequest(request, callback);
    });
  } else {
    request.headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
    RestClient.performRequest(request, callback);
  }
}

// Function that updates the password of user identified by their ID
function updatePassword(id, data, callback, refresh) {
  let request = new Request(HOST.backend_api + endpoint.updatePassword + id, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  });
  if(Date.now() >= jwt_decode(localStorage.getItem('accessToken')).exp * 1000) { 
    RestClient.refreshAccessToken(refresh, result => {
      request.headers.append('Authorization', `Bearer ${result}`)
      RestClient.performRequest(request, callback);
    });
  } else {
    request.headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
    RestClient.performRequest(request, callback);
  }
}

// Function that adds the moderator rank to a user identified by their ID
function addMod(id, callback, refresh) {
  let request = new Request(HOST.backend_api + endpoint.add_mod + id, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  });
  if(Date.now() >= jwt_decode(localStorage.getItem('accessToken')).exp * 1000) { 
    RestClient.refreshAccessToken(refresh, result => {
      request.headers.append('Authorization', `Bearer ${result}`)
      RestClient.performRequest(request, callback);
    });
  } else {
    request.headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
    RestClient.performRequest(request, callback);
  }
}

// Function that removes the moderator rank from a user identified by their ID
function removeMod(id, callback, refresh) {
  let request = new Request(HOST.backend_api + endpoint.remove_mod + id, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  });
  if(Date.now() >= jwt_decode(localStorage.getItem('accessToken')).exp * 1000) { 
    RestClient.refreshAccessToken(refresh, result => {
      request.headers.append('Authorization', `Bearer ${result}`)
      RestClient.performRequest(request, callback);
    });
  } else {
    request.headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
    RestClient.performRequest(request, callback);
  }
}

export { getAll, addMod, removeMod, loginUser, registerUser, logoutUser, refreshToken, initializeRecovery, completeRecovery, getPersonalData, updateName, updateEmail, updatePassword }
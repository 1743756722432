import React from 'react'

function FormInput(props) {

  return(
    <div className="form-block__element" id={props.id}>
      <label className="form-block__label">{props.label}</label>
      <input type="text" className="form-block__input" placeholder={props.placeholder} onChange={event => {props.onChange(event.target.value)}}/>
    </div>
  )
}

export default FormInput;
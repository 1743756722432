import React, { useState, useEffect } from 'react'

import * as API from '../api/publisher-api'

function PublisherView(props) {

  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(1);

  const SHOW_COUNT = 10;
  var elements = [];
  
  function nextPage() { setPage(page + 1); }
  function previousPage() { setPage(page - 1); }
  function resetPage() { setPage(1); }

  useEffect(() => {
    elements = [];
    renderFilteredResults();
  }, [page])

  useEffect(() => {
    resetPage();
  }, [filter])

  function setFilterFunction(filterData) { setFilter(filterData); }

  // Function that retrieves all publishers
  function findAll() {
    return API.getAll(result => {
      const publishers = [];
      result.forEach(element => {
        publishers.push(element);
      });
      setData(publishers);
    }, props.refreshToken);
  }

  // Function that filters the results based on the search term
  function filterResults() {
    const filteredResults = [];
    if(data !== null) {
      data.forEach(element => {
        if(String(element.id).match(filter) || String(element.name).toLowerCase().match(filter.toLowerCase())) {
          filteredResults.push({
            id: element.id,
            name: element.name
          })
        }
      })
    }
    return filteredResults;
  }

  useEffect(findAll, []);

  // Function that renders the filtered results
  function renderFilteredResults() {
    const filteredResults = filterResults();
    sessionStorage.setItem('shownDataSize', filteredResults.length);
    if(filteredResults !== null) {
      for(let i = SHOW_COUNT * (page - 1); i < (SHOW_COUNT * page); i++) {
        if(i <= filteredResults.length && filteredResults[i] !== undefined) {
          elements.push(
            <div className="form-block__element--two" key={filteredResults[i].id}>
              <div>
                {filteredResults[i].id}
              </div>
              <div>
                {filteredResults[i].name}
              </div>
            </div>
          );
        }
      }
    }
  }

  renderFilteredResults();

  return(
    <>
      <div className="form-block__search-input" onChange={event => setFilterFunction(event.target.value)}>
        <input type="text" placeholder="Type to filter the results"  id="publisher-view-search"></input>
      </div>
      <div className="form-block__header">
        <div className="form-block__header__element">ID</div>
        <div className="form-block__header__element">Name</div>
      </div>
      <div className="form-block__body">
        {elements}
      </div>
      <div className="form-block__buttons">
        <button className="form-block__button" style={{display: `${page === 1 ? 'none' : 'block'}`}} onClick={() => previousPage()}><i className="fas fa-angle-left"></i></button>
        <button className="form-block__button" style={{display: `${page * SHOW_COUNT > sessionStorage.getItem('shownDataSize') ? 'none' : 'block'}`}} onClick={() => nextPage()}><i className="fas fa-angle-right"></i></button>
      </div>
    </>
  )
}

export default PublisherView;
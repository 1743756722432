import React, { useState, useEffect } from 'react'

import * as API from '../api/books-api'

function BookView(props) {

  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(1);

  const SHOW_COUNT = 10;
  var elements = [];

  function nextPage() { setPage(page + 1); }
  function previousPage() { setPage(page - 1); }
  function resetPage() { setPage(1); }

  useEffect(() => {
    elements = [];
    renderFilteredResults();
  }, [page])

  useEffect(() => {
    resetPage();
  }, [filter])

  function setFilterFunction(filterData) {
    setFilter(filterData);
  }

  // Function that retrieves all books
  function findAll() {
    return API.getAll(result => {
      const books = [];
      result.forEach(element => {
        books.push(element);
      })
      setData(books);
    })
  }

  // Function that checks if genres match
  function matchGenres(filter, genres) {
    var found = false;
    genres.forEach(genre => {
      if(String(genre.name).toLowerCase().match(filter.toLowerCase())) {
        found = true;
      }
    })
    return found;
  }

  // Function that filters the results based on the search term
  function filterResults() {
    const filteredResults = [];
    if(data !== null) {
      data.forEach(element => {
        if(
            String(element.id).match(filter) ||
            String(element.isbn13).match(filter) ||
            String(element.name).toLowerCase().match(filter.toLowerCase()) ||
            String(element.author.name).toLowerCase().match(filter.toLowerCase()) ||
            String(element.publisher.name).toLowerCase().match(filter.toLowerCase()) ||
            String(element.series.name).toLowerCase().match(filter.toLowerCase()) ||
            String(element.genres[0].name).toLowerCase().match(filter.toLowerCase()) ||
            matchGenres(filter, element.genres)
          ) {
              filteredResults.push(element)
            }
      })
    }
    return filteredResults;
  }

  useEffect(findAll, []);

  // Function that renders the genres
  function renderGenres(genres) {
    var elements = [];
    genres.forEach(genre => {
      var text = `${genre.name},`
      if(elements.length === genres.length - 1) text = text.substring(0, text.length-1);
      elements.push(
        <span key={Math.random()} style={{display: 'inline-block', paddingLeft: 0}}><a href={`/book/genreId/${genre.id}`}>{text}&nbsp;</a></span>
      )
    })
    return elements;
  }

  // Function that renders the date
  function renderDate(date) {
    var options = { year: 'numeric', month: 'short', day: 'numeric' };
    var timeThen = parseInt(String(date).replace('.', ""));
    var dateThen = new Date(timeThen);
    return dateThen.toLocaleDateString("en-GB", options);
  }

  // Function that renders the filtered results
  function renderFilteredResults() {
    const filteredResults = filterResults();
    sessionStorage.setItem('shownDataSize', filteredResults.length);
    if(filteredResults !== null) {
      for(let i = SHOW_COUNT * (page - 1); i < (SHOW_COUNT * page); i++) {
        if(i <= filteredResults.length && filteredResults[i] !== undefined) {
          const url= "https://beyondbooks.sergiudeaj.ro/book/" + filteredResults[i].isbn13;
          elements.push(
            <div className="form-block__element--books" key={filteredResults[i].id}>
              <div> {filteredResults[i].id} </div>
              <div><a href={url}> {filteredResults[i].isbn13} </a></div>
              <div><a href={url}> {filteredResults[i].name} </a></div>
              <div> {filteredResults[i].author.name} </div>
              <div> {filteredResults[i].series.name} </div>
              <div> {filteredResults[i].quantity} </div>
              <div> {filteredResults[i].sold} </div>
              <div> {renderGenres(filteredResults[i].genres)} </div>
              <div> {filteredResults[i].publisher.name} </div>
              <div> {filteredResults[i].format.name} </div>
              <div> {filteredResults[i].language.name} </div>
              <div> {filteredResults[i].price % 1 === 0 ? String(filteredResults[i].price).concat(".00") : filteredResults[i].price} </div>
              <div> {filteredResults[i].discount}% </div>
              <div> {renderDate(filteredResults[i].releaseDate)} </div>
            </div>
          );
        }
      }
    }
  }

  renderFilteredResults();

  return(
    <>
      <div className="form-block__search-input" onChange={event => setFilterFunction(event.target.value)}>
        <input type="text" placeholder="Type to filter the results" id="book-view-search"></input>
      </div>
      <div className="scroll-x">
        <div className="no-overflow">
          <div className="form-block__header--books">
            <div className="form-block__header__element">ID</div>
            <div className="form-block__header__element">ISBN13</div>
            <div className="form-block__header__element">Name</div>
            <div className="form-block__header__element">Author</div>
            <div className="form-block__header__element">Series</div>
            <div className="form-block__header__element">Qty.</div>
            <div className="form-block__header__element">Sold</div>
            <div className="form-block__header__element">Genres</div>
            <div className="form-block__header__element">Publisher</div>
            <div className="form-block__header__element">Format</div>
            <div className="form-block__header__element">Language</div>
            <div className="form-block__header__element">Price</div>
            <div className="form-block__header__element">Disc.</div>
            <div className="form-block__header__element">Release Date</div>
          </div>
          <div className="form-block__body">
            {elements}
          </div>
        </div>
      </div>

      <div className="form-block__buttons">
        <button className="form-block__button" style={{display: `${page === 1 ? 'none' : 'block'}`}} onClick={() => previousPage()}><i className="fas fa-angle-left"></i></button>
        <button className="form-block__button" style={{display: `${page * SHOW_COUNT > sessionStorage.getItem('shownDataSize') ? 'none' : 'block'}`}} onClick={() => nextPage()}><i className="fas fa-angle-right"></i></button>
      </div>
    </>
  )
}

export default BookView;
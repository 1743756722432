// Function that create a new message box, with the given text and given button text
function addMessageBox(text, button) {
  let area = document.getElementById("message-box-area");
  let messageText = document.getElementById("message-box__text");
  let messageButton = document.getElementById("message-box__button");
  messageText.innerText = `${text}`;
  messageButton.innerText = `${button}`
  area.classList.add("show");
}

export { addMessageBox }
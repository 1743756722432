import RestClient from './rest-client'
import { HOST } from './hosts'
import jwt_decode from 'jwt-decode'

const endpoint = {
  get_ranked: '/api/author/getRanked',
  save: '/api/author/add',
  get_all: '/api/author/getAll',
  get_by_name: '/api/author/get',
  get_by_id: '/api/author/getById/?id=',
  delete: '/api/author/delete/?id=',
  update: '/api/author/update/?id='
};

// Function that retrieves a list of all authors, ranked by their sales
function getRankedAuthors(callback) {
  let request = new Request(HOST.backend_api + endpoint.get_ranked, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
  RestClient.performRequest(request, callback);
}

// Function that saves a new author
function save(author, callback, refresh) {
  let request = new Request(HOST.backend_api + endpoint.save, {
    method: 'POST',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(author)
  });
  if(Date.now() >= jwt_decode(localStorage.getItem('accessToken')).exp * 1000) { 
    RestClient.refreshAccessToken(refresh, result => {
      request.headers.append('Authorization', `Bearer ${result}`)
      RestClient.performRequest(request, callback);
    });
  } else {
    request.headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
    RestClient.performRequest(request, callback);
  }
}

// Function that retrieves all authors
function getAll(callback) {
  let request = new Request(HOST.backend_api + endpoint.get_all, {
    method: 'GET',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  });
  RestClient.performRequest(request, callback);
}

// Function the retrieves an author by its' name
function getByName(author, callback, refresh) {
  let request = new Request(HOST.backend_api + endpoint.get_by_name, {
    method: 'GET',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(author)
  });
  if(Date.now() >= jwt_decode(localStorage.getItem('accessToken')).exp * 1000) { 
    RestClient.refreshAccessToken(refresh, result => {
      request.headers.append('Authorization', `Bearer ${result}`)
      RestClient.performRequest(request, callback);
    });
  } else {
    request.headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
    RestClient.performRequest(request, callback);
  }
}

// Function the retrieves an author by its' ID
function getById(id, callback, refresh) {
  let request = new Request(HOST.backend_api + endpoint.get_by_id + id, {
    method: 'GET',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  });
  if(Date.now() >= jwt_decode(localStorage.getItem('accessToken')).exp * 1000) { 
    RestClient.refreshAccessToken(refresh, result => {
      request.headers.append('Authorization', `Bearer ${result}`)
      RestClient.performRequest(request, callback);
    });
  } else {
    request.headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
    RestClient.performRequest(request, callback);
  }
}

// Function the deletes an author identified by its' ID
function deleteById(id, callback, refresh) {
  let request = new Request(HOST.backend_api + endpoint.delete + id, {
    method: 'DELETE',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  });
  if(Date.now() >= jwt_decode(localStorage.getItem('accessToken')).exp * 1000) { 
    RestClient.refreshAccessToken(refresh, result => {
      request.headers.append('Authorization', `Bearer ${result}`)
      RestClient.performRequest(request, callback);
    });
  } else {
    request.headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
    RestClient.performRequest(request, callback);
  }
}

// Function the updates an author identified by its' ID
function update(id, author, callback, refresh) {
  let request = new Request(HOST.backend_api + endpoint.update + id, {
    method: 'PUT',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(author)
  });
  if(Date.now() >= jwt_decode(localStorage.getItem('accessToken')).exp * 1000) { 
    RestClient.refreshAccessToken(refresh, result => {
      request.headers.append('Authorization', `Bearer ${result}`)
      RestClient.performRequest(request, callback);
    });
  } else {
    request.headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
    RestClient.performRequest(request, callback);
  }
}

export { getRankedAuthors, save, getAll, getByName, getById, update, deleteById }
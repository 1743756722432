import React, { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'

import Header from './components/Header'
import Content from './components/Content'
import Footer from './components/Footer'
import AnnouncementArea from './globals/AnnouncementArea'
import MessageBoxArea from './globals/MessageBoxArea'

function App() {

  const [loggedIn, setLoggedIn] = useState(false);
  const [cartBookCount, setCartBookCount] = useState(0);
  const [favoritesCount, setFavoritesCount] = useState(0);
  const [cookies, setCookie, removeCookie] = useCookies(['refreshToken', 'shoppingCart', 'favorites']);

  // Initializes the shopping cart and favorites list on page load 
  useEffect(() => {
    if(cookies.shoppingCart === undefined) {
      var newShoppingCart = [];
      setCookie('shoppingCart', newShoppingCart, { path: '/',  maxAge: 7776000 });
    } else {
      let counter = 0;
      if(cookies.shoppingCart != null) {
        cookies.shoppingCart.forEach(book => counter += parseInt(book.amount))
      }
      cartBookCountSetter(counter);
    }
    if(cookies.favorites === undefined) {
      var favorites = [];
      setCookie('favorites', favorites, { path: '/',  maxAge: 7776000 });
    } else {
      let counter = 0;
      if(cookies.favorites !== null && cookies.favorites !== undefined) {
        cookies.favorites.forEach(book => counter += parseInt(book.amount))
      }
      favoritesCountSetter(counter);
    }
  }, [])

  useEffect(() => {
    if(cookies.refreshToken !== null && cookies.refreshToken !== undefined) {
      setLoggedIn(true);
    }
  }, [cookies.refreshToken])

  function loggedInSetter(value) {
    setLoggedIn(value);
  }

  function cartBookCountSetter(value) {
    setCartBookCount(value);
  }

  function favoritesCountSetter(value) {
    setFavoritesCount(value)
  }

  function refreshTokenSetter(newToken) {
    setCookie('refreshToken', newToken, { path: '/', maxAge: 7776000});
  }

  function refreshTokenRemover() {
    removeCookie('refreshToken', { path: '/', maxAge: 7776000});
  }

  return (
    <>
      <Header 
        cartBookCount={cartBookCount}
        favoritesCount={favoritesCount}
        loggedIn = { loggedIn }
        setLoggedIn = { loggedInSetter }
        refreshToken = { cookies.refreshToken } 
        removeRefreshToken = { refreshTokenRemover } 
        shoppingCart = { cookies.shoppingCart }
        favorites = { cookies.favorites }
      />
      <Content 
        shoppingCart = {cookies.shoppingCart}
        cartBookCount={cartBookCount}
        cartBookCountSetter={cartBookCountSetter}
        favorites = {cookies.favorites}
        favoritesCountSetter={favoritesCountSetter}
        loggedIn = { loggedIn } 
        setLoggedIn = { loggedInSetter } 
        refreshToken = { cookies.refreshToken } 
        setRefreshToken = { refreshTokenSetter }
      />
      <Footer/>
      <AnnouncementArea/>
      <MessageBoxArea/>
    </>
  )
}

export default App;
import React, { useState, useEffect } from 'react'

import { useCookies } from 'react-cookie'

import * as SHOPPING_CART_UTILS from '../utils/ShoppingCartUtils'

function Basket(props) {

  const [basketSize, setBasketSize] = useState(-1);
  const [cookies, setCookie] = useCookies(['shoppingCart']);

  useEffect(() => {
    setBasketSize(props.shoppingCart.length)
  }, [props.shoppingCart])

  // Function that calls the shopping cart utility class to remove a book from the shopping cart
  function removeBookFromCart(bookToRemove) {
    let newCart = SHOPPING_CART_UTILS.removeBookFromCart(bookToRemove, cookies.shoppingCart);
    setCookie('shoppingCart', newCart, { path: '/',  maxAge: 7776000 });
  }

  // Function that calls the shopping cart utility class to change the amount of books in the shopping cart
  function changeAmount(bookToChange, newAmount) {
    let newCart = SHOPPING_CART_UTILS.changeAmountInCart(bookToChange, cookies.shoppingCart, newAmount);
    setCookie('shoppingCart', newCart, { path: '/',  maxAge: 7776000 });
  }

  // Function that calculates the total price of the shopping cart
  function calculateTotal() {
    var total = 0;
    props.shoppingCart.forEach(book => {
      total += (book.amount * Math.round((book.price - (book.discount/100 * book.price)) * 100)/100)
    })
    return Math.round((total + Number.EPSILON) * 100)/100;
  }

  // Function that renders availability
  function renderAvailability(value) {
    if(value <= 0) {
      return "Out of Stock";
    } else if (value === 1) {
      return "Only 1 Left";
    } else if (value === 2) {
      return "Only 2 Left";
    } else if (value === 3) {
      return "Only 3 Left";
    } else if (value <= 10) {
      return "Limited Qty";
    } else if (value > 10) {
      return "In Stock";
    }
  }

  // Function that renders options
  function renderOptions(value) {
    let maxAmount = value < 50 ? value : 50;

    var options = [];
    for(let i = 1; i <= maxAmount; i++) {
      if(options == null) {
        options = [<option value={i} key={i}>{i}</option>]
      } else {
        options.push(<option value={i} key={i}>{i}</option>)
      }
    }
    return options;
  }

  const cart = [];
  props.shoppingCart.forEach(book => {
    cart.push(
      <div className="basket__book flex flex-fd-r flex-ai-c" key={"book " + book.id}>
        <a href={/book/ + book.isbn13} className="basket__image"><img alt={book.isbn13} src={`https://beyondbooks.sergiudeaj.ro/assets/images/books/${book.isbn13}.jpg`} width={100}/></a>
        <div className="basket__texts flex">
          <div className="basket__title__author flex flex-fd-c">
            <a href={/book/ + book.isbn13} className="basket__name">{book.name}</a>
            <a href={`/book/authorId/${book.author.id}`}><div className="basket__author">{book.author.name}</div></a>
          </div>
          <div style={{color: `${book.quantity > 10 ? 'green' : (book.quantity > 0 ? '#fcb103' : 'red')}`}}className="basket__availability"><span className="hide-for-tablet" style={{color: 'black'}}>Availability:&nbsp;</span>{renderAvailability(book.quantity)}</div>
          <div className="basket__quantity"><span className="hide-for-tablet">Quantity:&nbsp;</span>
            <select className="basket__quantity__select" value={book.amount} onChange={e => changeAmount(book, e.target.value)}>
              {renderOptions(book.quantity)}
            </select>
            <button className="basket__button hide-for-mobile" onClick={() => removeBookFromCart(book)}><i className="fas fa-times"></i></button>
          </div>
          <div className="basket__price"><span className="hide-for-tablet">Price:&nbsp;</span>{Math.round((book.price - (book.discount/100 * book.price)) * 100)/100}€</div>
          <div className="basket__total"><span className="hide-for-tablet">Total:&nbsp;</span>{Math.round((book.price - (book.discount/100 * book.price))*book.amount*100)/100}€</div>
        </div>
        <button className="basket__button--mobile hide-for-tablet" onClick={() => removeBookFromCart(book)}><i className="fas fa-times"></i></button>
      </div>
    );
  });

  function BasketRenderer() {
    if(basketSize === 0) {
      return  <div className="empty-basket max-width pad-10 flex flex-jc-c flex-ai-c flex-fd-c">
                <h3>Your basket is empty</h3>
                <p>To add products to your basket please return to the store</p>
                <a href="/">Return to the store</a>
              </div>
    }
    return  <div className="basket-page__container">
              <div className="basket-page__cover pad-10">
                <div className="basket-page">
                  <h2>Your Basket</h2>
                  <div className="basket__header flex hide-for-mobile">
                    <div className="basket__header__product">Product</div>
                    <div className="basket__header__availability">Availability</div>
                    <div className="basket__header__quantity">Quantity</div>
                    <div className="basket__header__price">Price</div>
                    <div className="basket__header__total">Total</div>
                  </div>
                  <div className="basket flex flex-fd-c">{cart}</div>
                  <div className="basket__footer flex flex-fd-r flex-jc-sb flex-ai-c">
                    <div>TOTAL: {calculateTotal()}€</div>
                    <a href="/basket/delivery">Checkout</a>
                  </div>
                </div>
              </div>
            </div>
  }

  return(<BasketRenderer refreshToken = { props.refreshToken }/>)
}

export default Basket;